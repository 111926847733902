
export default function Stats() {
  const token = JSON.parse(localStorage.getItem('token') || '{}');
  const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
  const tenant_id = token[process.env.REACT_APP_BASE_URL + "/tenant_id"] 
  const user_id = token["sub"] 
  const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
  const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
  const domain = process.env.REACT_APP_API_BASE_URL
  const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
  const qa_environment = qa_config["environment"]
  const blue_config = qa_config["config"]

  return (
    <div>
    <iframe
    src={`https://pdx.${continent}.private.${domain}/private/analytics/metabase/public/dashboard/7e63f6bc-c189-4298-b2f6-4108c2a5b4e7?org_id_tenant_id=${org_id}-${tenant_id}&user_id=${user_id}#hide_parameters=org_id_tenant_id,user_id`}
    frameBorder={0}
    width="100%"
    height={1500}
    allowTransparency
/>
    <iframe
    src={`https://pdx.${continent}.private.${domain}/private/analytics/metabase/public/dashboard/5f407233-9691-4209-8c61-210101c87c12?org_id=${org_id}&org_id_tenant_id=${org_id}-${tenant_id}&user_id=${user_id}#hide_parameters=org_id,org_id_tenant_id,user_id`}
    frameBorder={0}
    width="100%"
    height={1500}
    allowTransparency
/>
</div>
  );
}

