import { Row, Col, Card, Typography } from "antd";
const { Title } = Typography;

import {OrderTimeline} from "./orderTimeline"

export default function ActivityFeed() {
  return (
    <Row gutter={[16, 16]}>
        <Title level={5}>TimeLine</Title>
        <Col md={24}>
        {/* <div text-align='right'>
                <Space wrap >
                  <Button
                      shape="round"
                      type="primary"
                      text="Refresh"
                      align="rceight"
                      // onClick={() => {
                      //     setFilterCategories([]);
                      //     onChange?.([]);
                      // }}
                  >
                    Refresh
                  </Button>
              </Space>
              </div> */}
          <Row gutter={[16, 16]}>
              <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                  <Card
                      bodyStyle={{
                          padding: 10,
                          paddingBottom: 0,
                      }}
                      style={{
                          background: "url(images/daily-revenue.png)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right",
                          backgroundSize: "cover",
                      }}
                  >
                  <OrderTimeline />    
                  </Card>
              </Col>
          </Row>
      </Col>
    </Row>
    
  );
}