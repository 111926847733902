import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Space,
  Tag,
  theme,
  Avatar,
  Select,
  AutoComplete,
  Input,
  Typography,
  Tooltip,
} from "antd";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";

import {
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";


import { useList } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6";
import { ISearch } from "interfaces";

const { Text } = Typography;

export interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

export interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

const { useToken } = theme;

// To be able to customize the option title
const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>
      {title.charAt(0).toUpperCase() + title.slice(1).replace('_', ' ')}
    </Text>
  );
};

// To be able to customize the option item
const renderItem = (title: string, resource: string, chain_ids: string[], chains: string[], value: string, id?: string, labels?: any) => {
  const linkPath = id 
    ? `/${resource}/show/${id}` 
    : resource === "addresses" 
      ? `/${resource}/show/${value}?chain_ids=${chain_ids?.join(',') || ''}`
      : `/${resource}/show/${chain_ids?.[0] || ''}_${value}`;
  
  const MAX_CHAINS_DISPLAY = 2;
  const remainingChains = (chain_ids?.length || 0) - MAX_CHAINS_DISPLAY;
  
  const displayedChains = chains?.slice?.(0, MAX_CHAINS_DISPLAY)?.join?.(", ") || "";
  const remainingChainsList = chains?.slice?.(MAX_CHAINS_DISPLAY)?.join?.(", ") || "";
  const chainDisplay = (chain_ids?.length || 0) > MAX_CHAINS_DISPLAY
    ? <Tooltip title={`Additional chains: ${remainingChainsList}`}>
        <Text type="secondary">({displayedChains} +{remainingChains} more chains)</Text>
      </Tooltip>
    : <Text type="secondary">({chains?.join?.(", ") || ""})</Text>;
  
  const labelLogos = labels ? Object.values(labels)
    .reduce((acc: { withLogo: any[], withoutLogo: any[] }, label: any) => {
      if (label.label_logo) {
        acc.withLogo.push({
          logo: label.label_logo,
          label: label.label
        });
      } else if (label.label) {
        acc.withoutLogo.push(label.label);
      }
      return acc;
    }, { withLogo: [], withoutLogo: [] }) : { withLogo: [], withoutLogo: [] };
  
  const displayTitle = (resource === "transactions" || resource === "addresses") 
    ? `${title.slice(0, 10)}...${title.slice(-10)}`
    : title;

  return {
    value: title,
    label: (
      <Link to={linkPath}>
        <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space align="center">
            <div style={{ width: 60, display: 'flex', alignItems: 'center' }}>
              <Space size={-6}>
                {chain_ids?.slice(0, MAX_CHAINS_DISPLAY)?.map((chain_id, index) => (
                  <Tooltip title={chains?.[index]}>
                    <Avatar 
                      key={chain_id}
                      size="small"
                      src={`https://${process.env.REACT_APP_CDN_URL}/public/GET/cdn/blockchain/logos/svg/${chain_id}.svg`}
                      style={{ 
                        border: '1px solid #f0f0f0',
                        marginLeft: index > 0 ? '-8px' : 0,
                        zIndex: MAX_CHAINS_DISPLAY - index 
                      }}
                    />
                  </Tooltip>
                ))}
                {(chain_ids?.length || 0) > MAX_CHAINS_DISPLAY && (
                  <Tooltip title={`Additional chains: ${remainingChainsList}`}>
                    <Avatar 
                      size="small"
                      style={{ 
                        marginLeft: '-8px',
                        background: '#1890ff',
                        color: 'white',
                        fontWeight: 'bold',
                        zIndex: 0
                      }}
                    >
                      +{remainingChains}
                    </Avatar>
                  </Tooltip>
                )}
              </Space>
            </div>
            <Tooltip title={title}>
              <Text>{displayTitle}</Text>
            </Tooltip>
            <Text type="secondary">{chainDisplay}</Text>
          </Space>
          <Space>
            {labelLogos.withLogo.map(({ logo, label }) => (
              <Tooltip title={label || "No label"}>
                <Avatar 
                  size="small"
                  src={logo}
                />
              </Tooltip>
            ))}
            {labelLogos.withoutLogo.map((label) => (
              <Tag>{label}</Tag>
            ))}
          </Space>
        </Space>
      </Link>
    ),
  };
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  // const { data: user } = useGetIdentity<IUser>();
  const {
    user,
  } = useAuth0();
  
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const { token } = useToken();
  const region_config = user?.[process.env.REACT_APP_BASE_URL + "/region_config"] 
  const region_alias = region_config?.region_alias
  const backup_region_alias = region_config?.backup_region_alias
  const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user?.[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
  
  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  const { Option } = Select;

  let [useRegionAlias, setUseRegionAlias] = React.useState<string | null>(JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')  || "default")

  const handleOnChangeSelectUseRegionAlias = (e: any) => {     
    console.log("Changed region to: " + e)

    if(!e){
      setUseRegionAlias(null)
      localStorage.removeItem(local_storage_prefix + '_use_region_alias')
      window.location.reload();
    } else{
      setUseRegionAlias(e.target)
      localStorage.setItem(local_storage_prefix + '_use_region_alias', JSON.stringify(e))
      window.location.reload();
    }

  };
  
  const { refetch: refetchSearch } = useList<ISearch>({
    resource: "search",
    meta: { 
      "search_string": value,
    },
    filters: [{ field: "search_input", operator: "eq", value }],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        setIsSearching(false);
        
        // Group results by category
        const groupedResults = data.data.reduce((acc: { [key: string]: IOptionGroup[] }, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          
          acc[item.category].push(
            renderItem(
              item.value, 
              item.resource, 
              item.chain_ids, 
              item.chains, 
              item.value, 
              item.id,
              item.labels,
            )
          );
          
          return acc;
        }, {});

        // Convert grouped results to options format
        const formattedOptions = Object.entries(groupedResults).map(([category, items]) => ({
          label: renderTitle(category),
          options: items,
        }));

        setOptions(formattedOptions);
      },
    },
  });

  useEffect(() => {
    setOptions([]);
    if (value) {
      setIsSearching(true);
      refetchSearch();
    } else {
      setIsSearching(false);
    }
  }, [value]);

  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const [cachedValue, setCachedValue] = useState<string>('');
  const [cachedOptions, setCachedOptions] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
        // Skip if clicking on an option
        const target = event.target as HTMLElement;
        if (target.closest('.ant-select-item')) {
            return;
        }

        if (autoCompleteRef.current && !(event.target as Node).contains(autoCompleteRef.current)) {
            // Cache current values before closing
            if (value) {
                setCachedValue(value);
                setCachedOptions(options);
            }
            setIsOpen(false);
        }
    }

    function handleClickInside(event: MouseEvent) {
        // Skip if clicking on an option
        const target = event.target as HTMLElement;
        if (target.closest('.ant-select-item')) {
            return;
        }

        if (autoCompleteRef.current?.contains(event.target as Node)) {
            setIsOpen(true);
            // Only restore cached values if the input wasn't cleared
            if (cachedValue && value) {
                setValue(cachedValue);
                setOptions(cachedOptions);
            }
        }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickInside);
    
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('mousedown', handleClickInside);
    };
  }, [value, cachedValue, options, cachedOptions]);

  return (
    <AntdLayout.Header style={headerStyles}>
      <div ref={autoCompleteRef}           
        style={{ 
            width: "100%",
            minWidth: "550px",
            maxWidth: "800px",
            float: "left",
            marginRight: "10px",
            paddingLeft: "10px"
          }}>
        <AutoComplete
          style={{ 
            width: "100%",
            minWidth: "550px",
            maxWidth: "800px",
            float: "left",
            marginRight: "10px",
            paddingLeft: "10px"
          }}
          filterOption={false}
          options={options}
          onSearch={(value: string) => setValue(value)}
          onSelect={() => {
            setValue('');
            setOptions([]);
            setCachedValue('');
            setCachedOptions([]);
            setIsOpen(false);
          }}
          open={isOpen && (isSearching || options.length > 0)}
          notFoundContent={isSearching ? "Searching..." : "No results found"}
          value={value}
        >
          <Input
            size="large"
            placeholder="Search for addresses, transactions or entities"
            suffix={isSearching ? <LoadingOutlined spin onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> : <SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
          />
        </AutoComplete>
      </div>      
      <Select 
          defaultValue={useRegionAlias}
          placeholder="Please select a region"
          onChange={handleOnChangeSelectUseRegionAlias}
          >
          <Option value="pdx" disabled={"pdx" != region_alias && "pdx" != backup_region_alias}><b>pdx</b> - us-west-2</Option>
          <Option value="iad" disabled={"iad" != region_alias && "iad" != backup_region_alias}><b>iad</b>  - us-east-1</Option>
          <Option value="fra" disabled={"fra" != region_alias && "fra" != backup_region_alias}><b>fra</b>  - eu-central-1</Option>
          <Option value="dub" disabled={"dub" != region_alias && "dub" != backup_region_alias}><b>dub</b>  - eu-west-1</Option>
          <Option value="default"><b>Default</b>  - default</Option>
      </Select>         
      <Space>
        <ConnectButton />
      </Space>
      <Link to="/settings">
      <Space
                style={{
                    float: "left",
                    marginRight: "10px",
                    paddingLeft: "10px"
                }}
            >
              <strong>{user?.name}</strong>
            </Space>   
              <Space
                    style={{
                        float: "left",
                        marginRight: "10px",
                        paddingLeft: "0px"
                    }}
                >
                <Avatar src={<img src={user?.picture} alt="avatar" />} />
              </Space>
            </Link>      
            <Space>
            <Tag color="geekblue">{user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.length > 1 ? "Custom" : user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.[0]?.name || "Error: No Role found"}</Tag>
            </Space>   
            <Tag color="success">{user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.['is_root_tenant'] ? "" : user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.name  || ""}</Tag>
            {user?.[process.env.REACT_APP_BASE_URL + '/qa_config']?.["environment"] == "blue" ? <Tag color="darkblue">blue</Tag> : null}
            {
              user?.[process.env.REACT_APP_BASE_URL + '/custom_app_config']?.['is_demo_account'] ? <Tag color="red">DEMO ACCOUNT</Tag> 
              : ""
            }
    </AntdLayout.Header>
  );
};
