import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { DeleteButton, SaveButton, Edit, useForm, TextField } from "@refinedev/antd";

import { Card, Checkbox, Form, Input, Select, Space, Tag, Radio, Tooltip, Flex } from "antd";
const { TextArea } = Input;

import { ITraceItem, ITracer } from "interfaces";

import { useSignMessage } from 'wagmi'

export const TracersEdit: React.FC<IResourceComponentsProps> = () => {
    const { data, error, signMessage, variables } = useSignMessage()

    const { formProps, saveButtonProps, queryResult, onFinish } = useForm<ITracer>();

    const postData = queryResult?.data?.data;

    const available_chains = ["eth", "goerli", "sepolia", "polygon", "mumbai", "bsc", "bsc_testnet", "avalanche", "fantom", "cronos", "palm", "arbitrum"]

    function AddAnotherTraceItem(){
        var trace_item: ITraceItem = {
            id: "",
            actively_stream_transactions: false,
            addresses: [],
            chains: [],
            name: "",
            description: "", 
            from_block: "",
            to_block: "",
            from_date: "",
            to_date: "",
            include_native_txs: true,
            max_number_of_transactions: 1
        }
        postData?.tracer_config.TRACE_ITEMS.push(trace_item)
    }

    function RemoveTraceItem(index: number){
        postData?.tracer_config.TRACE_ITEMS.splice(index, 1)
    }

    const onFinishHandler = (values : any ) => {
        console.log("Hello on finish")
        console.log(values)
        console.log(postData?.tracer_config.TRACE_ITEMS)
        onFinish({
            tracer_id: postData?.tracer_id,
            name: values.name,
            description: values.description,
            TRACE_ITEMS: postData?.tracer_config.TRACE_ITEMS
        });

        // close();
    };

    return (
        <Edit saveButtonProps={{ ...saveButtonProps }} 
        >
            <Form {...formProps} layout="vertical" onFinish={onFinishHandler}>
                <Form.Item
                    label="Tracer Id"
                    name="tracer_id"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={false} />
                </Form.Item>     
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={false} />
                </Form.Item>          
                <Form.Item
                    label="Trace Items"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    
                >
                    {
                        postData?.tracer_config.TRACE_ITEMS.map(trace_item=>{
                          return    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Flex wrap="wrap" gap="small">
                          <Card title={"Trace Config item: " + (postData?.tracer_config.TRACE_ITEMS.indexOf(trace_item) + 1)} size="default" extra={<Space><a href="#">Advanced</a> <a onClick={AddAnotherTraceItem}>Add Another Trace Item</a> <a style={{color: 'red'}} onClick={(e) => RemoveTraceItem(postData?.tracer_config.TRACE_ITEMS.indexOf(trace_item))}>Remove</a></Space>}>
                            <Tooltip title="Which address you want to follow">
                            <Card title="Addresses" size=   "small">
                                    <p><Checkbox.Group options={trace_item.addresses} defaultValue={trace_item.addresses} > </Checkbox.Group></p>
                            </Card>
                            </Tooltip>
                            <Tooltip title="On which chains you want to follow the address">
                            <Card title="Chains" size="small">
                                <p><Checkbox.Group options={available_chains} defaultValue={trace_item.chains}> </Checkbox.Group></p>
                            </Card>
                            </Tooltip>
                            
                            <Card title="Include internal transactions" size="small">
                            <p> <Radio.Group value="enabled">
                                    {['enabled', 'disabled'].map((item) => (
                                    <Radio key={item} value={item}>
                                        {item}
                                    </Radio>
                                    ))}
                                </Radio.Group>
                                </p>
                            </Card>
                          </Card>
                          </Flex>
                        </Space>
                        
                        })
                    }
                </Form.Item>   
            </Form>
        </Edit>

    );
};



