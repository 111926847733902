import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Avatar, Divider, List, Select, Space, Tag, Typography } from 'antd';
import { Show, TagField } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IIntegration } from 'interfaces';

const { Title, Text } = Typography;

// import AceEditor from 'react-ace';

// import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/theme-monokai';

export const IntegrationShow: React.FC<IResourceComponentsProps> = () => {
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const {
        user,
      } = useAuth0();

      const { queryResult } = useShow<IIntegration>();
      const { data, isLoading } = queryResult;
      const record = data?.data;
      const { Option } = Select;

    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    const pythonCode = `
    ### Example Payload
    {
        "TaskToken": "task_token",
        "Payload": {
            "transaction": {
                "hash": "0x57b1ab0ce2084d87f6f03a7911871ecbf179020564e3dff486ea48f4da27f6f3",
                "nonce": "7",
                "transaction_index": "109",
                "from_address": "0x3098b6665589959711a48a6bae5b7f2908f6a3be",
                "from_address_label": null,
                "to_address": "0x1043eee3e635827c466f68d434b8e474fd64a25b",
                "to_address_label": null,
                "value": "0",
                "gas": "757217",
                "gas_price": "15468279806",
                "input": "0x954491c1000000000000000000000000000000000000000000000000000000000000000500000000000000000000000000000",
                "receipt_cumulative_gas_used": "12164711",
                "receipt_gas_used": "630303",
                "receipt_contract_address": null,
                "receipt_root": null,
                "receipt_status": "0",
                "block_timestamp": "2023-09-27T18:35:11.000Z",
                "block_number": "18228915",
                "block_hash": "0x19cfca79520229f60a18bc4fcd2e9445a0b9a59b28be7c9ddacd77c8d1ba703d",
                "logs": [],
                "decoded_call": null
            }
        }
    }
    
    #### Example Integration code
    import os
    import json    
    import boto3
    
    sfn_client = boto3.client('stepfunctions', os.environ[AWS_REGION'])
    s3_client = boto3.client('s3', os.environ['AWS_REGION'])
    
    def lambda_handler(event, context):
        # Get the Payload
        task_token = event['TaskToken']
        transaction = event['Payload']["transaction"]

        # Add tag to the transaction
        result["tags] = ["Add tag 1"] 

        # Add from address label to the transaction
        result["from_address_labels] = ["Add from address label 1"] 

        # Add to address label to the transaction
        result["to_address_labels] = ["Add to address label 1"] 

        # Add the transaction to a group
        result["groups] = ["Add to group 1"] 

        print("Return the results")
        sfn_client.send_task_success(
                taskToken=task_token,
                output=json.dumps(result)
        )
`

    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Integration Environment</Title>
            <TagField value={record?.get_integration_result.environment}></TagField>
            <Title level={5}>Integration Region</Title>
            <TagField color={colors[record?.get_integration_result.region as keyof typeof colors ]} value={record?.get_integration_result.region}></TagField>
            <Title level={5}>Integration Org Id</Title>
            <Text>{record?.get_integration_result.org_id}</Text>
            <Title level={5}>Integration Tenant Id</Title>
            <Text>{record?.get_integration_result.tenant_id}</Text>
            <Title level={5}>Integration Owner Id</Title>
            <Text>{record?.get_integration_result.created_by}</Text>
            <Title level={5}>Integration Id</Title>
            <Text>{record?.get_integration_result.id}</Text>
            <Title level={5}>Integration Name</Title>
            <Text>{record?.get_integration_result.name}</Text>
            <Title level={5}>Integration Enabled</Title>
            <Text>
                <TagField color={record?.get_integration_result.enabled == true ? "green" : "red"} value={record?.get_integration_result.enabled == true ? "Enabled" : "Disabled"}></TagField>
            </Text>
            <Title level={5}>Integration Status</Title>
            <Text>
                <TagField color={record?.get_integration_result.status == "active" ? "green" : "red"} value={record?.get_integration_result.status}></TagField>
            </Text>
            <Title level={5}>Integration Stages</Title>
            <Text>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                disabled={true}
                value={record?.get_integration_result.integration_stages}
            />
            </Text>
            <Divider></Divider>

            <Title level={5}>Triggered on</Title>
            <Text>
            <List
                itemLayout="horizontal"
                dataSource={record?.get_integration_result?.trigger_on}
                renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.get_integration_result?.trigger_on[index]?.chain_id}.svg`} />}

                        // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                        title={record?.get_integration_result?.trigger_on[index]?.chain}
                        description={record?.get_integration_result?.trigger_on[index]?.address}
                    />
                </List.Item>
                )}
            />
            </Text>
            <Title level={5}>Example source code</Title>
            <pre> {pythonCode} </pre>


            {/* <Title level={5}>Integration Config (from codeCommit)</Title>
            {
                record?.get_integration_versions_result?.length > 0 ? 
                <AceEditor  
                    style={{width: 1000}}
                    mode="json"
                    theme="monokai"
                    wrapEnabled={true}
                    defaultValue={ record?.get_integration_versions_result[0]?.config_file_contents ? JSON.stringify(JSON.parse(record?.get_integration_versions_result[0]?.config_file_contents), null, 2): '{}' } 
                    readOnly={true}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                />
                :
                "No config"
            }
            <Divider></Divider>
            <Title level={5}>Integration Source (from codeCommit)</Title>
            {
                record?.get_integration_versions_result?.length > 0 ? 
                <AceEditor  
                    style={{width: 1000}}
                    mode="json"
                    theme="monokai"
                    wrapEnabled={true}
                    defaultValue={ record?.get_integration_versions_result[0]?.source_file_contents ? record?.get_integration_versions_result[0]?.source_file_contents: '{}' } 
                    readOnly={true}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                />
                :
                "No source"
            } */}
            <Divider></Divider>    
        </Show>
    );
};

