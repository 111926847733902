import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space } from "antd";

import { ITracer } from "interfaces";
import { useAccount } from 'wagmi'


export const TracersList: React.FC<IResourceComponentsProps> = () => {
    const { address } = useAccount()

    const { tableProps, sorter } = useTable<ITracer>({
        meta:{"address": address, "chain": "sepolia"}
    });

    const datetime = dayjs.unix(1653134400)


    return (
        <List>
            <Table {...tableProps} rowKey="tracer_id">
                <Table.Column<ITracer>
                    title="Id" 
                    dataIndex="id" 
                    render={(_, record) => {
                        return (
                            <TagField
                                value={`${
                                    record?.tracer_id
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<ITracer>
                    title="Name" 
                    dataIndex="name" 
                    render={(_, record) => {
                        return (
                            <TextField value={record.name} />
            
                        );
                    }}
                />   
                <Table.Column<ITracer>
                    title="Description" 
                    dataIndex="description" 
                    render={(_, record) => {
                        return (
                            <TextField value={record.description} />
            
                        );
                    }}
                />   



                <Table.Column<any>
                    title="Creation Date" 
                    dataIndex="creation_timestamp" 
                    render={(_, record) => {
                        return (
                            <DateField value={dayjs.unix(record?.creation_timestamp)} format="LLL" />
            
                        );
                    }}
                />       
                <Table.Column<ITracer>
                    title="Actions"
                    dataIndex="tracer_id"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.tracer_id}
                                resource="tracers"
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId= {record.tracer_id}
                                resource="tracers"
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.tracer_id}
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};
