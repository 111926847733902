import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne, useNotification } from "@refinedev/core";

import { TagField, Show, DeleteButton, SaveButton, ShowButton, useModalForm, EditButton } from "@refinedev/antd";

import { Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, List, Modal, Row, Select, SelectProps, Skeleton, Space, Spin, Statistic, Switch, Tag, Tooltip, Typography } from "antd";

import { IWallet } from "interfaces";
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ForceGraph2D from "react-force-graph-2d";

const { Title, Text } = Typography;

import { useModal } from "@refinedev/antd";
import debounce from 'lodash/debounce';
import { useAuth0 } from "@auth0/auth0-react";

interface DataType {
    gender: string;
    name: {
      title: string;
      first: string;
      last: string;
    };
    email: string;
    picture: {
      large: string;
      medium: string;
      thumbnail: string;
    };
    nat: string;
  }


export const WalletShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()
    const { open } = useNotification();
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/sub']

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')
  
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
  
    let use_region:string
    let use_region_alias
    let use_backup_region
    let use_backup_region_alias
  
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
  
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { queryResult } = useShow<IWallet>();
    const { data, isLoading, isFetching, isRefetching } = queryResult;
    const record = data?.data;

    // const { data: categoryData, isLoading: categoryIsLoading } =
    //     useOne<IWallet>({
    //         resource: "transactions",
    //         id: record?.hash ? record?.hash : "",
    //         queryOptions: {
    //             enabled: !!record,
    //         },
    //         meta: {"transaction_hash": record?.hash}
    //     });

    const params = useParams()

    const [images, setImages] = useState<Array<HTMLImageElement>>();

    function componentDidMount() {
        const nodeImages = record?.nodes?.map(node => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))

        const images = nodeImagesUnique?.map(image => {
        const img = new Image();
        img.src = image;
        return img;
        });
        setImages(images)
        console.log(images)
    }

    const fgRef = useRef<any>();

    const graphDataNew = useMemo(() => {
        const nodeImages = record?.nodes?.map(node => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))
    
        const images = nodeImagesUnique?.map(image => {
          const img = new Image();
          img.src = image;
          return img;
        });
        setImages(images)

        return {
          nodes: record?.nodes ? record?.nodes : [],
          links: record?.links ? record?.links : []
        };
    }, [record]);

    useEffect(() => {
        if(!images){
            componentDidMount()
        }

        const current = fgRef?.current
        current?.zoom(5)
    }, [images, record]);
    
    return (
        <Show isLoading={false}>
            <Title level={5}>Address</Title>
            <Text>{params?.id}
                {
                    <Avatar  style={{marginLeft:"10px"}} size={"large"} src={<img src={record?.address_labels?.[0]?.source || "https://" + process.env.REACT_APP_CDN_URL + "/public/GET/cdn/charts/logos/png/question_mark.png"} alt="avatar" />} />
                }
            </Text>
            <Title level={5}>Labels</Title>
            <Text>
            {         
                    record?.address_labels?.map((label: any)=>{
                        return <Tag color={"#" + label.color}>{label.name}</Tag>
                    })
            }  
             </Text>   
             <Title level={5}>Tags</Title>
            <Text>
            {         
                    record?.tags?.map((tag: any)=>{
                        return <Tag color={"#" + tag.color}>{tag.name}</Tag>
                    })
            }  
             </Text>   
             <Title level={5}>Chains</Title>
            <Text>
            {         
                    record?.all_chains?.map((chain: any)=>{
                        return <Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chain.value}.svg`} />
                    })
            }  
             </Text>               
             <Title level={5}>Number of transactions</Title>
            <Text>
            {         
                record?.number_of_transactions
            }  
             </Text>   
            { 
                isLoading || isFetching || isRefetching ? 
                    <Spin spinning={isLoading || isFetching || isRefetching}> </Spin> 
                    
                    : 
                    <div>
                    <Divider></Divider>
                    <Title level={3}>Graph:</Title>
                    <ForceGraph2D

                    ref={fgRef}
                    graphData={graphDataNew}            
                    // graphData={gData}
                    nodeLabel={(node:any) => `<div><b>Name</b>: <span>${node.friendly_name}</span><br></br><b>Id</b>: <span>${node.id}</span><br></br><b>To/From address Labels</b>: <span>${node?.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                    linkLabel={(link:any) => `<div><b>Name</b>: <span>${link.friendly_name}</span><br></br><b>Id</b>: <span>${link.id}</span><br></br><b>Tags</b>: <span>${link?.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                    nodeAutoColorBy="group"
                    //   onNodeHover={(node:any, prevNode:any) => {
                    //     console.log(node)
                    //     if(node){
                    //         node.val = 20
                    //         node.friendly_name = node.friendly_names
                    //     }
                    //     return node
                    //   }}
                    backgroundColor="white"
                    onLinkHover={(link:any) => {
                        return <Tooltip title="x">x</Tooltip>
                    }}
        
                    linkDirectionalArrowColor={(link:any) => {
                        if (link.linkArrowColor != undefined){
                            return link.linkArrowColor
                        } else{
                            return "black"
                        }
                    }}
                    linkDirectionalArrowLength={(link:any) => {
                        return link.arraySize
                    }}
                    linkDirectionalArrowRelPos={(link:any) => {
                        return 0.3
                    }}
                    linkLineDash={(link:any) => {
                        return [1]
                    }}
                    linkCurvature={(link:any) => {
                        if (link.curved != undefined){
                            return link.curved
                        } else{
                            return 0
                        }
                    }}
                    linkColor={(link:any) => {
                        if (link.linkColor != undefined){
                            return link.linkColor
                        } else{
                            return link.linkColor
                        }
                    }}
                    onLinkClick={(link:any) => {
                        return window.open(
                            link.link_url,
                            '_blank' // <- This is what makes it open in a new window.
                        );
                    }}
                    //   linkLabel={(link:any) => {
                    //     console.log(link)
                    //     return "djknsdnjkfdkjn"
                    //   }}
                    nodeCanvasObjectMode={() => "replace"}
                    nodeCanvasObject={(node:any, ctx:any, globalScale:any) => {
                        // const label = node.name.substring(0, [6]);
                        const label = node.friendly_name.name
                        const fontSize = 12 / globalScale;
                        ctx.font = `${fontSize}px Sans-Serif`;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "black"; //node.color;
                        // if (node.isClusterNode) {
                        //   ctx.fillText(label, node.x!, node.y!);
                        // } else {
                        //   ctx.fillText(label, node.x! + 20, node.y!);
                        // }
                        const size = 12      
                        
                        if (images && node.source){
                            ctx.drawImage(images.find(img => img.src === node.source), node.x! - size / 2, node.y! - size / 2, size, size);
                        }
        
                    }}
                    
                    linkCanvasObjectMode={() => "after"}
                    linkCanvasObject={(link:any, ctx:any, globalScale:any) => {
                        const MAX_FONT_SIZE = 4;
                        const LABEL_NODE_MARGIN = 12;
                        const start = link.source;
                        const end = link.target;
                        // ignore unbound links
                        if (typeof start !== 'object' || typeof end !== 'object') return;
                        // calculate label positioning
                        const textPos = Object.assign({},...['x', 'y']?.map(c => ({
                        [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                        })));
                        const relLink = { x: end.x - start.x, y: end.y - start.y };
                        const maxTextLength = Math.sqrt(Math.pow(relLink.x, 2) + Math.pow(relLink.y, 2)) - LABEL_NODE_MARGIN * 2;
                        let textAngle = Math.atan2(relLink.y, relLink.x);
                        // maintain label vertical orientation for legibility
                        if (textAngle > Math.PI / 2) textAngle = -(Math.PI - textAngle);
                        if (textAngle < -Math.PI / 2) textAngle = -(-Math.PI - textAngle);
                        // const label = link.linkType;
                        const label = link.linkLabel;
        
                        // estimate fontSize to fit in link length
                        ctx.font = '1px Sans-Serif';
                        const fontSize = Math.min(MAX_FONT_SIZE, maxTextLength / ctx.measureText(label).width);
                        ctx.font = `${fontSize}px Sans-Serif`;
                        const textWidth = ctx.measureText(label).width;
                        const bckgDimensions = [textWidth, fontSize]?.map(n => n + fontSize * 0.2); // some padding
                        // draw text label (with background rect)
                        ctx.save();
                        ctx.translate(textPos.x, textPos.y);
                        ctx.rotate(textAngle);
                        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        
                        ctx.fillRect(- bckgDimensions[0] / 2, - bckgDimensions[1] / 2, ...bckgDimensions);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'darkgrey';
                        // ctx.setLineDash([]);
                        
                        ctx.fillText(label, 0, 0);
                        ctx.restore();
                    }}
        
                    
                    />
                    </div>
                }
        </Show>
    );
};
