import { IResourceComponentsProps } from "@refinedev/core";
import { useAccount } from 'wagmi'

import {
    Create,
    useForm,
} from "@refinedev/antd";
const { TextArea } = Input;
import { Form, Input, Select } from "antd";

import { ITracer } from "interfaces";

export const TracersCreate: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps, onFinish } = useForm<ITracer>();

   
    return (
        <Create saveButtonProps={{ ...saveButtonProps}} 
        >
            <Form {...formProps}layout="vertical">
            <Form.Item
                    label="Address"
                    name="created_by"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
              
            </Form>
        </Create>
    );
};
