import React, { useState } from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { DeleteButton, SaveButton, Edit, useForm, TextField } from "@refinedev/antd";

import { Card, Checkbox, Form, Input, Select, Space, Tag, Radio, Tooltip, Flex, RadioChangeEvent } from "antd";
const { TextArea } = Input;

import { ISharedGraph } from "interfaces";

import { useAccount, useSignMessage } from 'wagmi'

export const SharedGraphEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult, onFinish } = useForm<ISharedGraph>();

    const postData = queryResult?.data?.data;
    const { open } = useNotification();

    const onFinishHandler = (values : any ) => {
        console.log("Hello on finish")

        onFinish({
            id: postData?.id,
            name: values.name,
            description: values.description,
            share_type: valueShareType ? valueShareType : postData?.share_type,
            update_type: valueUpdateType ? valueUpdateType : postData?.update_type,
        });

        // close();
    };

    const [valueShareType, setValueShareType] = useState(postData?.share_type);
    const [valueUpdateType, setValueUpdateType] = useState(postData?.update_type);

    const onChangeShareType = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        let updateType = valueUpdateType ? valueUpdateType : postData?.update_type

        if(updateType == "live" && e.target.value == "public"){
            open?.({
                type: "error",
                message: "Cannot set share type to public when update type is static",
                key: "shared-graph",
            }); 
        } 
            
        setValueShareType(e.target.value);
      };

      const onChangeUpdateType = (e: RadioChangeEvent) => {
          console.log('radio checked', e.target.value);
          let shareType = valueShareType ? valueShareType : postData?.share_type
       
          if(shareType == "public" && e.target.value == "live"){
            open?.({
                type: "error",
                message: "Cannot set share type to public when update type is static",
                key: "shared-graph",
            });
          }     
          setValueUpdateType(e.target.value);
        };

    return (
        <Edit saveButtonProps={{ ...saveButtonProps }} 
        >
            <Form {...formProps} layout="vertical" onFinish={onFinishHandler}>
                <Form.Item
                    label="Shared Graph Id"
                    name="id"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={false} />
                </Form.Item>     
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input disabled={false} />
                </Form.Item>
                <Form.Item
                    label="Share type"
                    name="share_type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeShareType} value={valueShareType} disabled={false}>
                        <Tooltip title="The user needs to be logged in and belong to the organization in order to see the graph"><Radio value={"private"}>Private</Radio></Tooltip>
                        <Tooltip title="The graph can be viewed without any authentication by anybody"><Radio value={"public"}>Public</Radio></Tooltip>
                    </Radio.Group>  
                </Form.Item>     
                <Form.Item
                    label="Update type"
                    name="update_type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeUpdateType} value={valueUpdateType} disabled={false}>
                        <Tooltip title="The transactions are stored as a snapshot"><Radio value={"static"}>Static</Radio></Tooltip>
                        <Tooltip title="The transactions are updated live. Can only be used when share type is 'Private'"><Radio value={"live"}>Live</Radio></Tooltip>
                    </Radio.Group>  
                </Form.Item>                                
            </Form>
        </Edit>

    );
};



