import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Tooltip } from "antd";
// import AceEditor from 'react-ace';

import { IIntegration } from "interfaces";
import Title from "antd/es/typography/Title";
import { isAddress } from 'viem'

export const IntegrationCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IIntegration>(
        {
            resource: "integrations",
            action: "create",
            redirect: false//"show",
        }
    );

    const { Option } = Select;

    let [config, setConfig] = React.useState<string>("EMPTY")
    let [source, setSource] = React.useState<string>("EMPTY")

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="integrations">
            {
                mutationResult?.data?.data.follow_build_here ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>New secret request can be followed here: <a href={mutationResult?.data?.data?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  

            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Environment"
                    name="environment"  
                    initialValue={process.env.REACT_APP_ENV == "dev" ? "sandbox" : process.env.REACT_APP_ENV}                 
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Continent"
                    name="region"  
                    initialValue={"us-west-2"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a continent">
                        <Option value="us-west-2">US (us-west-2/us-east-1)</Option>
                        <Option value="eu-central-1">EU (eu-central-1/eu-west-1)</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="integration_type"  
                    initialValue={"whitelisted"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a integration type">
                        <Option value="whitelisted">Whitelisted</Option>
                        <Option value="custom">Custom</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Stages"
                    name="integration_stages"  
                    initialValue={"pre-process"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        mode="multiple"
                        placeholder="Please select a stages">
                        <Option value="pre-process">pre-process</Option>
                        <Option value="process">process</Option>
                        <Option value="post-process">post-process</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="test name"/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Description here"/>
                </Form.Item>
                <Form.List
                    name="trigger_on_chain_id_addresses"  
                    initialValue={[""]}
                    rules={[
                    {
                        validator: async (_, names) => {
                            // if (names.includes("-")) {
                            //     return Promise.reject(new Error('Can not contain "-"'));
                            // }
                        },
                    },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            {...(index === 0 ? formItemLayout : formItemLayout)}
                            label={index === 0 ? 'Trigger on chain_id address' : ''}
                            required={true}
                            key={field.key}
                        >
                            <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                required: true,
                                whitespace: true,
                                message: "Please Trigger on chain_id address.",
                                },
                                {
                                    validator: async (_, names) => {
                                        if (names.includes("-")) {
                                            return Promise.reject(new Error('Can not contain "-"'));
                                        }
                                        if (!names.includes("_")) {
                                            return Promise.reject(new Error('Should contain "_"'));
                                        }
                                        if (!isAddress(names.split("_")[1])) {
                                            return Promise.reject(new Error('Not a valid address ' + names.split("_")[1]));
                                        }
                                    },
                                },
                            ]}
                            noStyle
                            >
                            <Input placeholder="0x1_0x78Cf1D91C94667a4a6615829e394C9CCe58fEc9E" style={{ width: '90%' }} />
                            </Form.Item>
                            {fields.length > 1 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                                onPointerEnterCapture={true} onPointerLeaveCapture={true}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '60%' }}
                            icon={<PlusOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true} />}
                        >
                            Add field trigger_on_chain_id_addresses
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                {/* <Form.Item
                    hidden={false}
                    label="Config"
                    name="config"   
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                <AceEditor  
                        style={{width: 1000}}
                        mode="json"
                        theme="monokai"
                        wrapEnabled={true}
                        onChange={newValue => {
                            try {
                                setConfig(newValue);
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                        name="integration_config"
                        editorProps={{ $blockScrolling: true }}
                /> 
                </Form.Item>
                <Form.Item
                    hidden={false}
                    label="Source"
                    name="source"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                <AceEditor  
                        style={{width: 1000}}
                        mode="json"
                        theme="monokai"
                        wrapEnabled={true}
                        onChange={newValue => {
                            try {
                                setSource(newValue);
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                        name="integration_source"
                        editorProps={{ $blockScrolling: true }}
                />
                </Form.Item>*/}
                <Form.Item
                    label="Source code file extension"
                    name="source_file_extension"  
                    initialValue={"py"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a source code file extension">
                        <Option value="py">py</Option>
                        <Option value="other">other</Option>
                    </Select>
                </Form.Item>
            </Form>

        </Create>
    );
};
