import { AxiosInstance } from "axios";
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";
import { DataProvider } from "@refinedev/core";
import {
    handleFilter,
    handlePagination,
    handleSort,
    handleJoin,
    axiosInstance,
} from "./utils";

const get_api_base_path = "GET/v0"
const any_api_base_path = "ANY/v0"
const public_get_api_base_path = "public/GET/v0"
const public_any_api_base_path = "public/ANY/v0"
const private_get_api_base_path = "private/GET/v0"
const private_any_api_base_path = "private/ANY/v0"
const management_get_api_base_path = "management/GET/v0"
const management_any_api_base_path = "management/ANY/v0"

const token = JSON.parse(localStorage.getItem('token') || '{}');
const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + token?.sub + "-" + token[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + token[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')

const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
const region = region_config?.region
const region_alias = region_config?.region_alias
const backup_region = region_config?.backup_region
const backup_region_alias = region_config?.backup_region_alias
const domain = process.env.REACT_APP_API_BASE_URL 

let use_region:any
let use_region_alias:any
let use_backup_region
let use_backup_region_alias

if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
    if(storage_use_region_alias == region_alias){
        use_backup_region = backup_region
        use_backup_region_alias = backup_region_alias
        use_region = region
        use_region_alias = region_alias                   
    } else{
        // Switch
        use_backup_region = region
        use_backup_region_alias = storage_use_region_alias
        use_region = backup_region
        use_region_alias = backup_region_alias
    }
} else{
    // Switch region based on time/minutes. To semi-randomly switch to backup region
    const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
    if(now > 2){ // 3 and 4
        use_backup_region = region_alias 
        use_backup_region_alias = region_alias 
        use_region = backup_region
        use_region_alias = backup_region_alias
        console.log("Using back up region: " + use_region_alias) 
    }
    else{ // 0,1,2
        use_backup_region = backup_region
        use_backup_region_alias = backup_region_alias
        use_region = region
        use_region_alias = region_alias
        console.log("Using main region: " + use_region_alias) 
    }
}

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Required<DataProvider> => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        console.log("from list")
        const url = `${apiUrl}/${resource}`;

        // console.log(url)
        let query = RequestQueryBuilder.create();

        query = handleFilter(query, filters);
        query = handleJoin(query, meta?.join);
        query = handlePagination(query, pagination);
        query = handleSort(query, sorters);

        // const { data } = await httpClient.get(`${url}?${query.query()}`);
        
        console.log(resource)

        if (resource == "transactions" ){
            console.log("Getting transactions")
            console.log(meta)
            const limit = meta?.limit ? meta?.limit : 10
            const last_evaluated_key = meta?.last_evaluated_key
            const search = meta?.search
            const { data, headers } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?search=${search}&limit=${limit}&last_evaluated_key=${last_evaluated_key}`);
            console.log("Finished Getting transactions")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
                last_evaluated_key: headers["x-last-evaluated-key"] ? headers["x-last-evaluated-key"] : null,
            };
        }
        else if (resource == "disputes" ){
            console.log("Getting disputes")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${address}&chain=${chain}`);
            console.log("Finished Getting disputes")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "permission-documents" ){
            console.log("Getting permisison documents")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${public_get_api_base_path}/${resource}?address=${address}&chain=${chain}`);
            console.log("Finished Getting permisison documents")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "tracers" ){
            console.log("Getting tracers")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/private/tracers/${get_api_base_path}/${resource}`);
            console.log("Finished Getting tracers")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }
        else if (resource == "actions" ){
            console.log("Getting actions")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/management/actions/${get_api_base_path}/${resource}`);
            console.log("Finished Getting actions")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }
        else if (resource == "integrations" ){
            console.log("Getting integrations")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/management/integrations/${get_api_base_path}/${resource}`);
            console.log("Finished Getting integrations")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }
        else if (resource == "users" ){
            console.log("Getting users")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/management/permissions/${get_api_base_path}/${resource}`);
            console.log("Finished Getting users")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }
        else if (resource == "search" ){
            console.log("Getting search")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/private/transactions-search/${get_api_base_path}/${resource}/${meta?.search_string}`);
            console.log("Finished Getting search")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }        
        else{
            console.log(`${apiUrl}/${private_get_api_base_path}/${resource}?${query.query()}`)
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${meta?.address}&version=ALL`);
            console.log(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${meta?.address}&version=ALL`)
            console.log("end from list")
            console.log(data)
            return {
                data: data,
                total: 10,
            };
        }
    },

    getMany: async ({ resource, ids, meta }) => {
        console.log("from get many ")
        const url = `${apiUrl}/${resource}`;

        let query = RequestQueryBuilder.create().setFilter({
            field: "id",
            operator: CondOperator.IN,
            value: ids,
        });

        query = handleJoin(query, meta?.join);

        const { data } = await httpClient.get(`${url}?${query.query()}`);

        console.log("end from get many ")
        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        console.log("create provider")
        console.log(variables)
        console.log(resource)

        if (resource == "permission-documents" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            
            return {
                data,
            };
        }
        else if (resource == "addresses" ){
            const url = `${apiUrl}/management/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "shared-graphs" ){
            const url = `${apiUrl}/private/analytics/shared-transactions-graphs/${any_api_base_path}/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }          
        else if (resource == "tags" ||  resource == "labels" || resource == "groups"){
            const url = `${apiUrl}/private/${resource}/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "roles" ){
            const url = `${apiUrl}/management/permissions/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "users" ){ 
            const url = `${apiUrl}/management/permissions/ANY/US/v0/${resource}`; 
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "users/invite" ){
            const url = `${apiUrl}/management/permissions/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "disputes" ){
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "tenants" ){
            // const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            const url = `https://${use_region_alias}.${continent}.management.sandbox.authe.io/ANY/${continent.toUpperCase()}/v0/organizations/${org_id}/tenants`
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "transactions-share-with" ){
            let new_id
            type ObjectKey = keyof typeof variables;
            const myVar = 'id' as ObjectKey;
            new_id = variables[myVar];

            const encodedId = encodeURIComponent(new_id as any);

            const url = `https://${use_region_alias}.${continent}.private.sandbox.authe.io/ANY/${continent.toUpperCase()}/v0/transactions/share-transaction/${encodedId}`
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "transactions-import" ){
            const url = `https://${use_region_alias}.${continent}.private.sandbox.authe.io/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "transaction_group_memberships" ){
            // Overwrite to put incase of update groups on transactions
            console.log("Updating the transaction to the latest value")
            
            const url = `${apiUrl}/private/groups/ANY/US/v0/groups/transactions/`;

            const { data } = await httpClient.post(url, variables);
            console.log("Finished Updating the transaction to the latest value")            

            return {
                data: data,
            };
        }            
        else {
            // const url = `${apiUrl}/${resource}`;
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;

            const { data } = await httpClient.post(url, variables);

            return {
                data,
            };
        }
    },

    update: async ({ resource, id, variables }) => {
        console.log("update provider")
        console.log(id)
        console.log(variables)
        console.log("end variables update provider")

        if (resource == "permission-documents" ){
            console.log("Updating the permission document to the latest value")
            const { data } = await httpClient.put(`${apiUrl}/${private_any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished Updating the permission document to the latest value")
            return {
                data,
            };
            
        }
        else if (resource == "addresses"){
            console.log(`Updating the ${resource} to the latest value`)
            const { data } = await httpClient.put(`${apiUrl}/${management_any_api_base_path}/${resource}/${id}`, variables);
            console.log(`Finished Updating the ${resource} to the latest value`)
            return {
                data,
            };
            
        }
        else if (resource == "shared-graphs" ){
            console.log(`Updating the ${resource} to the latest value`)
            const { data } = await httpClient.put(`${apiUrl}/private/analytics/shared-transactions-graphs/${any_api_base_path}/${resource}/${id}`, variables);
            console.log(`Finished Updating the ${resource} to the latest value`)            
            return {
                data,
            };
        }          
        else if (resource == "actions" ){
            let new_id
            if (id === undefined || id === null || id == ""){
                console.log("id not defined")
                type ObjectKey = keyof typeof variables;

                const myVar = 'id' as ObjectKey;

                new_id = variables[myVar];
            } else{
                new_id = id
            }
            console.log(apiUrl)
            console.log(`Updating the ${resource} to the latest value`)
            let url = `${apiUrl}/management/actions/${any_api_base_path}/${resource}/${new_id}`
            console.log(`url is: ${url}`)
            const { data } = await httpClient.put(url, variables);
            console.log(`Finished Updating the ${resource} to the latest value:`)
            return {
                data,
            };
            
        }
        else if (resource == "integrations" ){
            let new_id
            if (id === undefined || id === null || id == ""){
                console.log("id not defined")
                type ObjectKey = keyof typeof variables;

                const myVar = 'id' as ObjectKey;

                new_id = variables[myVar];
            } else{
                new_id = id
            }
            console.log(apiUrl)
            console.log(`Updating the ${resource} to the latest value`)
            let url = `${apiUrl}/management/integrations/${any_api_base_path}/${resource}/${new_id}`
            console.log(`url is: ${url}`)
            const { data } = await httpClient.put(url, variables);
            console.log(`Finished Updating the ${resource} to the latest value:`)
            return {
                data,
            };
            
        }
        else if (resource == "users" ){
            let new_org_id
            type ObjectKey = keyof typeof variables;
            const myVar = 'org_id' as ObjectKey;
            new_org_id = variables[myVar];

            const encodedOrgId = encodeURIComponent(new_org_id as any);

            let new_user_id

            const myUserVar = 'user_id' as ObjectKey;
            new_user_id = variables[myUserVar];

            const encodedUserId = encodeURIComponent(new_user_id as any);

            const url = `${apiUrl}/management/permissions/ANY/US/v0/organizations/${encodedOrgId}/${resource}/${encodedUserId}`;

            console.log(`url is: ${url}`)
            const { data } = await httpClient.put(url, variables);
            console.log(`Finished Updating the ${resource} to the latest value:`)
            return {
                data,
            };

            // let new_id
            // if (id === undefined || id === null || id == ""){
            //     console.log("id not defined")
            //     type ObjectKey = keyof typeof variables;

            //     const myVar = 'id' as ObjectKey;

            //     new_id = variables[myVar];
            // } else{
            //     new_id = id
            // }
            // console.log(apiUrl)
            // console.log(`Updating the ${resource} to the latest value`)
            // let url = `${apiUrl}/management/permissions/${any_api_base_path}/${resource}/${new_id}`
            // console.log(`url is: ${url}`)
            // const { data } = await httpClient.put(url, variables);
            // console.log(`Finished Updating the ${resource} to the latest value:`)
            // return {
            //     data,
            // };
            
        }
        else if (resource == "tags" || resource == "labels" || resource == "groups" ){
            console.log(`Updating the ${resource} to the latest value`)
            let new_id
            if (id === undefined || id === null || id == ""){
                console.log("id not defined")
                type ObjectKey = keyof typeof variables;

                const myVar = 'id' as ObjectKey;

                new_id = variables[myVar];
            } else{
                new_id = id
            }
            const { data } = await httpClient.put(`${apiUrl}/private/${resource}/ANY/US/v0/${resource}/${new_id}`, variables);
            console.log(`Finished Updating the ${resource} to the latest value`)
            return {
                data,
            };
        }
        else if (resource == "disputes" ){
            console.log("Updating the disputes to the latest value")
            const { data } = await httpClient.put(`${apiUrl}/${private_any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished Updating the dispute to the latest value")
            return {
                data,
            };
            
        }
        else if (resource == "transactions" ){
            console.log("Updating the transaction to the latest value")
            const { data } = await httpClient.put(`${apiUrl}/${private_any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished Updating the dispute to the latest value")
            return {
                data,
            };
            
        }
        else if (resource == "tracers" ){
            console.log("Updating the tracer")
            const { data } = await httpClient.put(`${apiUrl}/private/tracers/${any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished updating tracer")
            console.log(data)
            return {
                data: data
            };
        }
        else{

            const url = `${apiUrl}/${resource}/${id}`;
            const { data } = await httpClient.patch(url, variables);

            return {
                data,
            };
        }
    },

    updateMany: async ({ resource, ids, variables }) => {
        console.log("update many provider")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        console.log("create many provider")
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables });

        return {
            data,
        };
    },

    
    getOne: async ({ resource, id, meta }) => {
        console.log("get one provider: " + resource)
        console.log("from get one ")
        console.log("hello world: " + id)
        console.log(meta)
        if (resource == "transactions" ){
            if(!id || id == "" || id == undefined){
                console.log("No id given return empty")
                return {
                    data: "",
                };
            }
            // console.log("Getting transactions")
            // console.log(meta)
            // const address = meta?.address 
            // const chain = meta?.chain
            // const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}/${id}?chain=sepolia`);
            // console.log("Finished Getting transactions")
            // console.log(data)
            // return {
            //     data: data,
            //     total: 100,
            // };
        }
        if (resource == "transactions-graph" ){
            console.log("Getting graph transactions")
            console.log(meta)
            const filterCurrentDisplayedData = meta?.filterCurrentDisplayedData 
            const showInternalTransactions = meta?.showInternalTransactions
            const filterOnChain = meta?.filterOnChain
            const previouslyRetrievedData = meta?.previouslyRetrievedData
            const newGraphData = meta?.newGraphData

            if(filterCurrentDisplayedData == true && previouslyRetrievedData){
                let new_data = previouslyRetrievedData
                console.log("filter filterCurrentDisplayedData")
                console.log(new_data)

                if(filterOnChain && filterOnChain != "all"){
                    let nodes = new_data?.nodes?.filter((node: { id: any; }) => node?.id?.startsWith(filterOnChain))
                    console.log("filter filterOnChain: " + filterOnChain)
                    
                    // The chart converts links source and target automatically. So the initial retrieved data can be modified/augmented
                    let links = new_data?.links?.filter((link: { source: any; }) => link?.source?.id?.startsWith(filterOnChain) || link?.source?.startsWith(filterOnChain))

                    new_data = {
                        "nodes": nodes,
                        "links": links
                    }
                }

                if (!showInternalTransactions){
                    console.log("filter internal transactions")
                    let nodes = new_data?.nodes?.filter((node: { is_internal_transaction: any; }) => node?.is_internal_transaction != true)
                    let links = new_data?.links?.filter((link: { is_internal_transaction: any; }) => link?.is_internal_transaction != true)
                    console.log(nodes)
                    new_data = {
                        "nodes": nodes,
                        "links": links
                    }                
                }                
                console.log(new_data)
            
                return {
                    data: new_data,
                    total: 100,
                };
            } 

            const { data } = await httpClient.get(`${apiUrl}/private/${resource}/GET/v0/transactions`);
            console.log("Finished Getting transactions-graph")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "addresses" ){
            let url = `${apiUrl}/${management_get_api_base_path}/${resource}/${id}`;
    
            // Add chain_ids as query params if present in meta
            if (meta?.chain_ids) {
                url += `?chain_ids=${meta.chain_ids}`;
            }
            
            console.log(`Making request to: ${url}`);
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")

            // For edit address add a key attribute. TODO figure out how to do this properly in the ui
            if (data?.labels) {
                data?.labels.forEach(function (label:any) {
                    label.key = label.id
                  }); 
            }
            
            return {
                data,
            };
        }
        else if (resource == "shared-graphs" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/private/analytics/shared-transactions-graphs/${get_api_base_path}/${resource}/${id}`;
            console.log(`${apiUrl}/private/analytics/shared-transactions-graphs/${get_api_base_path}/${resource}/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            return {
                data,
            };
        }    
        else if (resource == "subscriptions" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/management/${resource}/${get_api_base_path}/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.get(url)
            console.log("end from get one ")
            return {
                data,
            };
        }              
        else if (resource == "actions" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/management/actions/${get_api_base_path}/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            console.log(data)
            return {
                data,
            };
        }
        else if (resource == "integrations" ){
            const url = `${apiUrl}/management/integrations/${get_api_base_path}/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            console.log(data)
            return {
                data,
            };
        }
        else if (resource == "users" ){
            const url = `${apiUrl}/management/permissions/${get_api_base_path}/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            console.log(data)
            return {
                data,
            };
        }
        else if (resource == "permission-documents" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${public_get_api_base_path}/${resource}/${id}`;
            console.log(`${apiUrl}/${public_get_api_base_path}/${resource}/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            console.log(data)
            return {
                data,
            };
        }
        else if (resource == "permission-documents-show" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${public_get_api_base_path}/permission-documents/${id}`; // A hack to display the json correctly
            console.log(`${apiUrl}/${public_get_api_base_path}/permission-documents/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            return {
                data,
            };
        }
        else if (resource == "disputes" ){
            const url = `${apiUrl}/${private_get_api_base_path}/disputes/${id}`; 
            console.log(`${apiUrl}/${private_get_api_base_path}/disputes/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log(data)
            console.log("end from get one ")
            return {
                data,
            };
        }
        else if (resource == "tracers" ){
            console.log("Getting tracers")
            console.log(meta)
            const { data, headers } = await httpClient.get(`${apiUrl}/private/tracers/${get_api_base_path}/${resource}/${id}`);
            console.log("Finished Getting tracers")
            console.log(data)
            console.log(headers)
            return {
                data: data,
                total: headers["x-total-count"] ? headers["x-total-count"] : data.length,
            };
        }
        else{
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${private_get_api_base_path}/${resource}/${id}`;
            
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            return {
                data,
            };
        }
    },

    deleteOne: async ({ resource, id, meta }) => {
        console.log("delete one provider")
        console.log(meta)

        const url = `${apiUrl}/${resource}/${id}`;

        if (resource == "addresses" ){
            const url = `${apiUrl}/management/ANY/US/v0/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.delete(url);
            return {
                data: data,
            };
        }
        else if (resource == "shared-graphs" ){
            const url = `${apiUrl}/private/analytics/shared-transactions-graphs/ANY/US/v0/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.delete(url);
            return {
                data,
            };
        }         
        else if ( resource == "actions" ){
            const url = `${apiUrl}/management/actions/ANY/US/v0/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.delete(url);
            return {
                data: data,
            };
        }
        else if (resource == "tags" || resource == "labels" || resource == "groups" ){
            const url = `${apiUrl}/private/${resource}/ANY/US/v0/${resource}/${id}`;
            console.log(url)
            const { data } = await httpClient.delete(url);
            return {
                data: data,
            };
        }
        else if (resource == "transactions-share-with" ){
            console.log(id)
            console.log(resource)
            console.log(meta)
            
            var payload = {
                "shared_with_to_add": [],
                "shared_with_to_remove": [meta?.remove_user_email]
            }

            const encodedId = encodeURIComponent(id as any);

            const url = `${apiUrl}/private/ANY/US/v0/transactions/share-transaction/${encodedId}`;
            console.log(url)
            const { data } = await httpClient.delete(url, { data: payload });
            return {
                data: data,
            };
        }
        else if (resource == "transaction_group_memberships" ){
            // Overwrite to put incase of update groups on transactions
            console.log("Updating the transaction to the latest value")

            const url = `${apiUrl}/private/groups/ANY/US/v0/groups/transactions/${id}`;

            const { data } = await httpClient.delete(url, { data: 
                {
                    "group_names_to_remove": meta?.group_names_to_remove,
                    "group_names_to_add": meta?.group_names_to_add
                }
             });
            console.log("Finished Updating the transaction to the latest value")
            return {
                data,
            };
            
        }        
        else{
            const { data } = await httpClient.delete(url);
            return {
                data,
            };
        }
    },

    deleteMany: async ({ resource, ids }) => {
        console.log("delete many provider")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        console.log("get api provider")
        return apiUrl;
    },

    custom: async ({
        url,
        method,
        meta,
        filters,
        sorters,
        payload,
        query,
        headers,
    }) => {
        console.log("custom provider")
        let requestQueryBuilder = RequestQueryBuilder.create();

        requestQueryBuilder = handleFilter(requestQueryBuilder, filters);

        requestQueryBuilder = handleJoin(requestQueryBuilder, meta?.join);

        requestQueryBuilder = handleSort(requestQueryBuilder, sorters);

        let requestUrl = `${url}?${requestQueryBuilder.query()}`;

        // if (headers) {
        //     httpClient.defaults.headers = {
        //         ...httpClient.defaults.headers,
        //         ...headers,
        //     };
        // }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
