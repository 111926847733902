import React, { useState } from "react";

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { Show } from "@refinedev/antd";

import { Button, Typography } from "antd";

import { ITracer } from "interfaces";

const { Title, Text } = Typography;

// import { utils } from "ethers";

export const TracersShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ITracer>();
    const { data, isLoading } = queryResult;
    const record = data?.data;


    


      
    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<ITracer>({
            resource: "tracers",
            id: record?.tracer_id || "",
            queryOptions: {
                enabled: !!record,
            },
            meta: {"id": record?.tracer_id}
        });

     
    return (
        <Show isLoading={isLoading} footerButtons={() => (
            <>
            </>
        )}
        >
            <Title level={5}>Id</Title>
            <Text>{record?.tracer_id}</Text>

        </Show>
    );
};
