import React from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { Edit, ListButton, RefreshButton, SaveButton, TagField, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from "antd";
import axios from 'axios';
const { Title, Text } = Typography;
// import AceEditor from 'react-ace';

// import 'ace-builds/src-noconflict/mode-json';
// import 'ace-builds/src-noconflict/theme-monokai';


import { IIntegration } from "interfaces";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
export const IntegrationEdit: React.FC<IResourceComponentsProps> = () => {
      
    const navigate = useNavigate();
    const params= useParams()
    

    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult, queryResult } = useForm<IIntegration>(
        {
            resource: "integrations",
            action: "edit",
            id: params.id,
            redirect: false
        }
    );
    const record = queryResult?.data?.data;

    const { open } = useNotification();

    const { Option } = Select;

    let [editUserConfigVersion, setEditUserConfigVersion] = React.useState<any>(null)
    let [editTenantConfigVersion, setEditTenantConfigVersion] = React.useState<any>(null)
    let [editOrgConfigVersion, setEditOrgConfigVersion] = React.useState<any>(null)

    let [isLoadingNewVersion, setIsLoadingNewVersion] = React.useState<boolean>(false)


    const refreshTableResults = () => {     
        queryResult?.refetch()
        setEditUserConfigVersion(null)
        setEditTenantConfigVersion(null)
        setEditOrgConfigVersion(null)
    };

    async function handleOnClickSaveButton(action: string) {

        setIsLoadingNewVersion(true)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        let configs = {
            "org_config": {
                "name": queryResult?.data?.data.org_config?.name, 
                "value": editOrgConfigVersion,
            },
            "tenant_config": {
                "name": queryResult?.data?.data.tenant_config?.name, 
                "value": editTenantConfigVersion,
            },
            "user_config": {
                "name": queryResult?.data?.data.user_config?.name, 
                "value": editUserConfigVersion,
            }
        }

        for (let config in configs) {
            console.log(config)
            const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
            console.log("Sending the request")
            let url
            if (qa_environment == "blue"){
                url = `${apiUrl}/management/client-config/blue/ANY/${continent.toUpperCase()}/v0/update-ssm-param-multi-region`
            } 
            else{
                url = `${apiUrl}/management/client-config/ANY/${continent.toUpperCase()}/v0/update-ssm-param-multi-region`
            }
            console.log(url)

        
            let config_name = configs[config as keyof typeof configs].name;
            console.log(config_name)
            let config_value = configs[config as keyof typeof configs].value;
            console.log(config_value)

            // let config_name = queryResult?.data?.data["org_config" as keyof typeof configs ]}

            let obj = {
                "name": config_name,
                "value": config_value
            }
            console.log(obj)

            if(obj.value == undefined){
                console.log("Nothing to update for " + config_name)
                if(config_name != undefined)
                open?.({
                    type: "success",
                    message: "Nothing to update for " + config_name,
                    key: "client-configs",
                });
            } 
            else{
                try {
                    const { data, status } = await axios.post(
                        url,
                        JSON.stringify(obj), {
                            headers: requestHeaders
                        }
                    )
                    console.log(data)
                    console.log(status)
                    let new_message = config_name
                    let new_description = "Updated config"
                
                    open?.({
                        type: "success",
                        message: new_message,
                        description: new_description,
                        key: "client-configs",
                    });
                } catch (error: any) {
                    console.log(error.response); 
                    setIsLoadingNewVersion(false)
                    
                    return error.response;
                    
                }       
            }
        }

        setIsLoadingNewVersion(false)
    }
    
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} resource="integrations" headerButtons={({ listButtonProps, refreshButtonProps }) => (
            <>
              {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton {...refreshButtonProps} disabled={queryResult?.isLoading || queryResult?.isFetching } loading={queryResult?.isLoading || queryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )} 

            
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    footerButtonProps={{
        style: {
            float: "left",
            marginLeft: "24px"
        },
    }}
      footerButtons={({ saveButtonProps }) => (
        
        <>
          <SaveButton {...saveButtonProps} onClick={(e:any) => handleOnClickSaveButton("new_version")} loading={isLoadingNewVersion} >Save</SaveButton>
        </>
        
      )}
      >
            {queryResult?.isLoading || queryResult?.isFetching  ? <Spin size="large" /> : null}
            <Form {...formProps} layout="vertical">
                <Title level={5}>Integration Environment</Title>
                <TagField value={record?.get_integration_result.environment}></TagField>
                <Title level={5}>Integration Region</Title>
                <TagField color={colors[record?.get_integration_result.region as keyof typeof colors ]} value={record?.get_integration_result.region}></TagField>
                <Title level={5}>Integration Org Id</Title>
                <Text>{record?.get_integration_result.org_id}</Text>
                <Title level={5}>Integration Tenant Id</Title>
                <Text>{record?.get_integration_result.tenant_id}</Text>
                <Title level={5}>Integration User Id</Title>
                <Text>{record?.get_integration_result.created_by}</Text>
                <Title level={5}>Integration Id</Title>
                <Text>{record?.get_integration_result.id}</Text>
                <Title level={5}>Integration Name</Title>
                <Text>{record?.get_integration_result.name}</Text>
                <Divider></Divider>
                <Form.Item
                    label="Integration Status"
                    name="status"  
                    initialValue={record?.get_integration_result.status}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select the integration status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Integration Stages"
                    name="integration_stages"  
                    initialValue={record?.get_integration_result.integration_stages}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        mode="multiple"
                        placeholder="Please select the integration stage">
                        <Option value="pre-process">pre-process</Option>
                        <Option value="process">process</Option>
                        <Option value="post-process">post-process</Option>
                    </Select>
                </Form.Item>
            </Form>            
        </Edit>
        
    );
};

