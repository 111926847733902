// see https://codesandbox.io/p/sandbox/force-directed-clusters-with-root-node-forked-guz4g7?file=%2Fsrc%2Findex.js%3A11%2C1
import React, { useEffect, useState, useRef, useMemo } from 'react';


import { IResourceComponentsProps, useInvalidate, useOne, useUpdate } from "@refinedev/core";
// import data from "./miserables";

import {
    List,
    useTable,
    useModalForm,
    RefreshButton,
    CreateButton,
    DeleteButton,
    ShowButton,
    Show,
    useModal,
} from "@refinedev/antd";

import { Form, Input, Modal, Avatar, Card, Skeleton, Switch, Col, Row, Spin, Space, Tooltip, Select, Tag, Divider, Checkbox, Button, Alert, Flex, Progress  } from "antd";
const { Meta } = Card;
import { EyeOutlined, EditOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { IGraphTransaction, IIntegration, ISubscription } from "interfaces";
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';
import ForceGraph2D from "react-force-graph-2d";
const { Title, Text } = Typography;

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import * as subscriptions from "../../graphql/subscriptions"; //codegen generated code
import { publish } from '../../graphql/mutations';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { id } from 'ethers';

//AppSync endpoint settings
Amplify.configure({
    API: {
      GraphQL: {
        endpoint: "https://stream-transactions.pdx.us.private.sandbox.authe.io/graphql",
        region: 'us-west-2',
        defaultAuthMode: 'lambda', // 'apiKey'
        // apiKey: "da2-xrpjzjrx2rggpdgiwgvtrivlvq",
        
      }
    }
  });
const client = generateClient();        

export const DashboardList: React.FC<IResourceComponentsProps> = () => {
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const [newGraphData, setNewGraphData] : any[] = useState({ "nodes": [], "links": [] });
    const [newGraphDataNodesMap, setNewGraphDataNodesMap] : any = useState(new Map());
    const [newGraphDataLinksMap, setNewGraphDataLinksMap] : any = useState(new Map());
    const [existingGraphDataNodesMap, setExistingGraphDataNodesMap] : any = useState(new Map());
    const [existingGraphDataLinksMap, setExistingGraphDataLinksMap] : any = useState(new Map());
    const [alreadyReceivedStreamMessageIds, setAlreadyReceivedStreamMessageIds] : any = useState(new Map());
    const [receivedStreamedItemIds, setReceivedStreamedItemIds]:any = React.useState<String[]>([])

    const CheckboxGroup = Checkbox.Group;
    const [filterCurrentDisplayedData, setFilterCurrentDisplayedData] = useState<boolean>(true);
    const [showInternalTransactions, setShowInternalTransactions] = useState<boolean>(true);
    const [groupInternalTransactions, setGroupInternalTransactions] = useState<boolean>(true);
    const [filterOnChain, setFilterOnChain] = useState<string>("all");
    const [previouslyRetrievedData, setPreviouslyRetrievedData] = useState<any>();

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
    const tenant_id = token[process.env.REACT_APP_BASE_URL + "/tenant_id"] 
    const user_id = token["sub"] 
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
    const domain = process.env.REACT_APP_API_BASE_URL
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { data: graphData, isLoading: graphDataIsLoading, isFetching, isRefetching, refetch } =
        useOne<IGraphTransaction>({
            resource: "transactions-graph",
            id: "",
            queryOptions: {
                enabled: true,
            },
            meta: {
                "id": "",
                "filterCurrentDisplayedData": filterCurrentDisplayedData,
                "showInternalTransactions": showInternalTransactions,
                "group_internal_transactions": groupInternalTransactions,
                "filterOnChain": filterOnChain,
                "previouslyRetrievedData": previouslyRetrievedData,
            },
        });

    const { data: subscriptionData, isLoading: subscriptionIsLoading, isFetching: subscriptionIsFetching, isRefetching: subscriptionIsRefetching, refetch: refetchSubscription } =
        useOne<ISubscription>({
            resource: "subscriptions",
            id: "main",
    });


    // Create shared graph
    const [sharableGraphUrl, setSharableGraphUrl] = useState<any>();

    const {
        modalProps: createSharedGraphModalProps,
        formProps: createSharedGraphFormProps,
        show: createSharedGraphModalShow,
        formLoading: createSharedGraphFormLoading,
        onFinish,
        close
    } = useModalForm({
        action: "create",
        syncWithLocation: false,
        warnWhenUnsavedChanges: false,
        resource:"shared-graphs",
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            console.log(data)
            type ObjectKey = keyof typeof data;
            const myVar = 'id' as ObjectKey;            
            setSharableGraphUrl(data.data[myVar])
            close();
        },
    });

    const onFinishShareGraph = (values : any ) => {
        console.log("hello finish")
        console.log(values)
      
        let new_obj = {
            name: `${values.name}`,
            share_type: `${values.share_type}`,
            update_type: `${values.update_type}`,
            include_streamed_transactions: values.include_streamed_transactions,
            nodes: graphDataNew.nodes,
            links: graphDataNew.links,
            streamed_transactions_item_ids: receivedStreamedItemIds,
        }


        // console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };

    const [images, setImages] = useState<Array<HTMLImageElement>>();
    let enabled_chains = [
        {"label": "All", "value": "all"},
        {"label": "Ethereum", "value": "0x1"},
        {"label": "Goerli", "value": "0x5" },
        {"label": "Sepolia", "value": "0xaa36a7" },
        {"label": "Polygon", "value": "0x89" },
        {"label": "Mumbai", "value": "0x13881" },
        {"label": "Bsc", "value": "0x38" },
        {"label": "Bsc Testnet", "value": "0x61" },
        {"label": "Avalanche", "value": "0xa86a" },
        {"label": "Fantom", "value": "0xfa" },
        {"label": "Cronos", "value": "0x19" },
        {"label": "Palm", "value": "0x2a15c308d" },
        {"label": "Arbitrum", "value": "0xa4b1" },
        {"label": "Gnosis", "value": "0x64" },
        {"label": "Gnosis Chiado", "value": "0x27d8" },
        {"label": "Chiliz", "value": "0x15b38" },
        {"label": "Chiliz Spicy", "value": "0x15b32" },
        {"label": "Base", "value": "0x2105" },
        {"label": "Base Goerli", "value": "0x14a33" },
    ]

    function componentDidMount() {
            const nodeImages = graphData?.data?.nodes?.map(node => node.source);
            const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))

            const images = nodeImagesUnique?.map(image => {
            const img = new Image();
            img.src = image;
            return img;
            });
            setImages(images)
            console.log(images)
        }

    const fgRef = useRef<any>();

    useEffect(() => {
        console.log(filterCurrentDisplayedData)
        if(!images){
            componentDidMount()
        }

        if(graphData && !previouslyRetrievedData){
            // Will break the websockets stream transactions. But is needed when wanting to toggle between 'show internal transactions'
            // setPreviouslyRetrievedData(graphData?.data)
        }

        const current = fgRef?.current
        // Only zoom on load
        if(!images){
            current?.zoom(5)
        }        
    }, [images, graphData]);

    const graphDataNew = useMemo(() => {
    //     if(graphData){
    //     graphData["data"]["nodes"] = [
    //         {
    //             "id": "0",
    //             "name": "project, idea, user",
    //             "friendly_name": "3232",
    //             "source": "https://${cdn_domain_name}/public/GET/cdn/charts/logos/png/chainlink.function.894389438934.png"
    //         },            
    //         {
    //             "id": "1",
    //             "name": "project, idea, user",
    //             "friendly_name": "3232",
    //             "source": "https://${cdn_domain_name}/public/GET/cdn/charts/logos/png/chainlink.function.894389438934.png"
    //         }
    //     ]     
    // }
        const nodeImages = graphData?.data?.nodes?.map(node => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))
    
        const images = nodeImagesUnique?.map(image => {
          const img = new Image();
          img.src = image;
          return img;
        });
        setImages(images)

        setNewGraphData({ nodes: graphData ? graphData?.data?.nodes : [], links: graphData ? graphData?.data?.links : [] });
        var node_result = new Map(graphData?.data?.nodes?.map(node => [node?.id, node]));
        var link_result = new Map(graphData?.data?.links?.map(link => [link?.id, link]));

        setExistingGraphDataNodesMap(node_result)
        setExistingGraphDataLinksMap(link_result)

        return {
          "nodes": graphData ? graphData?.data?.nodes : [],
          "links": graphData ? graphData?.data?.links : []
        };
    }, [graphData]);

    function filterByChain(chain: any) {
        console.log(chain)
        setFilterOnChain(chain)
        refetch()
    }

    const [streamedData, setStreamedData] :any = useState();
    const [received, setReceived] = useState('');

    // Define the channel name here
    let name = org_id + "-" + tenant_id + "-" + user_id + "-streamed-transactions";
    
    // subscribe to events
    useEffect(() => {
        // TODO Deduplicate the messages based on the message id. They can come from 2 different regions
        const sub = client.graphql({ query: subscriptions.subscribe, variables: { name }, authToken:`Bearer ${token.__raw}` }).subscribe({
            next: ({ data }) => {
                let parsedData = JSON.parse(data.subscribe.data)
                if(!alreadyReceivedStreamMessageIds.has(parsedData?.message_id)){
                    let new_message: any = new Map()
                    new_message.set("parsedData?.message_id", "true")
                    setAlreadyReceivedStreamMessageIds(new Map(alreadyReceivedStreamMessageIds.set(parsedData?.message_id, parsedData?.message_id)))
                    setStreamedData(parsedData)
                    // Add item sk to the array so that it can be later shared in the share graph function
                    if(parsedData?.item_sk){
                        setReceivedStreamedItemIds((prev: string[]) => (prev.includes(parsedData?.item_sk) ? [...receivedStreamedItemIds] : [...receivedStreamedItemIds, parsedData?.item_sk]));
                    }
                } else{
                    console.log("Message id is already processed: " + parsedData?.message_id)
                    console.log(alreadyReceivedStreamMessageIds)
                }
            },
            error: (error) => console.warn(error),
        });
        console.log("test")
        return () => sub.unsubscribe();
    }, [name]);

    useEffect(() => {
        console.log("test")

        const { nodes, links } = newGraphData;

        const newNodes = streamedData?.nodes ? streamedData?.nodes : []
        const newLinks = streamedData?.links ? streamedData?.links : []

        let nodesToAdd: any[] = []
        let linksToAdd: any[] = []
        newNodes.forEach( (node: { [x: string]: any; }) => {
            if(!existingGraphDataNodesMap.has(node["id"])){
                nodesToAdd.push(node)
            } else{
                // Update the attributes of the node
            }
        });        
   
        newLinks.forEach( (link: { [x: string]: any; }) => {
            if(!existingGraphDataLinksMap.has(link["id"])){
                linksToAdd.push(link)
            } else{
                // Update the attributes of the link
                let link_id = link["id"]
                let existingLink = existingGraphDataLinksMap.get(link_id)
                existingLink["friendly_name"] = link["friendly_name"]
                existingLink["original_friendly_name"] = link["original_friendly_name"]
                existingLink["linkColor"] = link["linkColor"]
                existingLink["linkArrowColor"] = link["linkArrowColor"]
                existingLink["name"] = link["name"]
            }
        });   

        let mergedNodes = [...nodes, ...nodesToAdd]
        let mergedLinks = [...links, ...linksToAdd]

        // Make sure the images in the graph are available
        const nodeImages = mergedNodes?.map((node: { source: any; }) => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))
        const images = nodeImagesUnique?.map(image => {
          const img: HTMLImageElement = new Image();
          img.src = image as string;
          return img;
        });
        setImages(prev => images)
                
        // All nodes and links
        setNewGraphData({ nodes: mergedNodes, links: mergedLinks });
        console.log(nodesToAdd)
        console.log(mergedNodes)

        // Define the existing nodes and links that should always be static
        var node_result = new Map(mergedNodes?.map(node => [node?.id, node]));
        var link_result = new Map(mergedLinks?.map(link => [link?.id, link]));
        setExistingGraphDataNodesMap(node_result)
        setExistingGraphDataLinksMap(link_result)

        // Define the dynamic streamed nodes/links
        let mergedNewNodesMap = new Map([...newGraphDataNodesMap, ...nodesToAdd?.map((node => [node?.id, node])) ])
        let mergedNewLinksMap:Map<string, any> = new Map([...newGraphDataLinksMap, ...linksToAdd?.map(link => [link?.id, link])])
        console.log(mergedNewNodesMap)
        console.log(mergedNewLinksMap)
        setNewGraphDataNodesMap(mergedNewNodesMap)
        setNewGraphDataLinksMap(mergedNewLinksMap)
    }, [streamedData]);

     
    const [importTransactionsInProgress, setImportTransactionsInProgress] = useState<number>();
    const [importTransactionsToBeProcessed, setImportTransactionsToBeProcessed] = useState<number>();
    const [importTransactionsProcessed, setImportTransactionsProcessed] = useState<number>();
    const [totalTransactionsToImport, setTotalTransactionsToImport] = useState<number>();
    const [maxImportTransactionsPerDay, setMaxImportTransactionsPerDay] = useState<number>();

    useEffect(() => {
        if(subscriptionData){
            setImportTransactionsInProgress(Number(subscriptionData.data.total_transactions_in_progress))
            setImportTransactionsToBeProcessed(Number(subscriptionData.data.total_transactions_to_be_processed))
            setImportTransactionsProcessed(Number(subscriptionData.data.total_transactions_processed))
            setTotalTransactionsToImport(Number(subscriptionData.data.total_transactions_to_import))
            setMaxImportTransactionsPerDay(Number(subscriptionData.data.max_nr_of_moralis_transactions_per_day))

            if(Number(subscriptionData.data.total_transactions_in_progress) > 0 || Number(subscriptionData.data.total_transactions_to_be_processed) > 0){
                //running the api call every 5 seconds
                console.log("Refetching subscription data")
                const interval = setInterval(() => {
                    refetchSubscription()
                }, 5000);
                return () => clearInterval(interval);
            }
        }
    }, [subscriptionData]);

    // Todo check this example: https://codesandbox.io/p/sandbox/force-directed-clusters-with-links-between-clusters-forked-tsbf3b?file=%2Fsrc%2Findex.js%3A70%2C15-70%2C28
return (
    <Show title="Dashboard" headerButtons={() => (
        <>
          <RefreshButton loading={graphDataIsLoading || isFetching || isRefetching} onClick={(e) => refetch()}></RefreshButton>
        </>
    )}>
        { subscriptionData ?
           <>
           <Tooltip title={importTransactionsProcessed + " done / " + importTransactionsInProgress + " in progress / " + importTransactionsToBeProcessed + " to do / maximum per day to import " + maxImportTransactionsPerDay }>
            <Flex gap="small" vertical>
            {   subscriptionData.data.superseded_daily_spend == true ? 
                <Alert style={{marginLeft:10}}
                    message={<span>Maximum amount of transactions to import per day reached. Upgrade here: <a href={sharableGraphUrl} target="_blank"> link </a> </span> }
                    type="info" 
                />
            : ""
            }
                <Flex gap="small"> <Title level={2}>Importing transactions</Title> <Spin spinning={Number(subscriptionData.data.total_transactions_in_progress) > 0 || Number(subscriptionData.data.total_transactions_to_be_processed) > 0}></Spin></Flex> 
                    <Progress percent={Math.round(Number(importTransactionsInProgress) / Number(subscriptionData.data.total_transactions_to_import) * 100)} success={{ percent: Number(subscriptionData.data.total_transactions_processed) / Number(subscriptionData.data.total_transactions_to_import) * 100 }} />
                </Flex>
            <Divider></Divider>
            </Tooltip>
        </>
        : ""
        }        
        <div>
        <Title level={1}>Graph</Title>
       
        <Title level={5}>Filter down on some attributes:</Title>
            <Divider />
            <Space>
            <Title level={5}>Select the Chain(s):</Title>
                <Select
                    defaultValue={filterOnChain}
                    style={{ width: 120, marginLeft:10, marginRight:10 }}
                    onChange={filterByChain}
                    options={graphData?.data?.all_chains?.concat({ "value": "all", "label" : "All"}) || enabled_chains}
                />
            <Title level={5}>Select the group(s):</Title>
                <Select
                    // defaultValue={region}
                    style={{ width: 220, marginLeft:10, marginRight:10 }}
                    
                    // onChange={setRegion}
                    options={graphData?.data?.all_groups?.map(group => ({
                        "disabled": true,
                        "label": <Tooltip title={group}> <span>{group}</span></Tooltip> ,
                        "value": group,
                    })).sort((a, b) => a.value.localeCompare(b.value)) || []}
                    />
            <Title level={5}>Select the tag(s):</Title>
                <Select
                    // defaultValue={region}
                    style={{ width: 220, marginLeft:10, marginRight:10 }}
                    // onChange={setRegion}
                    options={graphData?.data?.all_tags?.map(tag => ({
                        "disabled": true,
                        "label": <Tooltip title={tag}> <span>{tag}</span></Tooltip> ,
                        "value": tag,
                      })).sort((a, b) => a.value.localeCompare(b.value)) || []}
                />       
                <Title level={5}>Select the label(s):</Title>
                <Select
                    // defaultValue={region}
                    style={{ width: 220, marginLeft:10, marginRight:10 }}
                    // onChange={setRegion}
                    options={graphData?.data?.all_labels?.map(label => ({
                        "disabled": true,
                        "label": <Tooltip title={label}> <span>{label}</span></Tooltip> ,
                        "value": label,
                    })).sort((a, b) => a.value.localeCompare(b.value)) || []}
                    />   
            </Space>
            <Divider />
            <Space.Compact size="large">
                <Checkbox checked={filterCurrentDisplayedData} onChange={(e) => setFilterCurrentDisplayedData(e.target.checked)}>
                    Filter current displayed data?
                </Checkbox>
                <Checkbox checked={showInternalTransactions} onChange={(e) => setShowInternalTransactions(e.target.checked)}>
                    Show Internal transactions and links?
                </Checkbox>       
                <Checkbox disabled={true} checked={groupInternalTransactions} onChange={(e) => setGroupInternalTransactions(e.target.checked)}>
                    Group Internal transactions?
                </Checkbox>      
            </Space.Compact>
            <Divider />
            <Space.Compact>
                <Button type="primary" onClick={(e) => createSharedGraphModalShow()}>Create a shareable link of below graph</Button>   
            </Space.Compact>
            <Space.Compact>
                {
                    sharableGraphUrl ? 
                    <Alert style={{marginLeft:10}}
                        message={<span>Shareable Graph url can be found here: <a href={sharableGraphUrl} target="_blank"> link </a> </span> }
                        type="success" 
                    />
                    : ""
                }
            </Space.Compact>            
            <>
                <Modal {...createSharedGraphModalProps} width="1000" centered title={"Share the Graph "} onOk={close} destroyOnClose>
                <Form {...createSharedGraphFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishShareGraph}>
                        <Form.Item
                            label="Name"
                            name="name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Tooltip title="Do you want the graph to be public or private?">  
                          <Form.Item
                              label="Graph will be public or private?"
                              name="share_type"
                              initialValue={"public"}
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                          >
                            <Select
                            defaultValue={"public"}
                            style={{ width: 500 }}
                            options={[
                                { value: "private", label: 'private'},
                                { value: "public", label: 'public' },
                            ]}
                            />
                          </Form.Item>
                        </Tooltip>
                          <Tooltip title="Live transactions will be continuously updated or static transactions will contain a snapshot">  
                          <Form.Item
                              label="Graph will be contain live transactions or static transactions?"
                              name="update_type"
                              initialValue={"static"}
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                          >
                            <Select
                            defaultValue={"static"}
                            style={{ width: 500 }}
                            options={[
                                { value: "static", label: 'Static'},
                                { value: "live", label: 'Live' },
                            ]}
                            />
                          </Form.Item>
                        </Tooltip>    
                        <Tooltip title="The newly streamed transactions are also added and visible to be replayed in the shared graph">  
                          <Form.Item
                              label="Include newly streamed transactions?"
                              name="include_streamed_transactions"
                              initialValue={true}
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                          >
                            <Select
                            defaultValue={true}
                            style={{ width: 500 }}
                            options={[
                                { value: true, label: 'Yes'},
                                { value: false, label: 'No' },
                            ]}
                            />
                          </Form.Item>
                        </Tooltip>                                          
                    </Form>
                </Modal>
            </>
                { 
                    graphDataIsLoading || isFetching || isRefetching ? 
                     <Spin spinning={graphDataIsLoading || isFetching || isRefetching}> </Spin> 
                     
                     : 
                     
                     <ForceGraph2D
                        
                        ref={fgRef}
                        graphData={newGraphData}            
                        // graphData={gData}
                        nodeLabel={(node:any) => `<div><b>Name</b>: <span>${node.friendly_name}</span><br></br><b>Id</b>: <span>${node.id}</span><br></br><b>To/From address Labels</b>: <span>${node.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                        linkLabel={(link:any) => `<div><b>Name</b>: <span>${link.friendly_name}</span><br></br><b>Id</b>: <span>${link.id}</span><br></br><b>Tags</b>: <span>${link.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                        nodeAutoColorBy="group"
                        //   onNodeHover={(node:any, prevNode:any) => {
                        //     console.log(node)
                        //     if(node){
                        //         node.val = 20
                        //         node.friendly_name = node.friendly_names
                        //     }
                        //     return node
                        //   }}
                        backgroundColor="white"
                        onLinkHover={(link:any) => {
                            return <Tooltip title="x">x</Tooltip>
                        }}
            
                        linkDirectionalArrowColor={(link:any) => {
                            if (link.linkArrowColor != undefined){
                                return link.linkArrowColor
                            } else{
                                return "black"
                            }
                        }}
                        linkDirectionalArrowLength={(link:any) => {
                            if (link.arraySize != undefined){
                                return Number(link.arraySize)
                            } else{
                                return 6
                            }                            
                        }}
                        linkDirectionalArrowRelPos={(link:any) => {
                            return 0.3
                        }}
                        linkLineDash={(link:any) => {
                            return [1]
                        }}
                        linkCurvature={(link:any) => {
                            if (link.curved != undefined){
                                return link.curved
                            } else{
                                return 0
                            }
                        }}
                        linkColor={(link:any) => {
                            if (link.linkColor != undefined){
                                return link.linkColor
                            } else{
                                return link.linkColor
                            }
                        }}
                        onLinkClick={(link:any) => {
                            return window.open(
                                link.link_url,
                                '_blank' // <- This is what makes it open in a new window.
                            );
                        }}
                        linkDirectionalParticles={4}
                        linkDirectionalParticleWidth={link => newGraphDataLinksMap.has(link["id"]) || link.is_streamed_transaction == true ? 4 : 0}
                        //   linkLabel={(link:any) => {
                        //     console.log(link)
                        //     return "djknsdnjkfdkjn"
                        //   }}
                        nodeCanvasObjectMode={() => "replace"}
                        nodeCanvasObject={(node:any, ctx:any, globalScale:any) => {
                            // add ring just for highlighted nodes that are streamed
                            // if(!existingGraphDataNodesMap.has(node["id"])){         
                            if(node["id"] == "0x1_mempool") {                                  
                                ctx.beginPath();
                                const NODE_R = 15
                                const hoverNode = 1
                                // ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);

                                // ctx.fillRect(node.x - 20, node.y - 4, NODE_R * 1.4, 8, false);  // rectangle
                                // ctx.font = '10px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.fillText('Mempool', node.x, node.y); 
                                // ctx.fillStyle = node === hoverNode ? 'red' : 'orange';
                                // ctx.fill();
                                var fontsize = 14;
                                var fontface = 'verdana';
                                var lineHeight = fontsize * 1.286;

                                var text = 'Mempool';
                                if(node["id"].startsWith("0x1_")){
                                    text = "Ethereum - " + text
                                } else if( node["id"].startsWith("bitcoin_")){
                                    text = "Bitcoin - " + text
                                }

                                ctx.font = fontsize + 'px ' + fontface;
                                var textWidth = ctx.measureText(text).width;
                                
                                ctx.textAlign = 'left';
                                ctx.textBaseline = 'top';
                                
                                ctx.fillText(text, node.x, node.y);
                                ctx.strokeRect(node.x - 5, node.y - 5, textWidth + 10, lineHeight + 5);                                
                            }         

                            // const label = node.name.substring(0, [6]);
                            const label = node.friendly_name.name
                            const fontSize = 12 / globalScale;
                            ctx.font = `${fontSize}px Sans-Serif`;
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillStyle = "black"; //node.color;
                            // if (node.isClusterNode) {
                            //   ctx.fillText(label, node.x!, node.y!);
                            // } else {
                            //   ctx.fillText(label, node.x! + 20, node.y!);
                            // }
                            const size = 12      
                            
                            if (images && node.source && node["id"] != "0x1_mempool"){
                                ctx.drawImage(images.find(img => img.src === node.source), node.x! - size / 2, node.y! - size / 2, size, size);
                            }
            
                        }}
                        
                        linkCanvasObjectMode={() => "after"}
                        linkCanvasObject={(link:any, ctx:any, globalScale:any) => {
                            const MAX_FONT_SIZE = 4;
                            const LABEL_NODE_MARGIN = 12;
                            const start = link.source;
                            const end = link.target;
                            // ignore unbound links
                            if (typeof start !== 'object' || typeof end !== 'object') return;
                            // calculate label positioning
                            const textPos = Object.assign({},...['x', 'y'].map(c => ({
                            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                            })));
                            const relLink = { x: end.x - start.x, y: end.y - start.y };
                            const maxTextLength = Math.sqrt(Math.pow(relLink.x, 2) + Math.pow(relLink.y, 2)) - LABEL_NODE_MARGIN * 2;
                            let textAngle = Math.atan2(relLink.y, relLink.x);
                            // maintain label vertical orientation for legibility
                            if (textAngle > Math.PI / 2) textAngle = -(Math.PI - textAngle);
                            if (textAngle < -Math.PI / 2) textAngle = -(-Math.PI - textAngle);
                            // const label = link.linkType;
                            const label = link.linkLabel;
            
                            // estimate fontSize to fit in link length
                            ctx.font = '1px Sans-Serif';
                            const fontSize = Math.min(MAX_FONT_SIZE, maxTextLength / ctx.measureText(label).width);
                            ctx.font = `${fontSize}px Sans-Serif`;
                            const textWidth = ctx.measureText(label).width;
                            const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding
                            // draw text label (with background rect)
                            ctx.save();
                            ctx.translate(textPos.x, textPos.y);
                            ctx.rotate(textAngle);
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
            
                            ctx.fillRect(- bckgDimensions[0] / 2, - bckgDimensions[1] / 2, ...bckgDimensions);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillStyle = 'darkgrey';
                            // ctx.setLineDash([]);
                            
                            ctx.fillText(label, 0, 0);
                            ctx.restore();
                        }}
            
                        
                        />
                    }


             </div>
             <iframe
                src={`https://pdx.${continent}.private.${domain}/private/analytics/metabase/public/dashboard/7e63f6bc-c189-4298-b2f6-4108c2a5b4e7?org_id_tenant_id=${org_id}-${tenant_id}&user_id=${user_id}#hide_parameters=org_id_tenant_id,user_id`}
                frameBorder={0}
                width="100%"
                height={1500}
                allowTransparency
            />
    </Show>
        
    );
    
};
