import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import { DeleteButton, SaveButton, Edit, useForm, DateField } from "@refinedev/antd";
import dayjs from "dayjs";

import { Form, Input, Select, SelectProps, Spin, Tag, Tooltip } from "antd";
const { TextArea } = Input;

import { IAddress, IAddressLabel, ITag, IUser } from "interfaces";

import axios from 'axios';

import debounce from 'lodash/debounce';
import { useAuth0 } from "@auth0/auth0-react";
import { values } from "lodash";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const UserEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps, queryResult, onFinish } = useForm<IUser>();

    const postData = queryResult?.data?.data;
    const { user, getAccessTokenSilently, getIdTokenClaims,  } = useAuth0();
    const { open } = useNotification();

    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface RoleValue {
        label: string;
        value: string;
    }

    async function fetchRolesList(role: string) {
        console.log('fetching roles', role);
      
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/blue/GET/" + continent.toUpperCase() + "/v0/roles"
        }  
        else{
            url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/roles"
        }
        console.log(url)

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result.map(
              (role: { role_name: string; id: string }) => ({
                label: `${role.role_name}`,
                value: role.id,
              }),
            ),
          );
      }
    
    const [value, setValue] = useState<RoleValue[]>([]);

    let [availableRoles, setAvailableRoles] = React.useState(JSON.parse(process.env.REACT_APP_DEFAULT_APP_ROLES!)["roles"])

    let [isLoadingSubmitRoleChange, setIsLoadingSubmitRoleChange] = React.useState(false)

    async function handleSubmitdRoleChange(value: any) {
        console.log(value.roles);
        setIsLoadingSubmitRoleChange(true)
        const existing_roles = postData?.roles || []
        const roles_to_remove: string | string[] = []
        const roles_to_add: string | any[] = []

        // if (value?.roles.length == 0){
        //     console.log("Remove all existing roles")
            
        //     existing_roles.forEach((role: any) => {
        //         roles_to_remove.push(role)
        //     })
        // }

        let role_ids_temp: string[] = []
        value?.roles.forEach((role: any) => {
            if(role.value != undefined){
                role_ids_temp.push(role.value)  
            }
            else{
                role_ids_temp.push(role)  
            }
            
          // convert role id to role name
          var role_name = ""
          availableRoles.forEach((available_role: { value: any; label: string; }) => {
            console.log(available_role)
            if(available_role.value == role.value){
              role_name = available_role.value
            }
          })
    
          if (existing_roles.indexOf(role_name) > -1){
            console.log("Role is already there: " + role_name)
          }
          else if (role.value != undefined){
            console.log("Role needs to be added: " + role_name + " and " + role.value)
            if (role.value != undefined){
                roles_to_add.push(role.value)
            }
            else{
                roles_to_add.push(role_name)
            }
            
          }
          else{
            console.log("unknown role: " + role)
          }
        })
    
        existing_roles.forEach((role_name: string) => {
          // convert role name to role id
          // If roles are not loaded then custom role id is not available. So this needs to be computed on the backend
          var role_id = ""
          availableRoles.forEach((available_role: { label: string; value: string; }) => {
            if(available_role.label == role_name){
              role_id = available_role.value
            }
          })
          console.log(role_ids_temp)
          if (role_ids_temp.indexOf(role_id) > -1){
            console.log("Role id is still there: " + role_name)
          }
          else if (role_ids_temp.indexOf(role_name) > -1){
            console.log("Role name is still there: " + role_name)
          }
          else if(role_id == ""){
            console.log("Role name needs to be removed: " + role_name)
            roles_to_remove.push(role_name)
          }
          else{
            console.log("Role id needs to be removed: " + role_name)
            roles_to_remove.push(role_id)
          }
        })
    
        console.log("roles to add: " + roles_to_add)
        console.log("roles to remove: " + roles_to_remove)
    
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/blue/ANY/" + continent.toUpperCase() + "/v0/users/" + user?.sub + "/roles"
        }  
        else{
            url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/ANY/" + continent.toUpperCase() + "/v0/users/" + user?.sub + "/roles"
        }
        console.log(url)

        let error = []
        if(roles_to_add.length > 0){
           const { data, status } = await axios.post(
                url,
                {
                    "roles": roles_to_add
                }
            )
            console.log(data)
            console.log(status)
        
          console.log("Roles added updated: " + JSON.stringify(data))
          if(data?.error){
            error.push(data?.error)
          }
        }

        
        if(roles_to_remove.length > 0){
            const { data: data_deleted, status: status_deleted } = await axios.delete(
            url,
            { data:
              {
                "roles": roles_to_remove
              }
            }
          )
    
          console.log("Roles removed updated: " + JSON.stringify(data_deleted))
          if(data_deleted?.error){
            error.push(data_deleted?.error)
          }
        }
        setIsLoadingSubmitRoleChange(false)
        await getAccessTokenSilently({ cacheMode: 'off'});
        open?.({
            type: "success",
            message: "done",
            description: "done",
            key: "users",
        });
    }


    return (
        <Edit saveButtonProps={{ ...saveButtonProps }} canDelete={postData?.status?.includes("draft")} footerButtons={({ saveButtonProps, deleteButtonProps }) => (
            <>
                <SaveButton {...saveButtonProps} loading={isLoadingSubmitRoleChange}/>
                {deleteButtonProps && (
                    <DeleteButton {...deleteButtonProps} />
                )}
            </>
        )}
        >
            <Form {...formProps} layout="vertical" onFinish={handleSubmitdRoleChange}>
                <Form.Item
                    label="Id"
                    name="id"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item 
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                </Form.Item>  
                <Form.Item 
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input 
                            disabled={true}
                        ></Input>
                </Form.Item>
                <Form.Item
                    label="Created At"
                    name="created_at"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                  <DateField value={dayjs.unix(postData?.created_at || 1)} format="LLL"></DateField>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    initialValue = "Active"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Roles"
                    name="roles"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                <DebounceSelect
                    mode="multiple"
                    value={value}
                    placeholder="Select roles"
                    fetchOptions={fetchRolesList}
                    onChange={(newValue) => {
                        setValue(newValue as RoleValue[]);
                    }}
                    style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Edit>

    );
};



