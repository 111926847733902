import React, { useMemo, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show, DeleteButton, SaveButton, ShowButton, useModalForm } from "@refinedev/antd";

import { Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, List, Modal, Row, Select, SelectProps, Skeleton, Space, Spin, Statistic, Switch, Tag, Tooltip, Typography } from "antd";

import { IUser } from "interfaces";
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import InfiniteScroll from 'react-infinite-scroll-component';

const { Title, Text } = Typography;

import { useModal } from "@refinedev/antd";
import debounce from 'lodash/debounce';

interface DataType {
    gender: string;
    name: {
      title: string;
      first: string;
      last: string;
    };
    email: string;
    picture: {
      large: string;
      medium: string;
      thumbnail: string;
    };
    nat: string;
  }

export interface DebounceSelectPropsTransactionShow<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()

    const { queryResult } = useShow<IUser>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const [recoveredAddress, setRecoveredAddress] = useState('');

    
    const [disabled, setDisabled] = useState(false);

    const { show, close, modalProps } = useModal();
    
    function DebounceSelect<
      ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
      >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsTransactionShow<ValueType>) {
      const [fetching, setFetching] = useState(false);
      const [options, setOptions] = useState<ValueType[]>([]);
      const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface UserValue {
        label: string;
        value: string;
    }

      async function fetchUserList(username: string) {
        console.log('fetching user', username);
     
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`
        }  
        else{
            url = `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`
        }
        console.log(url)

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result.map(
              (user: { name: string; email: string }) => ({
                label: `${user.name} - ${user.email}`,
                value: user.email,
              }),
            ),
          );
      }
    
    
    return (
        <Show isLoading={isLoading} >
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Email</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Status</Title>
            <Text>
                <Tag color={record?.status == "inactive" ? "red" : "green"}>{record?.status == "inactive" ? "Inactive" : "Active"}</Tag>   
            </Text>

            
            <Title level={5}>Roles</Title>
            <Text>
                {         
                        record?.roles?.map((role: any)=>{
                        return <Tag>{role}</Tag>
                    })
                }   
            </Text>
            
          
        </Show>
    );
};
