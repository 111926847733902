import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show } from "@refinedev/antd";

import { Button, Typography } from "antd";

import { IAction } from "interfaces";

const { Title, Text } = Typography;
import axios from 'axios';

import { recoverMessageAddress } from 'viem'
import type { Hex } from 'viem'

export const ActionsShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()

    const { queryResult } = useShow<IAction>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Hash</Title>
 
        </Show>
    );
};
