import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Alert, Button, Checkbox, Divider, Form, Input, Select, Space, Tooltip } from "antd";
// import AceEditor from 'react-ace';

import { IUser } from "interfaces";
import Title from "antd/es/typography/Title";
import { useAuth0 } from "@auth0/auth0-react";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const { user, getAccessTokenSilently, getIdTokenClaims,  } = useAuth0();
    let [availableRoles, setAvailableRoles] = React.useState(JSON.parse(process.env.REACT_APP_DEFAULT_APP_ROLES!)["roles"])

    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IUser>(
        {
            resource: "users",
            action: "create",
            redirect: false//"show",
        }
    );

    const { Option } = Select;

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="users">
            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Org"
                    name="org_id"  
                    initialValue={user?.[process.env.REACT_APP_BASE_URL + '/org_id']}                 
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Tenant"
                    name="tenant_id" // For now always in the root tenant  
                    initialValue={user?.[process.env.REACT_APP_BASE_URL + '/tenant_id']}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a tenant">
                        <Option value={user?.[process.env.REACT_APP_BASE_URL + '/tenant_id']}>{user?.[process.env.REACT_APP_BASE_URL + '/tenant_id']}</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"  
                    initialValue="pending_invitation"
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        // disabled={true}
                        placeholder="Please select a Status">
                        <Option value="pending_invitation">Pending Invitation</Option>    
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>

                    </Select>
                </Form.Item> 
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="test name"/>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="test@gmail.com"/>
                </Form.Item>
              
               
                <Form.Item
                    label="Role(s)"
                    name="roles"  
                    initialValue={[availableRoles[0].value]}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                  
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                    <Select
                        labelInValue={true}
                        mode="multiple"
                        placeholder="Please select a role"
                        style={{ flex: 1 }}
                        options={availableRoles}
                    />
                </Form.Item>
                
            
            </Form>

        </Create>
    );
};
