import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne, useNotification } from "@refinedev/core";

import { TagField, Show, DeleteButton, SaveButton, ShowButton, useModalForm, EditButton, DateField } from "@refinedev/antd";

import { Alert, Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, List, Modal, Radio, Row, Select, SelectProps, Skeleton, Space, Spin, Statistic, Switch, Tag, Tooltip, Typography } from "antd";

import { ITransaction } from "interfaces";
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ForceGraph2D from "react-force-graph-2d";

const { Title, Text } = Typography;

import { useModal } from "@refinedev/antd";
import debounce from 'lodash/debounce';
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface DataType {
    gender: string;
    name: {
      title: string;
      first: string;
      last: string;
    };
    email: string;
    picture: {
      large: string;
      medium: string;
      thumbnail: string;
    };
    nat: string;
  }

export interface DebounceSelectPropsTransactionShow<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

const CHAIN_CONFIG = {
    // Mainnets
    "0x1": { token: "ETH", gasUnit: "Gwei", name: "Ethereum" },
    "0x89": { token: "MATIC", gasUnit: "Gwei", name: "Polygon" },
    "0x38": { token: "BNB", gasUnit: "Gwei", name: "BNB Chain" },
    "0xa4b1": { token: "ARB", gasUnit: "Gwei", name: "Arbitrum" },
    "0x2105": { token: "ETH", gasUnit: "Gwei", name: "Base" },
    "0xa": { token: "ETH", gasUnit: "Gwei", name: "Optimism" },
    "0xe708": { token: "ETH", gasUnit: "Gwei", name: "Linea" },
    "0xa86a": { token: "AVAX", gasUnit: "nAVAX", name: "Avalanche" },
    "0xfa": { token: "FTM", gasUnit: "Gwei", name: "Fantom" },
    "0x19": { token: "CRO", gasUnit: "Gwei", name: "Cronos" },
    "0x64": { token: "xDAI", gasUnit: "Gwei", name: "Gnosis" },
    "0x15b38": { token: "CHZ", gasUnit: "Gwei", name: "Chiliz" },
    "0x171": { token: "PLS", gasUnit: "Gwei", name: "PulseChain" },
    "0x504": { token: "GLMR", gasUnit: "Gwei", name: "Moonbeam" },
    "0x505": { token: "MOVR", gasUnit: "Gwei", name: "Moonriver" },
    "0x13e31": { token: "ETH", gasUnit: "Gwei", name: "Blast" },
    "0x144": { token: "ETH", gasUnit: "Gwei", name: "zkSync Era" },
    "0x1388": { token: "MNT", gasUnit: "Gwei", name: "Mantle" },
    "0xcc": { token: "BNB", gasUnit: "Gwei", name: "opBNB" },
    "0x44d": { token: "MATIC", gasUnit: "Gwei", name: "Polygon zkEVM" },
    "0x1b58": { token: "ZETA", gasUnit: "Gwei", name: "ZetaChain" },
    "0x2eb": { token: "FLOW", gasUnit: "FLOW units", name: "Flow" },
    "0x7e4": { token: "RON", gasUnit: "Gwei", name: "Ronin" },
    "0x46f": { token: "LSK", gasUnit: "LSK units", name: "Lisk" },
    
    // Testnets
    "0xaa36a7": { token: "ETH", gasUnit: "Gwei", name: "Sepolia" },
    "0x4268": { token: "ETH", gasUnit: "Gwei", name: "Holesky" },
    "0x13882": { token: "MATIC", gasUnit: "Gwei", name: "Polygon Amoy" },
    "0x61": { token: "tBNB", gasUnit: "Gwei", name: "BSC Testnet" },
    "0x66eee": { token: "ARB", gasUnit: "Gwei", name: "Arbitrum Sepolia" },
    "0x14a34": { token: "ETH", gasUnit: "Gwei", name: "Base Sepolia" },
    "0xaa37dc": { token: "ETH", gasUnit: "Gwei", name: "Optimism Sepolia" },
    "0xe705": { token: "ETH", gasUnit: "Gwei", name: "Linea Sepolia" },
    "0xfa2": { token: "FTM", gasUnit: "Gwei", name: "Fantom Testnet" },
    "0x27d8": { token: "xDAI", gasUnit: "Gwei", name: "Gnosis Chiado" },
    "0x15b32": { token: "CHZ", gasUnit: "Gwei", name: "Chiliz Testnet" },
    "0x507": { token: "DEV", gasUnit: "Gwei", name: "Moonbase" },
    "0xa0c71fd": { token: "ETH", gasUnit: "Gwei", name: "Blast Sepolia" },
    "0x12c": { token: "ETH", gasUnit: "Gwei", name: "zkSync Sepolia" },
    "0x138b": { token: "MNT", gasUnit: "Gwei", name: "Mantle Sepolia" },
    "0x98a": { token: "MATIC", gasUnit: "Gwei", name: "Polygon zkEVM Cardona" },
    "0x1b59": { token: "ZETA", gasUnit: "Gwei", name: "Zetachain Testnet" },
    "0x221": { token: "FLOW", gasUnit: "FLOW units", name: "Flow Testnet" },
    "0x7e5": { token: "RON", gasUnit: "Gwei", name: "Ronin Saigon Testnet" },
    "0x106a": { token: "LSK", gasUnit: "LSK units", name: "Lisk Sepolia" },

    // Non-EVM chains
    "0x0": { token: "BTC", gasUnit: "Sats/byte", name: "Bitcoin" },  // Using 0x0 as example chain_id for Bitcoin
    "0x1399c": { token: "SOL", gasUnit: "Lamports", name: "Solana" },  // Using actual Solana chain ID
    "0x162": { token: "DOT", gasUnit: "Plancks", name: "Polkadot" },   // Using actual Polkadot chain ID
};

export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()
    const { open } = useNotification();
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/sub']

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')
  
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
  
    let use_region:string
    let use_region_alias
    let use_backup_region
    let use_backup_region_alias
  
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
  
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { queryResult } = useShow<ITransaction>();
    const { data, isLoading, isFetching, isRefetching } = queryResult;
    const record = data?.data;

    const [recoveredAddress, setRecoveredAddress] = useState('');

    // const { data: categoryData, isLoading: categoryIsLoading } =
    //     useOne<ITransaction>({
    //         resource: "transactions",
    //         id: record?.hash ? record?.hash : "",
    //         queryOptions: {
    //             enabled: !!record,
    //         },
    //         meta: {"transaction_hash": record?.hash}
    //     });

    const [disabled, setDisabled] = useState(false);

    const { show, close, modalProps } = useModal();
    const { show: setShowInternalTransaction, close: closeInternalTransaction, modalProps:internalTransactionModalProps } = useModal();
    const { show: setShowTransactionDecodedInput, close: closeTransactionDecodedInput, modalProps:transactionDecodedInputModalProps } = useModal();
    const { show: setShowEvent, close: closeEvent, modalProps:eventModalProps } = useModal();

    const [showIntegrationIdDetails, setShowIntegrationIdDetails] = useState("");
    const [showInternalTransactionDetails, setShowInternalTransactionDetails] = useState("");
    const [showEventDetails, setShowEventDetails] = useState("");

    const showIntegrationDetails = async (record: any) => {
        console.log("Show integration details")
        console.log(record)
        show()
        setShowIntegrationIdDetails(record);
      };

    const showInternalTransaction = async (record: any) => {
        console.log("Show internal transaction")
        console.log(record)
        setShowInternalTransaction()
        setShowInternalTransactionDetails(record);
    };

    const showEvent = async (record: any) => {
        console.log("Show event")
        console.log(record)
        if (record?.is_search_transaction && record?.from_address_entity && record?.from_address_entity_logo) {
            record.entity = record.from_address_entity;
            record.entity_logo = record.from_address_entity_logo;
        } else if (record?.is_search_transaction && record?.to_address_entity && record?.to_address_entity_logo) {
            record.entity = record.to_address_entity;
            record.entity_logo = record.to_address_entity_logo;
        }
        setShowEvent()
        setShowEventDetails(record);
    };

    function DebounceSelect<
      ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
      >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsTransactionShow<ValueType>) {
      const [fetching, setFetching] = useState(false);
      const [options, setOptions] = useState<ValueType[]>([]);
      const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface UserValue {
        label: string;
        value: string;
    }

    const [isLoadingReimportTransaction, setIsLoadingReimportTransaction] = React.useState<boolean>(false);

    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [importForm] = Form.useForm();

    async function reimportTransaction(values?: { 
        force_update: boolean,
        simple_import: boolean,
        create_address_labels: boolean 
    }) {
        setIsLoadingReimportTransaction(true)
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": use_region,
            "Destination-Region": use_region,
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")


        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/private/blue/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
        }  
        else{
            url = `${apiUrl}/private/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
        }

        console.log(url)
        const hash = record?.hash
        const chain_id = record?.chain_id
        const force_update = values?.force_update ?? true

        const postData = {
            "hash": hash,
            "chain": chain_id,
            "force_update": force_update,
            "simple_import": values?.simple_import ?? true,
            "create_address_labels": values?.create_address_labels ?? true
        }
        
        try {
            const { data, status } = await axios.post(
                url,
                JSON.stringify(postData), {
                    headers: requestHeaders
                }
            )  
            console.log(data)
            console.log(status)
            setIsLoadingReimportTransaction(false)

            open?.({
                type: "success",
                message: "Successfully requested the reprocess of the transaction",
                description: "Successfully requested the reprocess of the transaction",
                key: "hash",
            })
            navigate(`/transactions/show/${data?.result?.message_id}`)
        } catch (error: any) {
            console.log(error); 
            setIsLoadingReimportTransaction(false)

            open?.({
                type: "error",
                message: "Error",
                description: error.response?.data?.error?.message,
                key: "transactions-import",
            });
        } 

        console.log(data)

        return {
            data,
        };
    }


    const shareTransaction = async () => {
        console.log("Share transaction")
        showShareWithModal()
      };
      
    async function fetchUserList(username: string) {
        console.log('fetching user', username);
     
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`
        }  
        else{
            url = `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`
        }
        console.log(url)

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result?.map(
              (user: { name: string; email: string }) => ({
                label: `${user.name} - ${user.email}`,
                value: user.email,
              }),
            ),
          );
      }
    
    const [value, setValue] = useState<UserValue[]>([]);
    const {
        modalProps: modalPropsShareWithModal,
        formProps: formPropsShareWithModal,
        show: showShareWithModal,
        close: closeShareWithModal,
        onFinish
    } = useModalForm({
        action: "create",
        resource: "transactions-share-with",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeShareWithModal()
        },
    });

    const [loading, setLoading] = useState(false);
    const loadMoreData = () => {

    }

    const onFinishShareWith = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello action")
       
        let shared_with = values.shared_with
        let shared_with_to_add_array = []
        for(let i=0; i<shared_with.length; i++){
            console.log(shared_with[i].value);
            shared_with_to_add_array.push(shared_with[i].value)
        }

        let new_obj = {}
       
        new_obj = {
            id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
            shared_with_to_add: shared_with_to_add_array,
            shared_with_to_remove: []
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };

    const [images, setImages] = useState<Array<HTMLImageElement>>();

    function componentDidMount() {
        const nodeImages = record?.nodes?.map(node => {
            const imageSource = record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.from_address?.toLowerCase()) && record?.from_address_entity_logo
                ? record?.from_address_entity_logo
                : record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.to_address?.toLowerCase()) && record?.to_address_entity_logo
                    ? record?.to_address_entity_logo
                        : node.source;
            return imageSource;
        });
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))

        const images = nodeImagesUnique?.map(image => {
            const img = new Image();
            img.src = image;
            return img;
        });
        setImages(images)
        console.log(images)
    }

    const fgRef = useRef<any>();

    const graphDataNew = useMemo(() => {
        const nodeImages = record?.nodes?.map(node => {
            const imageSource = record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.from_address?.toLowerCase()) && record?.from_address_entity_logo
                ? record?.from_address_entity_logo
                : record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.to_address?.toLowerCase()) && record?.to_address_entity_logo
                    ? record?.to_address_entity_logo
                        : node.source;
            return imageSource;
        });
        
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))
    
        const images = nodeImagesUnique?.map(image => {
          const img = new Image();
          img.src = image;
          return img;
        });
        setImages(images)

        return {
          nodes: record?.nodes ? record?.nodes : [],
          links: record?.links ? record?.links : []
        };
    }, [record]);

    useEffect(() => {
        if(!images){
            componentDidMount()
        }

        const current = fgRef?.current
        current?.zoom(5)
    }, [images, record]);
    
    const {
        modalProps: editTagsModalProps,
        formProps: editTagsFormProps,
        show: showEditTags,
        close: closeEditTags,
        onFinish: handleEditTagsFinish,
        setId: setEditTagsId,
    } = useModalForm({
        id: `${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`,
        action: "edit", 
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_tags"
        },
    });

    const {
        modalProps: editGroupsModalProps,
        formProps: editGroupsFormProps,
        show: showEditGroups,
        close: closeEditGroups,
        onFinish: handleEditGroupsFinish,
        setId: setEditGroupsId
    } = useModalForm({
        id: `${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_groups"
        },
    });

    const [isImportingTransaction, setIsImportingTransaction] = useState(false);

    const importTransaction = async () => {
        setIsImportingTransaction(true);
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": use_region,
                "Destination-Region": use_region,
            };

            const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain;
            const url = qa_environment === "blue" 
                ? `${apiUrl}/private/blue/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
                : `${apiUrl}/private/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`;

            const postData = {
                "hash": record?.hash,
                "chain": record?.chain_id,
                "force_update": true,
                "simple_import": true
            };
            
            const response = await axios.post(url, JSON.stringify(postData), { headers: requestHeaders });
            console.log(response.data.error)
            if (response.data.error?.message && response.data.error?.reason) {
                // Handle API error response
                throw new Error(
                    `${response.data.error.reason}`
                );
            }
            
            if (response.status === 200 && response.data.ok === true) {
                console.log(response.data)
                return response.data;
            } else {
                throw new Error("Failed to import transaction");
            }
                
        } catch (error: any) {
            const errorMessage = error.response?.data?.reason || error.message;
            open?.({
                type: "error",
                message: "Error importing transaction",
                description: errorMessage,
            });
            throw error;
        } finally {
            setIsImportingTransaction(false);
        }
    };

    // Add this state near your other states
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Update the form submission handler
    const handleEditTagsFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        const formData = {
            tags: values.tags || [],
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
        };

        try {
            if (record?.is_search_transaction) {
                const importResponse = await importTransaction();
                
                if (importResponse?.result?.message_id) {
                    setEditTagsId(importResponse.result.message_id);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    
                    await handleEditTagsFinish(formData);
                    queryResult.refetch();
                    closeEditTags();
                    
                    open?.({
                        type: "success",
                        message: "Transaction imported and tags updated successfully",
                    });
                }
            } else {
                await handleEditTagsFinish(formData);
                closeEditTags();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
        }
    };

    const handleGroupFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: values.group_names || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
        };

        try {
            if (record?.is_search_transaction) {
                try {
                    const importResponse = await importTransaction();
                
                    if (importResponse?.result?.message_id) {
                        setEditGroupsId(importResponse.result.message_id);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        await handleEditGroupsFinish(formData);
                        queryResult.refetch();
                        closeEditGroups();
                        
                        open?.({
                            type: "success",
                            message: "Transaction imported and groups updated successfully",
                        });
                    }
                } catch (error) {
                    return;
                }
            } else {
                await handleEditGroupsFinish(formData);
                closeEditGroups();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditGroups();
        }            
    };

    const handleFromAddressLabelFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: values.from_address_label || [],
            to_address_label: record?.to_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
        };

        try {
            if (record?.is_search_transaction) {
                const importResponse = await importTransaction();
                
                if (importResponse?.result?.message_id) {
                    setEditFromAddressLabelId(importResponse.result.message_id);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    
                    await handleEditFromAddressLabelFinish(formData);
                    queryResult.refetch();
                    closeEditFromAddressLabel();
                    
                    open?.({
                        type: "success",
                        message: "Transaction imported and from address labels updated successfully",
                    });
                }
            } else {
                await handleEditFromAddressLabelFinish(formData);
                closeEditFromAddressLabel();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditFromAddressLabel(); // Ensure modal closes even if there's an error
        }
    };

    const handleToAddressLabelFormSubmit = async (values: any) => {
        setIsSubmitting(true); // Start loading

        // Add default values to the form data with filtered labels
        const formData = {
            group_names: record?.group_names?.map((group: any) => group.name) || [],
            tags: record?.tags?.map((tag: any) => tag.name) || [],
            from_address_label: record?.from_address_label
                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                ?.map((label: any) => label.name) || [],
            to_address_label: values.to_address_label || [],
        };

        try {
            if (record?.is_search_transaction) {
                try {
                    const importResponse = await importTransaction();
                    
                    if (importResponse?.result?.message_id) {
                        setEditToAddressLabelId(importResponse.result.message_id);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        await handleEditToAddressLabelFinish(formData);
                        queryResult.refetch();
                        closeEditToAddressLabel();
                        
                        open?.({
                            type: "success",
                            message: "Transaction imported and to address labels updated successfully",
                        });
                    }
                } catch (error) {
                    return;
                }
            } else {
                await handleEditToAddressLabelFinish(formData);
                closeEditToAddressLabel();
            }
        } catch (error) {
            // Handle error
        } finally {
            setIsSubmitting(false); // Stop loading regardless of outcome
            closeEditToAddressLabel(); // Ensure modal closes even if there's an error
        }              
    };

    const {
        modalProps: editFromAddressLabelModalProps,
        formProps: editFromAddressLabelFormProps,
        show: showFromAddressLabel,
        close: closeEditFromAddressLabel,
        onFinish: handleEditFromAddressLabelFinish,
        setId: setEditFromAddressLabelId
    } = useModalForm({
        id: `${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_from_address_label"
        },
    });

    const {
        modalProps: editToAddressLabelModalProps,
        formProps: editToAddressLabelFormProps,
        show: showToAddressLabel,
        close: closeEditToAddressLabel,
        onFinish: handleEditToAddressLabelFinish,
        setId: setEditToAddressLabelId
    } = useModalForm({
        id: `${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`,
        action: "edit",
        resource: "transactions",
        redirect: "show",
        warnWhenUnsavedChanges: false,
        meta: {
            operation: "edit_to_address_label"
        },
    });

    const extractAddresses = (param: any): string[] => {
        if (!param) return [];
        
        const addresses: string[] = [];

        // Helper function to check if a string is a valid address
        const isAddress = (value: string): boolean => {
            return typeof value === 'string' && value.startsWith('0x') && value.length === 42;
        };

        // Helper function to process a value and extract addresses
        const processValue = (value: any) => {
            if (typeof value === 'string') {
                // Handle comma-separated values
                if (value.includes(',')) {
                    value.split(',').forEach(part => {
                        if (isAddress(part)) {
                            addresses.push(part.toString().toLowerCase());
                        }
                    });
                } else if (isAddress(value)) {
                    addresses.push(value.toString().toLowerCase());
                }
            }
        };

        // Recursive function to traverse the object
        const traverse = (obj: any) => {
            if (!obj) return;

            if (Array.isArray(obj)) {
                obj.forEach(item => traverse(item));
                return;
            }

            if (typeof obj === 'object') {
                // Check if it's a parameter with type and value
                if (obj.type && obj.value) {
                    if (obj.type === 'address' || obj.type === 'address[]') {
                        processValue(obj.value);
                    }
                }

                // Check value field separately for any addresses
                if (obj.value) {
                    processValue(obj.value);
                }

                // Recursively check all nested objects
                Object.values(obj).forEach(value => {
                    if (typeof value === 'object') {
                        traverse(value);
                    }
                });
            }
        };

        traverse(param);
        return Array.from(new Set(addresses)); // Return unique addresses only
    };
    
    
    return (
        <Show isLoading={isLoading} footerButtons={({deleteButtonProps }) => (
            <>
                {record?.is_search_transaction ? <></> : <ShowButton onClick={(e) => shareTransaction()}>Share transaction with other users</ShowButton>}
                <>
                <Button 
                    type="primary" 
                    loading={isLoadingReimportTransaction} 
                    onClick={() => setIsImportModalVisible(true)}
                >
                    Import
                </Button>
                <Modal
                    title="Import Transaction"
                    open={isImportModalVisible}
                    onCancel={() => {
                    setIsImportModalVisible(false);
                    importForm.resetFields();
                    }}
                    onOk={() => {
                    importForm.validateFields().then((values) => {
                        const { importType, ...otherValues } = values;
                        reimportTransaction({
                        ...otherValues,
                        simple_import: importType === 'simple'
                        });
                        setIsImportModalVisible(false);
                        importForm.resetFields();
                    });
                    }}
                >
                    <Form 
                    form={importForm} 
                    layout="vertical"
                    initialValues={{
                        importType: 'simple',
                        force_update: false,
                        create_address_labels: true,
                    }}
                    >
                    <Form.Item
                        name="importType"
                        label="Import Type"
                    >
                        <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="simple">
                            Simple Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Quick import with basic processing (recommended for simple transactions)
                            </Typography.Text>
                            </Radio>
                            <Radio value="advanced">
                            Advanced Import
                            <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                                Detailed import with full data processing and validation
                            </Typography.Text>
                            </Radio>
                        </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="force_update"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Force Update
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Overwrite existing transaction data
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="create_address_labels"
                        valuePropName="checked"
                    >
                        <Checkbox>
                        Create Address Labels
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                            Automatically generate labels for addresses
                        </Typography.Text>
                        </Checkbox>
                    </Form.Item>
                    </Form>        
                    </Modal>
                </>
            </>
        )}
        >
            <Title level={5}>Hash</Title>
            <Text>{record?.hash}</Text>

            {/* <Title level={5}>Address</Title>
            <Text>{record?.address}</Text>    */}

            <Title level={5}>From Address</Title>
            <Space align="center">
                <Space>
                    <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.from_address]?.id || record?.from_address)}>
                        {record?.from_address + " " || "loading"}
                    </Link>
                    {record?.from_address_label?.map((label: any) => (
                        label.id.startsWith("address_label_") ? (
                            <Tooltip title="This is a Global address label that is applied to all transactions with this address">
                                <Tag color={"#" + label.color}>
                                    {label.name}
                                </Tag>
                            </Tooltip>
                        ) : (
                            <Tag color={"#" + label.color}>
                                {label.name}
                            </Tag>
                        )
                    ))}
                </Space>
                {(record?.from_address_label?.length > 0 || (record?.is_search_transaction && record?.from_address_entity && record?.from_address_entity_logo)) && (
                    <Tooltip title={
                        record?.from_address_entity_logo 
                            ? (Array.isArray(record?.from_address_entity) 
                                ? record?.from_address_entity?.map((label: any) => label.name).join(', ')
                                : record?.from_address_entity)
                            : record?.address_items_map?.[record.from_address]?.main_labels?.chain_id?.name || 
                              record?.address_items_map?.[record.from_address]?.labels?.[0]?.name || 
                              'No label'
                    }>
                        <Avatar 
                            src={
                                record?.from_address_entity_logo || 
                                (record?.address_items_map?.[record.from_address] ? 
                                    record.address_items_map[record.from_address]?.main_labels?.chain_id?.source || 
                                    record.address_items_map[record.from_address]?.labels?.[0]?.source 
                                    : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`)
                            }
                        />
                    </Tooltip>
                )}                
                <Modal 
                {...editFromAddressLabelModalProps} 
                width="1000" 
                style={{ minWidth: "200px" }}
                centered 
                title="Edit From Address Labels" 
                confirmLoading={isImportingTransaction || isSubmitting}
                okButtonProps={{
                    loading: isImportingTransaction || isSubmitting,
                    disabled: isImportingTransaction || isSubmitting,
                    onClick: async () => {
                        const values = editFromAddressLabelFormProps.form?.getFieldsValue();
                        await handleFromAddressLabelFormSubmit(values);
                    }
                }}
                destroyOnClose={true}
            >
                <Form 
                    {...editFromAddressLabelFormProps}
                    initialValues={{
                        from_address_label: record?.from_address_label
                            ?.filter((label: any) => !label.id.startsWith("address_label_"))
                            ?.map((label: any) => label.name) || []
                    }}
                    onFinish={(values) => handleFromAddressLabelFormSubmit(values)}
                >
                    <Alert
                        message="Transaction-specific vs Global Address Labels"
                        description={
                            <>
                                <Text>There are two types of address labels:</Text>
                                <ul>
                                    <li>Transaction-specific labels (edited here): Apply only to this transaction</li>
                                    <li>
                                        Global address labels (managed in <Link to="/settings?tab=labels">Settings &gt; Labels</Link>): 
                                        Apply to all transactions with this address
                                    </li>
                                </ul>
                            </>
                        }
                        type="info"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                    {record?.is_search_transaction && (
                        <Alert
                            message="This transaction needs to be imported first"
                            description="When you save these from address labels, we'll first import the transaction and then apply the labels."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}
                    <Form.Item
                        label="To Address Labels"
                        name="to_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                        
                    <Form.Item
                        label="Groups"
                        name="group_names"
                        initialValue={[]}
                        hidden={true}
                    ></Form.Item>          
                    <Form.Item
                        label="Tags"
                        name="tags"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                   
                    <Form.Item
                        label="From Address Labels"
                        name="from_address_label"
                        rules={[
                            {
                                required: false,
                                message: 'Please select at least one from address label',
                            },
                        ]}
                    >
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            placeholder="Select from address labels or enter new ones"
                            style={{ width: '100%' }}
                            defaultValue={record?.from_address_label
                                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                                ?.map((label: any) => label.name) || []}
                            options={record?.from_address_label
                                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                                ?.map((label: any) => ({
                                    label: label.name,
                                    value: label.name,
                                }))}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                ((option?.label ?? '').toString()).toLowerCase().includes(input.toLowerCase())
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Text type="secondary" style={{ padding: '0 8px' }}>
                                        Type to create new labels (press Enter or comma to add)
                                    </Text>
                                </>
                            )}
                        />
                    </Form.Item>
                </Form>            
            </Modal>
            <Button 
                type="link" 
                icon={<InfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                onClick={() => showFromAddressLabel(`${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`)}
                style={{ padding: '0 8px' }}
            >
                Edit address label
            </Button>
            </Space>
            <Title level={5}>To Address</Title>
            <Space align="center">
                <Space>
                    <Link to={"/addresses/show/" + (record?.address_items_map?.[record?.to_address]?.id || record?.to_address)}>
                        {record?.to_address + " " || "loading"}
                    </Link>
                    {record?.to_address_label?.map((label: any) => (
                        label.id.startsWith("address_label_") ? (
                            <Tooltip title="This is a Global address label that is applied to all transactions with this address">
                                <Tag color={"#" + label.color}>
                                    {label.name}
                                </Tag>
                            </Tooltip>
                        ) : (
                            <Tag color={"#" + label.color}>
                                {label.name}
                            </Tag>
                        )
                    ))}
                </Space>
                {(record?.to_address_label?.length > 0 || (record?.is_search_transaction && record?.to_address_entity && record?.to_address_entity_logo)) && (
                    <Tooltip title={
                        record?.to_address_entity_logo 
                            ? (Array.isArray(record?.to_address_entity) 
                                ? record?.to_address_entity?.map((label: any) => label.name).join(', ')
                                : record?.to_address_entity)
                            : record?.address_items_map?.[record.to_address]?.main_labels?.chain_id?.name || 
                              record?.address_items_map?.[record.to_address]?.labels?.[0]?.name || 
                              'No label'
                    }>
                        <Avatar 
                            src={
                                record?.to_address_entity_logo || 
                                (record?.address_items_map?.[record.to_address] ? 
                                    record.address_items_map[record.to_address]?.main_labels?.chain_id?.source || 
                                    record.address_items_map[record.to_address]?.labels?.[0]?.source 
                                    : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`)
                            }
                        />
                    </Tooltip>
                )}
                <Button 
                    type="link" 
                    icon={<InfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                    onClick={() => showToAddressLabel(`${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`)}
                    style={{ padding: '0 8px' }}
                >
                    Edit address label
                </Button>
            </Space>

            <Modal 
                {...editToAddressLabelModalProps} 
                width="1000" 
                style={{ minWidth: "200px" }}
                centered 
                title="Edit To Address Labels" 
                confirmLoading={isImportingTransaction || isSubmitting}
                okButtonProps={{
                    loading: isImportingTransaction || isSubmitting,
                    disabled: isImportingTransaction || isSubmitting,
                    onClick: async () => {
                        const values = editToAddressLabelFormProps.form?.getFieldsValue();
                        await handleToAddressLabelFormSubmit(values);
                    }
                }}
                destroyOnClose={true}
            >
                <Form 
                    {...editToAddressLabelFormProps}
                    initialValues={{
                        to_address_label: record?.to_address_label
                            ?.filter((label: any) => !label.id.startsWith("address_label_"))
                            ?.map((label: any) => label.name) || []
                    }}
                    onFinish={(values) => handleToAddressLabelFormSubmit(values)}
                >
                    <Alert
                        message="Transaction-specific vs Global Address Labels"
                        description={
                            <>
                                <Text>There are two types of address labels:</Text>
                                <ul>
                                    <li>Transaction-specific labels (edited here): Apply only to this transaction</li>
                                    <li>
                                        Global address labels (managed in <Link to="/settings?tab=labels">Settings &gt; Labels</Link>): 
                                        Apply to all transactions with this address
                                    </li>
                                </ul>
                            </>
                        }
                        type="info"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />
                    {record?.is_search_transaction && (
                        <Alert
                            message="This transaction needs to be imported first"
                            description="When you save these to address labels, we'll first import the transaction and then apply the labels."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}
                    <Form.Item
                        label="From Address Labels"
                        name="from_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                        
                    <Form.Item
                        label="Groups"
                        name="group_names"
                        initialValue={[]}
                        hidden={true}
                    ></Form.Item>          
                    <Form.Item
                        label="Tags"
                        name="tags"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                   
                    <Form.Item
                        label="To Address Labels"
                        name="to_address_label"
                        rules={[
                            {
                                required: false,
                                message: 'Please select at least one to address label',
                            },
                        ]}
                    >
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            placeholder="Select to address labels or enter new ones"
                            style={{ width: '100%' }}
                            defaultValue={record?.to_address_label
                                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                                ?.map((label: any) => label.name) || []}
                            options={record?.to_address_label
                                ?.filter((label: any) => !label.id.startsWith("address_label_"))
                                ?.map((label: any) => ({
                                    label: label.name,
                                    value: label.name,
                                }))}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                ((option?.label ?? '').toString()).toLowerCase().includes(input.toLowerCase())
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Text type="secondary" style={{ padding: '0 8px' }}>
                                        Type to create new labels (press Enter or comma to add)
                                    </Text>
                                </>
                            )}
                        />
                    </Form.Item>
                </Form>            
            </Modal>
            <Title level={5}>Block Details</Title>
            <Text>
                <DateField value={record?.block_timestamp} format="LLL"/> {" | Block #"}{record?.block_number} {" | "}
                {record?.receipt_status === "0" ? (
                    <><CloseCircleOutlined style={{ color: "#ff4d4f" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> Failed</>
                ) : (
                    <><CheckCircleOutlined style={{ color: "#52c41a" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> Confirmed</>
                )}
            </Text>
            <Title level={5}>Gas Details</Title>
            <Text>
                Gas Price: {record?.gas_price ? (Number(record.gas_price) / 1e9).toFixed(2) + " " + 
                    (CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.gasUnit || "Gas Units")
                : "N/A"} | 
                Gas Limit: {record?.gas || "N/A"} | 
                Transaction Fee: {record?.gas_price && record?.receipt_gas_used ? 
                    ((Number(record.gas_price) * Number(record.receipt_gas_used)) / 1e18).toFixed(6) + " " + 
                    (CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.token || "Native Token")
                : "N/A"}
            </Text>
            
            <Title level={5}>Decoded input of the transaction</Title>
            <Space direction="vertical">
                {record?.decoded_transaction?.decoded_call && JSON.stringify(record?.decoded_transaction?.decoded_call) !== "{}" ? (
                    <Space align="start" style={{ width: '100%' }}>
                        <div style={{ width: 80, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>                    
                            <Space>
                                {(() => {
                                // Extract all unique addresses from the input parameters
                                const uniqueAddresses = new Set(
                                    record?.decoded_transaction?.decoded_call.params?.flatMap((param: any) => extractAddresses(param)) || []
                                );

                                // Filter for addresses that have labels/entities
                                const knownAddresses = Array.from(uniqueAddresses).filter((address: unknown) => {
                                    const addressStr = (address as string).toLowerCase();
                                    return (
                                        (record?.is_search_transaction && addressStr === record?.from_address?.toLowerCase() && record?.from_address_entity) ||
                                        (record?.is_search_transaction && addressStr === record?.to_address?.toLowerCase() && record?.to_address_entity) ||
                                        record?.address_items_map?.[addressStr as keyof typeof record.address_items_map]?.labels?.[0]?.name ||
                                        record?.address_items_map?.[address as keyof typeof record.address_items_map]?.main_labels?.chain_id?.name
                                    );
                                });

                                // Calculate number of unknown addresses
                                const unknownCount = uniqueAddresses.size - knownAddresses.length;

                                // Only show avatars if we have known addresses or unknown ones to count
                                return (knownAddresses.length > 0 || unknownCount > 0) && (
                                    <Space size={-6}>
                                        {/* Show maximum 3 avatars */}
                                        {knownAddresses.slice(0, 3).map((address, index) => (
                                            <Tooltip title={
                                                record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity
                                                    ? `${record?.from_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`
                                                    : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity
                                                        ? `${record?.to_address_entity} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})`
                                                        : record?.address_items_map?.[address as string]?.labels?.[0]?.name
                                                            ? `${record?.address_items_map?.[address as string]?.labels?.[0]?.name} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})`
                                                            : "unknown address label"
                                            }>
                                                <Avatar 
                                                    src={
                                                        record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity_logo
                                                            ? record?.from_address_entity_logo
                                                            : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity_logo
                                                                ? record?.to_address_entity_logo
                                                                : record?.address_items_map?.[address as string]?.labels?.[0]?.source
                                                                    ? record?.address_items_map?.[address as string]?.labels?.[0]?.source
                                                                    : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                    }
                                                    style={{ 
                                                        border: '1px solid #f0f0f0',
                                                        marginLeft: index === 0 ? 0 : '-8px',
                                                        zIndex: 3 - index
                                                    }}
                                                />
                                            </Tooltip>
                                        ))}

                                        {/* Show +X more if there are additional addresses */}
                                        {(knownAddresses.length > 3 || unknownCount > 0) && (
                                            <Tooltip title={(() => {
                                                const tooltipParts = [];
                                                
                                                // Add remaining known addresses to tooltip
                                                if (knownAddresses.length > 3) {
                                                    const remainingKnown = knownAddresses.slice(3).map(address => {
                                                        if (record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity) {
                                                            return `${record?.from_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                        } else if (record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity) {
                                                            return `${record?.to_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                        } else {
                                                            const entityLabel = record?.address_items_map?.[address as string]?.labels?.[0]?.name;
                                                            return entityLabel ? `${entityLabel} (${(address as string).slice(0, 6)}...${(address as string).slice(-6)})` : null;
                                                        }
                                                    }).filter(Boolean);
                                                    if (remainingKnown.length > 0) {
                                                        tooltipParts.push(remainingKnown.join('\n'));
                                                    }
                                                }

                                                // Add unknown addresses count to tooltip
                                                if (unknownCount > 0) {
                                                    tooltipParts.push(`${unknownCount} more unknown address${unknownCount > 1 ? 'es' : ''}`);
                                                }

                                                return tooltipParts.join('\n');
                                            })()}>
                                                <Avatar 
                                                    size="small"
                                                    style={{ 
                                                        marginLeft: '-8px',
                                                        background: '#1890ff',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        zIndex: 0
                                                    }}
                                                >
                                                    +{(knownAddresses.length > 3 ? knownAddresses.length - 3 : 0) + unknownCount}
                                                </Avatar>
                                            </Tooltip>
                                        )}
                                    </Space>
                                );
                            })()}                 
                            <Tooltip title={`Function: ${record.decoded_transaction.decoded_call.signature}`}>
                                <Tag color="orange">
                                    {record.decoded_transaction.decoded_call.label}
                                </Tag>
                            </Tooltip>
                            {record.decoded_transaction.decoded_call.params?.slice(0, 0).map((param: any) => (
                            <Tooltip title={
                                param.type.includes('address') 
                                    ? (record?.is_search_transaction && param.value?.toLowerCase() === record?.from_address?.toLowerCase() && record?.from_address_entity
                                        ? `${param.type}: ${record?.from_address_entity} (${param.value})`
                                        : record?.is_search_transaction && param.value?.toLowerCase() === record?.to_address?.toLowerCase() && record?.to_address_entity
                                            ? `${param.type}: ${record?.to_address_entity} (${param.value})`
                                            : record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name
                                                ? `${param.type}: ${record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name} (${param.value})`
                                                : `${param.type}: ${param.value}`)
                                    : `${param.type}: ${param.value}`
                            }>
                                <Tag color={
                                    param.type.includes('int') ? 'blue' :
                                    param.type.includes('uint') ? 'blue' :
                                    param.type.includes('address') ? 'green' :
                                    param.type.includes('bool') ? 'volcano' :
                                    param.type.includes('bytes') ? 'purple' :
                                    param.type.includes('string') ? 'cyan' :
                                    'default'
                                }>
                                    {param.name}
                                </Tag>
                            </Tooltip>
                            ))}
                            {record.decoded_transaction.decoded_call.params?.length > 0 && (
                                <Tooltip title="Click 'Show decoded input' to see all parameters">
                                    <Tag style={{cursor: "pointer"}} onClick={() => setShowTransactionDecodedInput()} color="grey">
                                        +{record.decoded_transaction.decoded_call.params.length - 0} more
                                    </Tag>
                                </Tooltip>
                            )}
                            <Button 
                                onClick={() => setShowTransactionDecodedInput()}
                                size="small"
                            >
                                Show decoded input
                            </Button>
                        </Space>
                        </div>
                    </Space>
                ) : (
                    <Text>Could not decode the transaction. Please try to add an ABI so next time we can decode it.</Text>
                )}
            </Space>
            <Title level={5} style={{ marginTop: '8px' }}  >Chain</Title>
            <Space>
                <Tooltip title={CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.name || record?.chain}>
                    <Avatar 
                        size="large" 
                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`} 
                    /> 
                </Tooltip>
                <Text>
                    {!record?.chain 
                        ? CHAIN_CONFIG[record?.chain_id as keyof typeof CHAIN_CONFIG]?.name 
                        : record?.chain?.[0]?.toUpperCase() + record?.chain?.slice(1)
                    }
                </Text>
            </Space>

            <Text>
            <Row gutter={16}>
            {
            record?.included_by ? record?.included_by?.map((record: any, index: any) => 
                <><Col span={12}>
                    { index == 0 ? <Title level={5}>Included by items</Title> : "" }
                    <Text> 
                        <Tooltip title={record}><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions/show/"+ record} target="_blank">Link to main item</Link></Tooltip>
                    </Text>
                </Col>
                </>
            ) : ""}
            </Row>
            </Text>
            
            <Title level={5}>Tags</Title>
            <Space>
                <Text>
                    {record?.tags?.map((tag: any) => (
                        <Tag color={"#" + tag.color}>{tag.name}</Tag>
                    ))}
                </Text>
                <Button 
                    type="primary" 
                    icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                    onClick={() => showEditTags(`${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`)}
                    size="small"
                >
                    Edit Tags
                </Button>
            </Space>
            <Modal 
                {...editTagsModalProps} 
                width="1000" 
                centered 
                title="Edit Transaction Tags" 
                confirmLoading={isImportingTransaction || isSubmitting}
                okButtonProps={{
                    loading: isImportingTransaction || isSubmitting,
                    disabled: isImportingTransaction || isSubmitting,
                    onClick: async () => {
                        const values = editTagsFormProps.form?.getFieldsValue();
                        await handleEditTagsFormSubmit(values);
                    }
                }}
                destroyOnClose={true}
            >
                <Form 
                    {...editTagsFormProps}
                    initialValues={{
                        tags: record?.tags?.map((tag: any) => tag.name) || []
                    }}
                    onFinish={(values) => 
                        { 
                            setEditTagsId(`${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`)
                            handleEditTagsFormSubmit(values)
                        }}
                >
 
                    {record?.is_search_transaction && (
                        <Alert
                            message="This transaction needs to be imported first"
                            description="When you save these tags, we'll first import the transaction and then apply the tags."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}                      
                    <Form.Item
                        label="From Address Labels"
                        name="from_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>          
                    <Form.Item
                        label="To Address Labels"
                        name="to_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                        
                    <Form.Item
                        label="Groups"
                        name="group_names"
                        initialValue={[]}
                        hidden={true}
                    ></Form.Item>                    
                    <Form.Item
                        label="Tags"
                        name="tags"
                        rules={[{ required: false }]}
                    >
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            placeholder="Select tags or enter new ones"
                            style={{ width: '100%' }}
                            defaultValue={record?.tags?.map((tag: any) => tag.name) || []}
                            options={record?.tags?.map((tag: any) => ({
                                label: tag.name,
                                value: tag.name,
                            }))}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Text type="secondary" style={{ padding: '0 8px' }}>
                                        Type to create new tags (press Enter or comma to add)
                                    </Text>
                                </>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Title level={5} style={{ marginTop: '8px' }}>Group(s)</Title>
            <Space>
                <Text>
                    {record?.group_names && record.group_names.length > 0 ? (
                        record.group_names.map((group: any) => (
                            <Tag color={"#" + group.color}>{group.name}</Tag>
                        ))
                    ) : (
                        <Text>No groups assigned yet to this transaction</Text>
                    )}
                </Text>
                <Button 
                    type="primary" 
                    icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                    onClick={() => showEditGroups(`${user?.sub}-${record?.block_timestamp}-${record?.chain_id}-${record?.hash}-${record?.message_type}`)}
                    size="small"
                >
                    Edit Groups
                </Button>
            </Space>

            <Modal 
                {...editGroupsModalProps} 
                width="1000" 
                centered 
                title="Edit Transaction Groups" 
                confirmLoading={isImportingTransaction || isSubmitting}
                okButtonProps={{
                    loading: isImportingTransaction || isSubmitting,
                    disabled: isImportingTransaction || isSubmitting,
                    onClick: async () => {
                        const values = editGroupsFormProps.form?.getFieldsValue();
                        await handleGroupFormSubmit(values);
                    }                         
                }}
                destroyOnClose={true}
            >
                <Form 
                    {...editGroupsFormProps}
                    initialValues={{
                        group_names: record?.group_names?.map((group: any) => group.name) || []
                    }}
                    onFinish={(values) => handleGroupFormSubmit(values)}
                >
                    {record?.is_search_transaction && (
                        <Alert
                            message="This transaction needs to be imported first"
                            description="When you save these groups, we'll first import the transaction and then apply the groups."
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    )}
                    <Form.Item
                        label="Tags"
                        name="tags"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>        
                    <Form.Item
                        label="From Address Labels"
                        name="from_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>          
                    <Form.Item
                        label="To Address Labels"
                        name="to_address_label"
                        hidden={true}
                        initialValue={[]}
                    ></Form.Item>                                        
                    <Form.Item
                        label="Groups"
                        name="group_names"
                        rules={[{ required: false }]}
                    >
                        <Select
                            mode="tags"
                            tokenSeparators={[',']}
                            placeholder="Select groups or enter new ones"
                            style={{ width: '100%' }}
                            defaultValue={record?.group_names?.map((group: any) => group.name) || []}
                            options={record?.group_names?.map((group: any) => ({
                                label: group.name,
                                value: group.name,
                            }))}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Text type="secondary" style={{ padding: '0 8px' }}>
                                        Type to create new groups (press Enter or comma to add)
                                    </Text>
                                </>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Row gutter={16}>
            {
            record ? record?.group_assignments_result?.map((record: any, index: any) => 
                <><Col span={12}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    bordered={false}
                    onClick={()=> navigate("/groups/show/"+ record.group_name)}
                    actions={[
                    <Tooltip title="More"><Link to={'https://dashboard.' + process.env.REACT_APP_API_BASE_URL + "/transactions?pageSize=10&current=1&group_name="+ record.group_name} target="_blank"><ExpandAltOutlined key="more" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Link></Tooltip>,
                    ]}
                >
                        <Tooltip title={record.group_name}>
                            <Statistic title={"Transactions in group: " + record?.group_name?.substring(0, 10)} value={record.count} />
                        </Tooltip>
                        {/* <Meta
                            description={         
                                record?.assignments?.map((group: any)=>{
                                return "-teds " +  group
                            })
                        } 
                        /> */}
                        <Title level={5}>Transaction(s)</Title>
                        {/* {         
                                record?.assignments?.map((assignment: any)=>{
                                return <Typography.Link id="assignment" href="#">{assignment}</Typography.Link>
                            })
                        }    */}
                        <List
                            itemLayout="horizontal"
                            dataSource={record?.assignments}
                            renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record.assignments[0].chain_id}.svg`} />}

                                    // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                    title={<Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions?pageSize=10&current=1&group_name="+ item} target="_blank">{record?.assignments[index]?.transaction_id?.split("-").at(-2)}</Link>}
                                    // description={record?.assignments[index]}
                                />
                            </List.Item>
                            )}
                        />
                    </Card>
                </Col>
                </>
            ) : ""}
            </Row>

            {!record?.is_search_transaction ? <>
            <Divider></Divider>
                <Title level={5} >Processed by the following Integrations:</Title>
            <>
                <Modal 
                    {...modalProps} 
                    width="1000" 
                    centered 
                    title={`Integration Details: ${record?.integration_results?.find(integration => integration.integration_id === showIntegrationIdDetails)?.integration_name}`}
                    onOk={() => {
                        setShowIntegrationIdDetails("");  // Reset the selected integration
                        close();  // Close the modal
                    }}
                    onCancel={() => {
                        setShowIntegrationIdDetails("");  // Reset the selected integration
                        close();  // Close the modal
                    }}
                >
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        {/* Global Section */}
                        <Card 
                            title="Global Information" 
                            bordered={false}
                            style={{ 
                                background: '#f0f5ff'  // Light blue
                            }}
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <div>
                                    <Text strong>Tags:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.global_tags?.map((tag: string) => (
                                                <Tag color="blue">{tag}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>From Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.global_from_address_labels?.map((label: string) => (
                                                <Tag color="green">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>To Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.global_to_address_labels?.map((label: string) => (
                                                <Tag color="orange">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>Groups:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.main_groups?.map((group: string) => (
                                                <Tag color="gold">{group}</Tag>
                                            ))}
                                    </Space>
                                </div>                                
                            </Space>
                        </Card>

                        {/* Main Section */}
                        <Card 
                            title="Main Information" 
                            bordered={false}
                            style={{ 
                                background: '#f6ffed'  // Light green
                            }}
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <div>
                                    <Text strong>Tags:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.main_tags?.map((tag: string) => (
                                                <Tag color="purple">{tag}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>From Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.main_from_address_labels?.map((label: string) => (
                                                <Tag color="cyan">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>To Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.main_to_address_labels?.map((label: string) => (
                                                <Tag color="magenta">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>Groups:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.main_groups?.map((group: string) => (
                                                <Tag color="gold">{group}</Tag>
                                            ))}
                                    </Space>
                                </div>
                            </Space>
                        </Card>

                        {/* Attached Section */}
                        <Card 
                            title="Directly attached Information to the transaction" 
                            bordered={false}
                            style={{ 
                                background: '#fff7e6'  // Light orange
                            }}
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <div>
                                    <Text strong>Tags:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.tags?.map((tag: string) => (
                                                <Tag color="volcano">{tag}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>From Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.from_address_labels?.map((label: string) => (
                                                <Tag color="geekblue">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>To Address Labels:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.to_address_labels?.map((label: string) => (
                                                <Tag color="lime">{label}</Tag>
                                            ))}
                                    </Space>
                                </div>

                                <div>
                                    <Text strong>Groups:</Text>
                                    <Space style={{ marginLeft: 8 }}>
                                        {record?.integration_results
                                            ?.find(integration => integration.integration_id === showIntegrationIdDetails)
                                            ?.groups?.map((group: string) => (
                                                <Tag color="processing">{group}</Tag>
                                            ))}
                                    </Space>
                                </div>
                            </Space>
                        </Card>
                    </Space>
                </Modal>
            </>
            {!record?.integration_results || record.integration_results.length === 0 ? (
                <Alert
                    message="No Integration Results"
                    description={
                        <>
                            This transaction hasn't been processed by any integrations yet. Integrations can:
                            <ul style={{ marginTop: '8px', marginBottom: 0 }}>
                                <li>Add tags, labels, and group assignments automatically</li>
                                <li>Decode input and output data of the transaction</li>
                                <li>Add token flows that are displayed in the transaction graph</li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                    style={{ marginTop: 16 }}
                />
            ) : null}
            <Row gutter={16}>
        {
            record ? record?.integration_results?.map((record: any, index) => 
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                        <Tooltip title="More">
                            <ExpandAltOutlined 
                                key="setting" 
                                onClick={(e) => {showIntegrationDetails(record.integration_id)}} 
                                onPointerEnterCapture={true} 
                                onPointerLeaveCapture={true}
                            />
                        </Tooltip>,
                        <Tooltip title="Edit">
                            <Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/integrations/show/"+ record.integration_id} target="_blank">
                                <EditOutlined key="edit" onPointerEnterCapture={true} onPointerLeaveCapture={true}/>
                            </Link>
                        </Tooltip>,
                    ]}
                >
                    <Skeleton loading={false} avatar active>
                        <Meta
                            avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                            title={record.integration_id}
                            description={record.integration_name}
                        />
                    </Skeleton>
                </Card>
            </Col>
            ) :             
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                        <Tooltip title="More"><SettingOutlined key="setting_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                        <Tooltip title="Edit"><EditOutlined key="edit_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                        <Tooltip title="Delete"><DeleteOutlined key="delete_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    ]}
                >
                    <Skeleton loading={true} avatar active>
                    </Skeleton>
                </Card>
            </Col>
        }
                    
        </Row>
        <Divider></Divider>
        {
            record?.shared_with ?
            <>
                <Title level={5}>Transaction shared with users:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.shared_with ? record?.shared_with : ["user1"] }
                renderItem={(item, index) => (
                    <List.Item key={item}>
                    <List.Item.Meta
                        avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        title={<a href="https://ant.design">{item.id}</a>}
                        description={item.email}
                    />
                    <Space>
                        {
                            record?.owner_id == item.id ?
                            <Button onClick={(e) => console.log()}
                                size="small"
                            >Owner</Button>
                          : ""
                        }  
                            <DeleteButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            />
                        </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         } </>: ""}   {/* When not is_search_transaction */}
        <>
            <Modal {...modalPropsShareWithModal} width="1000" centered title={"Share Transaction" }>
            <Form {...formPropsShareWithModal} onFinish={onFinishShareWith}>
                    <Form.Item label="Share the address with the following people in the organization"
                        name="shared_with"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                    <DebounceSelect
                        mode="multiple"
                        value={value}
                        placeholder="Select users"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                            setValue(newValue as UserValue[]);
                        }}
                        style={{ width: 500 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>    
        <Divider></Divider>
        <Modal {...internalTransactionModalProps} width="1000" centered title={"Internal Transaction Info"} onOk={closeInternalTransaction}>
           <pre>{JSON.stringify(showInternalTransactionDetails, null, 2)}</pre>
        </Modal>       
        <Modal {...transactionDecodedInputModalProps} width="1000" centered title={"Decoded Transaction Info"} onOk={closeTransactionDecodedInput}>
           <pre>{record?.decoded_transaction?.decoded_call && JSON.stringify(record?.decoded_transaction?.decoded_call) != "{}" ? JSON.stringify(record?.decoded_transaction?.decoded_call, null, 2)  : "Could not decode the transaction. Please try to add an ABI so next time we can decode it."}</pre>
        </Modal>     
                 
        {
            record?.internal_transactions ?
            <>
                <Title level={5}>Internal Transactions:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.internal_transactions ? record?.internal_transactions : [] }
                renderItem={(item, index) => (
                    <List.Item key={item}>
                    <List.Item.Meta
                        avatar={
                            <Space size={-6}>
                                {/* From Address Avatar */}
                                <Tooltip title={
                                    record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity
                                        ? `${record?.from_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                        : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity
                                            ? `${record?.to_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                            : record?.address_items_map?.[item.from]?.labels?.[0]?.name
                                                ? `${record?.address_items_map?.[item.from]?.labels?.[0]?.name} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                                : `unknown address label (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                                }>
                                    <Avatar 
                                        src={
                                            record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity_logo
                                                ? record?.from_address_entity_logo
                                                : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity_logo
                                                    ? record?.to_address_entity_logo
                                                    : record?.address_items_map?.[item.from]?.main_labels?.[0]?.source
                                                        ? record?.address_items_map?.[item.from]?.main_labels?.[0]?.source
                                                        : record?.address_items_map?.[item.from]?.labels?.[0]?.source
                                                            ? record?.address_items_map?.[item.from]?.labels?.[0]?.source
                                                            : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                        }
                                        style={{ 
                                            border: '1px solid #f0f0f0',
                                            zIndex: 1
                                        }}
                                    />
                                </Tooltip>
                                {/* To Address Avatar */}
                                <Tooltip title={
                                    record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity
                                        ? `${record?.from_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                        : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity
                                            ? `${record?.to_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                            : record?.address_items_map?.[item.to]?.labels?.[0]?.name
                                                ? `${record?.address_items_map?.[item.to]?.labels?.[0]?.name} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                                : `unknown address label (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                                }>
                                    <Avatar 
                                        src={
                                            record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity_logo
                                                ? record?.from_address_entity_logo
                                                : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity_logo
                                                    ? record?.to_address_entity_logo
                                                    : record?.address_items_map?.[item.to]?.main_labels?.[0]?.source
                                                        ? record?.address_items_map?.[item.to]?.main_labels?.[0]?.source
                                                        : record?.address_items_map?.[item.to]?.labels?.[0]?.source
                                                            ? record?.address_items_map?.[item.to]?.labels?.[0]?.source
                                                            : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                        }
                                        style={{ 
                                            border: '1px solid #f0f0f0',
                                            marginLeft: '-8px',
                                            zIndex: 0
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        }
                        title={<a onClick={() => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])}>{(`Type: ${item.type}` || "") + ((record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.label || record?.decoded_transaction?.decoded_internal_transactions[index]?.output?.label) ? " - " : "") + (record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.label || record?.decoded_transaction?.decoded_internal_transactions[index]?.output?.label || "")}</a>}
                        description={<Button onClick={(e) => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])} size="small">Show decoded internal transaction info</Button>}
                    />
                    <Space>
                        {record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.params?.slice(0, 3).map((param: any) => (
                            <Tooltip title={
                                param.type.includes('address') 
                                    ? (record?.is_search_transaction && param.value?.toLowerCase() === record?.from_address?.toLowerCase() && record?.from_address_entity
                                        ? `${param.type}: ${record?.from_address_entity} (${param.value})`
                                        : record?.is_search_transaction && param.value?.toLowerCase() === record?.to_address?.toLowerCase() && record?.to_address_entity
                                            ? `${param.type}: ${record?.to_address_entity} (${param.value})`
                                            : record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name
                                                ? `${param.type}: ${record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name} (${param.value})`
                                                : `${param.type}: ${param.value}`)
                                    : `${param.type}: ${param.value}`
                            }>
                                <Tag color={
                                    param.type.includes('int') ? 'blue' :
                                    param.type.includes('uint') ? 'blue' :
                                    param.type.includes('address') ? 'green' :
                                    param.type.includes('bool') ? 'volcano' :
                                    param.type.includes('bytes') ? 'purple' :
                                    param.type.includes('string') ? 'cyan' :
                                    'default'
                                }>
                                    {param.name}
                                </Tag>
                            </Tooltip>
                        ))}
                        {record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.params?.length > 3 && (
                            <Tooltip title="Click 'Show decoded internal transaction info' to see all parameters">
                                <Tag onClick={() => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])} color="magenta">
                                    +{record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.params.length - 3} more
                                </Tag>
                            </Tooltip>
                        )}
                        <Tooltip title={
    record?.is_search_transaction && item.from === record?.from_address && record?.from_address_entity
        ? `${record?.from_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
        : record?.is_search_transaction && item.from === record?.to_address && record?.to_address_entity
            ? `${record?.to_address_entity} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
            : record?.address_items_map?.[item.from]?.labels?.[0]?.name
                ? `${record?.address_items_map?.[item.from]?.labels?.[0]?.name} (${item.from.slice(0, 6)}...${item.from.slice(-6)})`
                : "unknown address label"
}>
    <Button onClick={(e) => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])} size="small">
        {item.from ? `${item.from.slice(0, 6)}...${item.from.slice(-6)}` : ""}
    </Button>
</Tooltip>
<Text>{"->"}</Text>
<Tooltip title={
    record?.is_search_transaction && item.to === record?.from_address && record?.from_address_entity
        ? `${record?.from_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
        : record?.is_search_transaction && item.to === record?.to_address && record?.to_address_entity
            ? `${record?.to_address_entity} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
            : record?.address_items_map?.[item.to]?.labels?.[0]?.name
                ? `${record?.address_items_map?.[item.to]?.labels?.[0]?.name} (${item.to.slice(0, 6)}...${item.to.slice(-6)})`
                : "unknown address label"
}>
    <Button onClick={(e) => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])} size="small">
        {item.to ? `${item.to.slice(0, 6)}...${item.to.slice(-6)}` : ""}
    </Button>
</Tooltip>
                    </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }
        <Modal {...eventModalProps} width="1000" centered title={"Event Info"} onOk={closeEvent}>
           <pre>{JSON.stringify(showEventDetails, null, 2)}</pre>
        </Modal>           
        {
            record?.decoded_transaction?.decoded_events ?
            <>
                <Title level={5} style={{ marginBottom: 16 }}>Events:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.decoded_transaction?.decoded_events ? Object.values(record?.decoded_transaction?.decoded_events)  : [] }
                renderItem={(item:any, index) => (
                    <List.Item>
                    <List.Item.Meta
                        avatar={
                            <div style={{ width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  {/* Added center justification */}
                                <Space size={-6}>
                                {(() => {
                                    // Create a Set of unique addresses from all sources
                                    const uniqueAddresses = new Set([
                                        item.address?.toLowerCase(),
                                        ...(item.params
                                            ?.filter((param: any) => param.type === 'address' || param.type === 'address[]')
                                            ?.flatMap((param: any) => {
                                                if (param.type === 'address[]') {
                                                    return typeof param.value === 'string' ? param.value.split(',').map((addr: string) => addr.toLowerCase()) : [];
                                                }
                                                return param.value?.toLowerCase();
                                            }) || [])
                                    ].filter(Boolean));

                                    // Filter for addresses that have labels/entities
                                    const knownAddresses = Array.from(uniqueAddresses).filter(address => {
                                        return (
                                            (record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity) ||
                                            (record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity) ||
                                            record?.address_items_map?.[address]?.labels?.[0]?.source
                                        );
                                    });

                                    // Calculate number of unknown addresses
                                    const unknownCount = uniqueAddresses.size - knownAddresses.length;

                                    // Only show avatars if we have known addresses or unknown ones to count
                                    return (knownAddresses.length > 0 || unknownCount > 0) && (
                                        <Space size={-6}>
                                            {/* Show maximum 3 avatars */}
                                            {knownAddresses.slice(0, 3).map((address, index) => (
                                                <Tooltip title={
                                                    record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity
                                                        ? `${record?.from_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`
                                                        : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity
                                                            ? `${record?.to_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`
                                                            : record?.address_items_map?.[address]?.labels?.[0]?.name
                                                                ? `${record?.address_items_map?.[address]?.labels?.[0]?.name} (${address.slice(0, 6)}...${address.slice(-6)})`
                                                                : "unknown address label"
                                                }>
                                                    <Avatar 
                                                        src={
                                                            record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity_logo
                                                                ? record?.from_address_entity_logo
                                                                : record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity_logo
                                                                    ? record?.to_address_entity_logo
                                                                    : record?.address_items_map?.[address]?.labels?.[0]?.source
                                                                        ? record?.address_items_map?.[address]?.labels?.[0]?.source
                                                                        : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`
                                                        }
                                                        style={{ 
                                                            border: '1px solid #f0f0f0',
                                                            marginLeft: index === 0 ? 0 : '-8px',
                                                            zIndex: 3 - index
                                                        }}
                                                    />
                                                </Tooltip>
                                            ))}

                                            {/* Show +X more if there are additional known addresses beyond 3 or any unknown addresses */}
                                            {(knownAddresses.length > 3 || unknownCount > 0) && (
                                                <Tooltip title={(() => {
                                                    const tooltipParts = [];
                                                    
                                                    // Add remaining known addresses to tooltip
                                                    if (knownAddresses.length > 3) {
                                                        const remainingKnown = knownAddresses.slice(3).map(address => {
                                                            if (record?.is_search_transaction && address === record?.from_address?.toLowerCase() && record?.from_address_entity) {
                                                                return `${record?.from_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                            } else if (record?.is_search_transaction && address === record?.to_address?.toLowerCase() && record?.to_address_entity) {
                                                                return `${record?.to_address_entity} (${address.slice(0, 6)}...${address.slice(-6)})`;
                                                            } else {
                                                                const entityLabel = record?.address_items_map?.[address]?.labels?.[0]?.name;
                                                                return entityLabel ? `${entityLabel} (${address.slice(0, 6)}...${address.slice(-6)})` : null;
                                                            }
                                                        }).filter(Boolean);
                                                        if (remainingKnown.length > 0) {
                                                            tooltipParts.push(remainingKnown.join('\n'));
                                                        }
                                                    }

                                                    // Add unknown addresses count to tooltip
                                                    if (unknownCount > 0) {
                                                        tooltipParts.push(`${unknownCount} more unknown address${unknownCount > 1 ? 'es' : ''}`);
                                                    }

                                                    return tooltipParts.join('\n');
                                                })()}>
                                                    <Avatar 
                                                        size="small"
                                                        style={{ 
                                                            marginLeft: '-8px',
                                                            background: '#1890ff',
                                                            color: 'white',
                                                            fontWeight: 'bold',
                                                            zIndex: 0
                                                        }}
                                                    >
                                                        +{(knownAddresses.length > 3 ? knownAddresses.length - 3 : 0) + unknownCount}
                                                    </Avatar>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    );
                                })()}
                        </Space>
                        </div>
                    }
                    title={<Tooltip title={item.signature}><a onClick={() => showEvent(item)}>{item.event ? item.event : item.label}</a></Tooltip>}
                    description={<Button onClick={(e) => showEvent(item)} size="small">Show decoded event info</Button>}
                />
                    
                <Space>
                    {item.params?.slice(0, 3).map((param: any) => (
                    <Tooltip title={
                        param.type.includes('address') 
                            ? (record?.is_search_transaction && param.value?.toLowerCase() === record?.from_address?.toLowerCase() && record?.from_address_entity
                                ? `${param.type}: ${record?.from_address_entity} (${param.value.slice(0, 6)}...${param.value.slice(-6)})`
                                : record?.is_search_transaction && param.value?.toLowerCase() === record?.to_address?.toLowerCase() && record?.to_address_entity
                                    ? `${param.type}: ${record?.to_address_entity} (${param.value.slice(0, 6)}...${param.value.slice(-6)})`
                                    : record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name
                                        ? `${param.type}: ${record?.address_items_map?.[param.value?.toLowerCase()]?.labels?.[0]?.name} (${param.value.slice(0, 6)}...${param.value.slice(-6)})`
                                        : `${param.type}: (${param.value.slice(0, 6)}...${param.value.slice(-6)})`)
                            : `${param.type}: ${param.value}`
                    }>
                        <Tag color={
                            param.type.includes('int') ? 'blue' :
                            param.type.includes('uint') ? 'blue' :
                            param.type.includes('address') ? 'green' :
                            param.type.includes('bool') ? 'volcano' :
                            param.type.includes('bytes') ? 'purple' :
                            param.type.includes('string') ? 'cyan' :
                            'default'
                        }>
                            {param.name}
                        </Tag>
                    </Tooltip>
                    ))}
                    {item.params?.length > 3 && (
                        <Tooltip title="Click 'Show decoded event info' to see all parameters">
                            <Tag style={{cursor: "pointer"}} onClick={() => showEvent(item)} color="grey">+{item.params.length - 3} more</Tag>
                        </Tooltip>
                    )}
                    <Tooltip title={
    record?.is_search_transaction && item.address === record?.from_address && record?.from_address_entity
        ? `${record?.from_address_entity} (${item.address})`
        : record?.is_search_transaction && item.address === record?.to_address && record?.to_address_entity
            ? `${record?.to_address_entity} (${item.address})`
            : record?.address_items_map?.[item.address]?.labels?.[0]?.source
                ? `${record?.address_items_map?.[item.address]?.labels?.[0]?.source} (${item.address})`
                : record?.address_items_map?.[item.address]?.main_labels?.chain_id?.name 
                    ? `${record?.address_items_map?.[item.address]?.main_labels?.chain_id?.name} (${item.address})`
                    : record?.address_items_map?.[item.address]?.labels?.[0]?.name
                        ? `${record?.address_items_map?.[item.address]?.labels?.[0]?.name} (${item.address})`
                        : `unknown address label (${item.address.slice(0, 6)}...${item.address.slice(-6)})`
}>
    <Button onClick={(e) => showEvent(item)}
        size="small"
    >{item.address ? `${item.address.slice(0, 6)}...${item.address.slice(-6)}` : ""}</Button>
</Tooltip>
                    <EditButton
                        size="small"
                        hideText={true}
                        recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                        resource="transactions-share-with"
                        meta={{"remove_user_email": item.email}}
                    />
                </Space>
                </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }   
          
         <Divider></Divider>
         

                { 
                    isLoading || isFetching || isRefetching ? 
                     <Spin spinning={isLoading || isFetching || isRefetching}> </Spin> 
                     
                     : 
                     <div>
                     <Divider></Divider>
                     <Title level={3}>Graph:</Title>
                     <ForceGraph2D
        
                        ref={fgRef}
                        graphData={graphDataNew}            
                        // graphData={gData}
                        nodeLabel={(node: any) => {
                            const friendlyNames = node?.friendly_names?.map(
                                (friendlyName: { name: any }) => friendlyName.name
                            )?.join(', ') || 'No labels';
                        
                            // Determine if this node is the from_address or to_address in a search transaction
                            const isFromAddress = record?.is_search_transaction && 
                                node.id.toLowerCase() === (record?.chain_id + "_" + record?.from_address?.toLowerCase());
                            const isToAddress = record?.is_search_transaction && 
                                node.id.toLowerCase() === (record?.chain_id + "_" + record?.to_address?.toLowerCase());
                        
                            // Use the specific address label if it's a search transaction
                            const displayName = node.friendly_name && node.friendly_name !== node.id ? node.friendly_name : (isFromAddress ? 
                                `${record?.from_address_entity ? record.from_address_entity + ' - ' : ''}${record?.from_address_label || ''}` :
                                isToAddress ? 
                                `${record?.to_address_entity ? record.to_address_entity + ' - ' : ''}${record?.to_address_label || ''}` :
                                'Unnamed');

                            return `
                                <div>
                                    <b>Name</b>: <span>${displayName}</span>
                                    <br></br>
                                    <b>Id</b>: <span>${node.id || 'No ID'}</span>
                                    <br></br>
                                    <b>To/From address Labels</b>: <span>${friendlyNames}</span>
                                </div>
                            `;
                        }}

                        linkLabel={(link: any) => {
                            const friendlyNames = link?.friendly_names?.map(
                                (friendlyName: { name: any }) => friendlyName.name
                            )?.join(', ') || 'No tags';

                            return `
                                <div>
                                    <b>Name</b>: <span>${link.friendly_name || 'Unnamed'}</span>
                                    <br></br>
                                    <b>Id</b>: <span>${link.id || 'No ID'}</span>
                                    <br></br>
                                    <b>Tags</b>: <span>${friendlyNames}</span>
                                </div>
                            `;
                        }}

                        nodeAutoColorBy="group"
                        //   onNodeHover={(node:any, prevNode:any) => {
                        //     console.log(node)
                        //     if(node){
                        //         node.val = 20
                        //         node.friendly_name = node.friendly_names
                        //     }
                        //     return node
                        //   }}
                        backgroundColor="white"
                        onLinkHover={(link:any) => {
                            return <Tooltip title="x">x</Tooltip>
                        }}
            
                        linkDirectionalArrowColor={(link:any) => {
                            if (link.linkArrowColor != undefined){
                                return link.linkArrowColor
                            } else{
                                return "black"
                            }
                        }}
                        linkDirectionalArrowLength={(link:any) => {
                            return link.arraySize
                        }}
                        linkDirectionalArrowRelPos={(link:any) => {
                            return 0.3
                        }}
                        linkLineDash={(link:any) => {
                            return [1]
                        }}
                        linkCurvature={(link:any) => {
                            if (link.curved != undefined){
                                return link.curved
                            } else{
                                return 0
                            }
                        }}
                        linkColor={(link:any) => {
                            if (link.linkColor != undefined){
                                return link.linkColor
                            } else{
                                return link.linkColor
                            }
                        }}
                        onLinkClick={(link:any) => {
                            return window.open(
                                link.link_url,
                                '_blank' // <- This is what makes it open in a new window.
                            );
                        }}
                        //   linkLabel={(link:any) => {
                        //     console.log(link)
                        //     return "djknsdnjkfdkjn"
                        //   }}
                        nodeCanvasObjectMode={() => "replace"}
                        nodeCanvasObject={(node:any, ctx:any, globalScale:any) => {
                            // const label = node.name.substring(0, [6]);
                            const label = node.friendly_name.name
                            const fontSize = 12 / globalScale;
                            ctx.font = `${fontSize}px Sans-Serif`;
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillStyle = "black"; //node.color;
                            // if (node.isClusterNode) {
                            //   ctx.fillText(label, node.x!, node.y!);
                            // } else {
                            //   ctx.fillText(label, node.x! + 20, node.y!);
                            // }
                            const size = 12      
                            
                            if (images && node.source) {
                                const imageSource = record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.from_address?.toLowerCase()) && record?.from_address_entity_logo
                                    ? record?.from_address_entity_logo
                                    : record?.is_search_transaction && node.id.toLowerCase() === (record?.chain_id + "_" + record?.to_address?.toLowerCase()) && record?.to_address_entity_logo
                                        ? record?.to_address_entity_logo
                                            : node.source;

                                ctx.drawImage(
                                    images.find(img => img.src === imageSource), 
                                    node.x! - size / 2, 
                                    node.y! - size / 2, 
                                    size, 
                                    size
                                );
                            }
                        }}
                        
                        linkCanvasObjectMode={() => "after"}
                        linkCanvasObject={(link:any, ctx:any, globalScale:any) => {
                            const MAX_FONT_SIZE = 4;
                            const LABEL_NODE_MARGIN = 12;
                            const start = link.source;
                            const end = link.target;
                            // ignore unbound links
                            if (typeof start !== 'object' || typeof end !== 'object') return;
                            // calculate label positioning
                            const textPos = Object.assign({},...['x', 'y']?.map(c => ({
                            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                            })));
                            const relLink = { x: end.x - start.x, y: end.y - start.y };
                            const maxTextLength = Math.sqrt(Math.pow(relLink.x, 2) + Math.pow(relLink.y, 2)) - LABEL_NODE_MARGIN * 2;
                            let textAngle = Math.atan2(relLink.y, relLink.x);
                            // maintain label vertical orientation for legibility
                            if (textAngle > Math.PI / 2) textAngle = -(Math.PI - textAngle);
                            if (textAngle < -Math.PI / 2) textAngle = -(-Math.PI - textAngle);
                            // const label = link.linkType;
                            const label = link.linkLabel;
            
                            // estimate fontSize to fit in link length
                            ctx.font = '1px Sans-Serif';
                            const fontSize = Math.min(MAX_FONT_SIZE, maxTextLength / ctx.measureText(label).width);
                            ctx.font = `${fontSize}px Sans-Serif`;
                            const textWidth = ctx.measureText(label).width;
                            const bckgDimensions = [textWidth, fontSize]?.map(n => n + fontSize * 0.2); // some padding
                            // draw text label (with background rect)
                            ctx.save();
                            ctx.translate(textPos.x, textPos.y);
                            ctx.rotate(textAngle);
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
            
                            ctx.fillRect(- bckgDimensions[0] / 2, - bckgDimensions[1] / 2, ...bckgDimensions);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillStyle = 'darkgrey';
                            // ctx.setLineDash([]);
                            
                            ctx.fillText(label, 0, 0);
                            ctx.restore();
                        }}
            
                        
                        />
                        </div>
                    }
        </Show>
    );
};
