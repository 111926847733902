import React, { useEffect, useState } from 'react';


import { IResourceComponentsProps, useInvalidate, useOne, useUpdate } from "@refinedev/core";

import {
    List,
    useTable,
    useModalForm,
    RefreshButton,
    CreateButton,
    DeleteButton,
    ShowButton,
} from "@refinedev/antd";

import { Form, Input, Modal, Avatar, Card, Skeleton, Switch, Col, Row, Spin, Space, Tooltip, Select, Tag  } from "antd";
const { Meta } = Card;
import { EyeOutlined, EditOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { IIntegration } from "interfaces";
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';

import { List as ListAntd } from "antd";

export const IntegrationsList: React.FC<IResourceComponentsProps> = () => {
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const [isLoading, setIsLoading] = React.useState<Array<boolean>>([]);
    const [integrations, setIntegrations] = React.useState<Array<IIntegration>>();

    const {
        tableQueryResult,
    } = useTable<IIntegration>({
        meta: { 
            "last_evaluated_key": "null",
            "limit": "null",
            "search": "null"
        },
    });

    let [editIntegration, setEditIntegration] = React.useState<any>() 
    let [editIntegrationChecked, setEditIntegrationChecked] = React.useState<boolean>(false) 
    let [enableDetailedEdit, setEnableDetailedEdit] = React.useState<boolean>(false) 

    // Show integrations task settings Modal
    const {
        modalProps: showIntegrationSettingsModalProps,
        formProps: showIntegrationSettingsFormProps,
        show: showIntegrationSettingsModalShow,
        setId,
        onFinish,
        close: closeUpdateIntegrationModal,
    } = useModalForm({
        id: editIntegration?.id,
        action: "edit",
        resource: "integrations",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeUpdateIntegrationModal()
            refreshTableResults()
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
            setEnableDetailedEdit(false)
        },
        onMutationError: (data, variables, context, isAutoSave) => {
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
        },
    });

    const onFinishUpdateIntegration = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello Integration")
        console.log(editIntegration)
        let new_obj = {}
        if(values.updateCheckedOnly) {
            console.log("Only updating checked value")

            new_obj = {
                id: `${values.record.id}`,
                enabled: values.enabled,
            }
        } else{
            let new_type = values.type != 'undefined' && typeof(values.type) !== 'undefined' && values.type !== undefined ? values.type : editIntegration.type
            let new_stage = values.stage != 'undefined' && typeof(values.stage) !== 'undefined' && values.stage !== undefined  ? values.stage : editIntegration.stage
            let new_name = values.name != 'undefined' && typeof(values.name) !== 'undefined' && values.name !== undefined  ? values.name : editIntegration.name
            let new_description = values.description != 'undefined' && typeof(values.description) !== 'undefined' && values.description !== undefined  ? values.description : editIntegration.description
            let new_timeout_seconds = values.timeout_seconds != 'undefined' && typeof(values.timeout_seconds) !== 'undefined' && values.timeout_seconds !== undefined  ? values.timeout_seconds : editIntegration.timeout_seconds
            let new_heart_beat_seconds = values.heart_beat_seconds != 'undefined' && typeof(values.heart_beat_seconds) !== 'undefined' && values.heart_beat_seconds !== undefined  ? values.heart_beat_seconds : editIntegration.heart_beat_seconds
            let new_context = values.context != 'undefined' && typeof(values.context) !== 'undefined' && values.context !== undefined  ? values.context : editIntegration.context
            let new_arn = values.arn != 'undefined' && typeof(values.arn) !== 'undefined' && values.arn !== undefined  ? values.arn : editIntegration.arn
            let new_field_name = values.field_name != 'undefined' && typeof(values.field_name) !== 'undefined' && values.field_name !== undefined  ? values.field_name : editIntegration.field_name
            let new_enabled = Boolean(editIntegrationChecked) // should always use this one
    
            if (typeof new_context === 'object' || new_context instanceof Object){
                new_context = JSON.stringify(new_context)
            } 
    
            new_obj = {
                id: `${editIntegration.id}`,
                type: `${new_type}`,
                stage: `${new_stage}`,
                name: `${new_name}`,
                description: `${new_description}`,
                timeout_seconds: `${new_timeout_seconds}`,
                heart_beat_seconds: `${new_heart_beat_seconds}`,
                context: `${new_context}`,
                arn: `${new_arn}`,
                field_name: `${new_field_name}`,
                enabled: `${new_enabled}`,
            }
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };


    

    const editIntegrationSettings = async (record: IIntegration) => {
        console.log("Edit task settings")
        if(record){
            console.log(record)
            setEnableDetailedEdit(false)
            setId(record.id);
            setEditIntegration(record)
            setEditIntegrationChecked(record.enabled)
            showIntegrationSettingsModalShow(record.id)
        }

      };

    const handleDetailedScreenChangeIntegrationEnabled = (checked: boolean) => {
        editIntegration.enabled = checked
        setEditIntegrationChecked(checked)
        setEnableDetailedEdit(checked)
    };

    const onChangeMainScreenEnabledIntegration = (checked: boolean, index: any, record: IIntegration) => {
        // 1. Make a shallow copy of the items
        let items = [...isLoading];
        items[index] = true
        setIsLoading(items) 

        onFinishUpdateIntegration({
            "updateCheckedOnly": "updateCheckedOnly",
            "enabled": checked,
            "record": record
        })
    };
    
    const [isLoadingTable, setIsLoadingTable] = React.useState<boolean>(false);
    const refreshTableResults = () => {
        if(tableQueryResult?.data){
            setIntegrations([])
        }
        
        tableQueryResult.refetch()
    };
    
    
    useEffect(() => {
        if(tableQueryResult){
            if(tableQueryResult.data){
                setIntegrations(tableQueryResult?.data.data)
            }
            setIsLoadingTable(tableQueryResult.isRefetching)
        }
       
      }, [editIntegration, tableQueryResult, integrations, enableDetailedEdit]);

    return (
        <>
    <List 
        key="id"
        title="Integrations"      
        headerButtons={
            <><RefreshButton
            loading={isLoadingTable}
                resource="integrations" onClick={(e) => refreshTableResults()} />
            <CreateButton
                    resource="integrations" /></>
      }>
    Integrations are a set of steps
 executed in sequence whenever any of the various triggers are invoked in the Authe pipeline (such as a user logging in). Integrations can be added to a Flow from either the Marketplace
 or be built with custom code to extend the default Authe behavior. Read more
.
        </List>
        <Modal {...showIntegrationSettingsModalProps} title="Task settings" centered>
            <Form 
                {...showIntegrationSettingsFormProps}
                fields={[
                {
                    name: ["id"],
                    value: editIntegration?.id,
                },
                {
                    name: ["type"],
                    value: editIntegration?.type,
                },
                {
                    name: ["stage"],
                    value: editIntegration?.stage,
                },
                {
                    name: ["name"],
                    value: editIntegration?.task.name,
                },
                {
                    name: ["description"],
                    value: editIntegration?.task.description,
                },
                {
                    name: ["timeout_seconds"],
                    value: editIntegration?.task.timeout_seconds,
                },
                {
                    name: ["heart_beat_seconds"],
                    value: editIntegration?.task.heart_beat_seconds,
                },
                {
                    name: ["context"],
                    value: editIntegration?.task.context,
                },
                {
                    name: ["arn"],
                    value: editIntegration?.task.arn,
                },
                {
                    name: ["field_name"],
                    value: editIntegration?.task.field_name,
                },
                {
                    name: ["enabled"],
                    value: editIntegration?.task.enabled,
                },
                ]}
                layout="vertical"
                onFinish={onFinishUpdateIntegration}
                >
                <Form.Item
                    label="Id"
                    name="id"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editIntegration?.id} disabled={false}/>
                </Form.Item>
               <Form.Item
                    label="Type"
                    name="type"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editIntegration?.type} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="stage"
                    name="stage"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editIntegration?.stage} disabled={!enableDetailedEdit}/>
                </Form.Item>
                 <Form.Item
                    label="Name"
                    name="name"
                    style={{ width: 500 }}
                >
                    <Input defaultValue={editIntegration?.task.name} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    style={{ width: 500 }}
                >
                    <Input  defaultValue={editIntegration?.task.description} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="timeout_seconds" label="Timeout in Seconds">
                    <Input defaultValue={editIntegration?.task.timeout_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="heart_beat_seconds" label="Heartbeat in Seconds">
                    <Input defaultValue={editIntegration?.task.heart_beat_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="context" 
                    label="Context"
                    rules={[
                        {
                            required: false,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                try{
                                    if (typeof value === 'object' || value instanceof Object){
                                        JSON.stringify(value)
                                    } else{
                                        JSON.parse(value)
                                    }
                                    
                                    return Promise.resolve();
                                }
                                catch{
                                    return Promise.reject(
                                        new Error("'No valid JSON"),
                                    );
                                }
                            },
                        },
                    ]}             
                    getValueProps={(v) => {
                        if (typeof v === 'string' || v instanceof String){
                            return ({ name: 'context', value: v });
                        } else{
                            return ({ name: 'context', value: JSON.stringify(editIntegration?.task.context) });
                        }
                    }}>
                    <Input type="textarea"  disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="arn" label="Lambda Arn">
                    <Input defaultValue={editIntegration?.task.arn} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="field_name" label="Field Name">
                    <Input defaultValue={editIntegration?.task.field_name} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="enabled" 
                    label={<Link to={''}>Edit Integration?</Link>       }
                    labelAlign="right"
                    // getValueProps={(v) => {
                    //     return ({ name: 'enabled', value: editIntegration?.task.enabled });
                    //     }}
                    >                        
                    <Switch checkedChildren={<CheckOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} unCheckedChildren={<CloseOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} onChange={handleDetailedScreenChangeIntegrationEnabled} style={{marginTop: 20}} defaultChecked={false} />
                </Form.Item>
            </Form>
        </Modal>
        
        <Row gutter={16}>
        {
            integrations ? integrations.map((record: IIntegration, index) => 
            <Col span={8}>
                <Card
                    key={record.id}
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record?.id}
                    />,
                    // <Tooltip title="More"><EyeOutlined key="setting" onClick={() => editIntegrationSettings(record)} onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    // <Tooltip title="Edit"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/code-editor/index.html?id="+ record.id} target="_blank"><EditOutlined key="edit" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Link></Tooltip>,
                    // <Tooltip title="Delete">
                    //     <DeleteButton resource="integrations" recordItemId={record.id} hideText size='small'/>
                    // </Tooltip>,
                    ]}
                >
                    <Skeleton loading={false} avatar active>
                    <Meta
                        // avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/integrations/logos/png/${record.id}.png`} />}
                        title={record.name}
                        description={record.description}
                    />
                    <div className="additional" style={{marginTop: 20}}>
                        <p>Id: <Tag>{record.id}</Tag></p>
                        <p>Type: <Tag>{record.integration_type}</Tag></p>
                        {/* <p>Stages: <span>{record.integration_stages}</span></p> */}
                    <p>Stages: 
                    <Tooltip title="Stages">
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            disabled={true}
                            value={record?.integration_stages}
                        />
                    </Tooltip>
                    </p>
                    <p>Triggered on: 
                    <ListAntd
                            itemLayout="horizontal"
                            dataSource={record?.trigger_on}
                            renderItem={(item, index) => (
                            <ListAntd.Item>
                                <ListAntd.Item.Meta
                                    avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.trigger_on[index]?.chain_id}.svg`} />}

                                    // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                    title={record?.trigger_on[index]?.chain}
                                    description={record?.trigger_on[index]?.address}
                                />
                            </ListAntd.Item>
                            )}
                        />
                    </p>
                    </div>
                    <p>Enabled:
                    <Spin spinning={isLoading[index] ? isLoading[index]: false}>
                        <Switch checkedChildren={<CheckOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} unCheckedChildren={<CloseOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} style={{marginTop: 20}} defaultChecked={record.enabled} onChange={(e) => {onChangeMainScreenEnabledIntegration(e, index, record)}}  />
                    </Spin>
                    </p>
                    </Skeleton>
                    
                </Card>
            </Col>
            ) :             
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><EyeOutlined key="setting_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    // <Tooltip title="Edit"><EditOutlined key="edit_loading" /></Tooltip>,
                    // <Tooltip title="Delete"> <DeleteOutlined key="delete_loading" /></Tooltip>,
                    ]}
                >
                    <Skeleton loading={true} avatar active>
                    </Skeleton>
                </Card>
            </Col>
        }
                    
        </Row>
        
        </>
    );
    
};
