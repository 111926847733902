import React, { useState } from "react";

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { Show } from "@refinedev/antd";

import { Button, Divider, Radio, Tooltip, Typography } from "antd";

import { ISharedGraph } from "interfaces";

const { Title, Text } = Typography;

import { Link } from 'react-router-dom';

export const SharedGraphShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ISharedGraph>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const domain = process.env.REACT_APP_API_BASE_URL != "dev" ? process.env.REACT_APP_API_BASE_URL : "sandbox.authe.io"
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"]
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]
    const region = region_config["region"]

    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<ISharedGraph>({
            resource: "shared-graphs",
        });

     
    return (
        <Show isLoading={isLoading} footerButtons={() => (
            <>
            </>
        )}
        >
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Link</Title>
            <Text>
                <Link to={"https://" + domain + "/shared-graphs/" + record?.share_type + "/index.html?shared_graph_id=" + record?.id + "&region=" + region} target="_blank">{"Graph url here"} </Link> 
            </Text>   
            <Title level={5}>Share type</Title>
            <Text>
                <Radio.Group value={record?.share_type} disabled={true}>
                    <Tooltip title="The user needs to be logged in and belong to the organization in order to see the graph"><Radio value={"private"}>Private</Radio></Tooltip>
                    <Tooltip title="The graph can be viewed without any authentication by anybody"><Radio value={"public"}>Public</Radio></Tooltip>
                </Radio.Group>       
            </Text>        
            <Title level={5}>Update type</Title>
            <Text>
                <Tooltip title="The transactions are updated live or its a static snapshot. Public charts can only contain static transactions">
                    <Radio.Group value={record?.update_type} disabled={true}>
                        <Radio value={"static"}>Static</Radio>
                        <Radio value={"live"}>Live</Radio>
                    </Radio.Group>   
                </Tooltip>
            </Text> 
            <Divider></Divider>
            <Tooltip title="Grouped by 60 seconds for public charts">
                <Title level={5}>Approximate number of Views</Title>
                <Text>{record?.viewer_count}</Text>     
            </Tooltip>         
        </Show>
    );
};
