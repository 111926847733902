import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { DeleteButton, SaveButton, Edit, useForm, DateField } from "@refinedev/antd";
import dayjs from "dayjs";

import { Form, Input, Select, SelectProps, Spin, Tag, Tooltip } from "antd";
const { TextArea } = Input;

import { IAddress, IAddressLabel, ITag } from "interfaces";

import { useAccount, useSignMessage } from 'wagmi'

import debounce from 'lodash/debounce';
import { useAuth0 } from "@auth0/auth0-react";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const ActionsEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps, queryResult, onFinish } = useForm<IAddress>();

    const postData = queryResult?.data?.data;
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface UserValue {
        label: string;
        value: string;
    }

    async function fetchUserList(username: string) {
        console.log('fetching user', username);
      
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"]
        const domain = process.env.REACT_APP_API_BASE_URL
    
        const url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/users"

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result.map(
              (user: { name: string; email: string }) => ({
                label: `${user.name}`,
                value: user.email,
              }),
            ),
          );
      }
    
    const [value, setValue] = useState<UserValue[]>([]);

    const [addressLabels, setAddressLabels] = useState<Array<IAddressLabel>
    >(postData?.labels || [])

    const [addressTags, setAddressTags] = useState<Array<ITag>
    >(postData?.tags || [])

    const labelRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };

        let new_color
        if(label == value){
            new_color = "grey"
        } else{
            new_color = "#" +  value
        }

        return (
          <Tag
            color={new_color}
            defaultValue={label}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        );
      };

    const tagRender = (props: any) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };

        let new_color
        if(label == value){
            new_color = "grey"
        } else{
            new_color = "#" +  value
        }

        return (
          <Tag
            color={new_color}
            defaultValue={label}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        );
      };

    return (
        <Edit saveButtonProps={{ ...saveButtonProps }} canDelete={postData?.status?.includes("draft")} footerButtons={({ saveButtonProps, deleteButtonProps }) => (
            <>
                <SaveButton {...saveButtonProps} />
                {deleteButtonProps && (
                    <DeleteButton {...deleteButtonProps} />
                )}
            </>
        )}
        >
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Id"
                    name="id"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item 
                        label="Address"
                        name="address"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                </Form.Item>  
                <Form.Item
                    label="Created At"
                    name="created_at"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                  <DateField value={dayjs.unix(postData?.created_at || 1)} format="LLL"></DateField>
                </Form.Item>
                <Form.Item 
                        label="Created By"
                        name="created_by"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input 
                            disabled={true}
                        ></Input>
                </Form.Item>  
                <Form.Item
                    label="Status"
                    name="status"
                    initialValue = "in progress"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Tooltip title="Friendly name for the address">
                <Form.Item label="Labels"
                    name="labels"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    
                >
                <Select
                    labelInValue
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Add address labels here"
                    tagRender={labelRender}
                    options={addressLabels}
                />
                </Form.Item>
                </Tooltip>
                <Tooltip title="Tags that will be added by default to each transaction for this address">
                <Form.Item label="Default tags for the address"
                    name="tags"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                <Select
                    labelInValue
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Add tags that will be added as default to each transaction here"
                    tagRender={tagRender}
                    options={addressTags}
                />
                </Form.Item>
                </Tooltip>
                <Form.Item label="Share the address with the following people in the organization"
                    name="shared_with"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                <DebounceSelect
                    mode="multiple"
                    value={value}
                    placeholder="Select users"
                    fetchOptions={fetchUserList}
                    onChange={(newValue) => {
                        setValue(newValue as UserValue[]);
                    }}
                    style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item label="Notes"
                    name="notes"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <TextArea
                        placeholder="Notes"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </Form.Item>
            </Form>
        </Edit>

    );
};



