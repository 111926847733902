import React, { useEffect, useMemo, useRef, useState, Key } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne, useNotification, useList } from "@refinedev/core";
import ForceGraph2D from "react-force-graph-2d";

import { TagField, Show, DateField, useModalForm } from "@refinedev/antd";

import { Avatar, Button, Checkbox, Divider, Form, Input, List, Modal, Space, Tag, Tooltip, Typography, Card, Descriptions, Row, Col, Statistic, Table, Select, SelectProps, Spin, DatePicker, TableColumnType, Dropdown, Alert, Slider } from "antd";

import { IAddress, ITransaction } from "interfaces";

const { Title, Text } = Typography;

import { recoverMessageAddress } from 'viem'
import type { Hex } from 'viem'
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { TransactionOutlined, TagsOutlined, EyeOutlined, ReloadOutlined, EditOutlined, FilterOutlined, SettingOutlined, AimOutlined, InfoCircleOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined, CloudSyncOutlined } from "@ant-design/icons";
// Usage of DebounceSelect
interface UserValue {
    label: string;
    value: string;
}
import debounce from 'lodash/debounce';
import { FilterDropdownProps } from "antd/es/table/interface";
import { reset } from "viem/actions";

export interface DebounceSelectPropsShowAddress<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

// Add this interface if not already present
interface LabelTagProps {
    label: string;
    value: string;
    closable: boolean;
    onClose: () => void;
}

const labelRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    let new_color
    if(label == value){
        new_color = "grey"
    } else{
        new_color = "#" +  value
    }

    return (
      <Tag
        id="x"
        key="x"
        title="x"
        color={new_color}
        defaultValue={label}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    let new_color
    if(label == value){
        new_color = "grey"
    } else{
        new_color = "#" +  value
    }

    return (
      <Tag
        id={label.id}
        key={label.id}
        color={new_color}
        defaultValue={label}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

// Add this interface near the top of your file with other type definitions
interface ChainGroup {
    x: number;
    y: number;
    width: number;
    height: number;
    chain_id: number;
    name: string;  // Add the name property to the interface
}

// Update the chainGroups declaration
const chainGroups = new Map<string, ChainGroup>();

export const AddressesShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { id: identifier } = useParams<{ id: string }>();
    const { address } = useAccount()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user?.[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    const storageKey = `${local_storage_prefix}-addressShowVisibleColumns`;

    const defaultColumns = [
        'chain_id',
        'hash',
        'method_label',
        'summary',
        'from_address',
        'to_address',
        'value',
        'block_timestamp',
        'found_in',
        'actions'
    ];

    const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
        // Don't try to load from localStorage until we have user data
        if (!user?.sub || !user?.[process.env.REACT_APP_BASE_URL + "/org_id"] || !user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"]) {
            return defaultColumns;
        }

        try {
            const storedColumns = localStorage.getItem(storageKey);
            return storedColumns ? JSON.parse(storedColumns) : defaultColumns;
        } catch (error) {
            console.warn('Error loading column preferences:', error);
            return defaultColumns;
        }
    });

    // Add this effect to save changes to localStorage
    useEffect(() => {
        // Only save to localStorage if we have all the necessary user data
        if (!user?.sub || !user?.[process.env.REACT_APP_BASE_URL + "/org_id"] || !user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"]) {
            return;
        }

        try {
            localStorage.setItem(storageKey, JSON.stringify(visibleColumns));
        } catch (error) {
            console.warn('Error saving column preferences:', error);
        }
    }, [visibleColumns, storageKey, user]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [activeChains, setActiveChains] = useState<string[]>(searchParams.get('chain_ids')?.split(',').sort() || []);

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')
  
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
  
    let use_region:string
    let use_region_alias: string
    let use_backup_region
    let use_backup_region_alias
  
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
  
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { queryResult } = useShow<IAddress>(
        {
            resource: "addresses",
            id: identifier,
            meta: {
                query: {
                    chain_ids: searchParams.get('chain_ids')
                }
            }
        }
    );

    const { data, isLoading } = queryResult;
    const record = data?.data;
    const available_chains = [
        {"label": "eth", "value": true}, 
        {"label": "goerli", "value": true},
        {"label": "sepolia", "value": true},
        {"label": "polygon", "value": true},
        {"label": "mumbai", "value": true},
        {"label": "bsc", "value": true},
        {"label": "bsc_testnet", "value": true},
        {"label": "avalanche", "value": true},
        {"label": "fantom", "value": true},
        {"label": "cronos", "value": true},
        {"label": "palm", "value": true},
        {"label": "arbitrum", "value": true}
    ]

    const [fromBlock, setFromBlock] = useState(0);
    const [toBlock, setToBlock] = useState(999999999999);
    const [selectedChainsForImport, setSelectedChainsForImport] = useState(available_chains);
    const [selectedChains, setSelectedChains] = useState<string[]>(
        // Only use search params if they differ from active chains
        searchParams.get('chain_ids')?.split(',').sort().join(',') !== activeChains?.sort().join(',')
            ? searchParams.get('chain_ids')?.split(',').filter(Boolean) ?? []
            : []
    );

    const { open } = useNotification();
    const DEFAULT_NODE_IMAGE = "https://cdn.jsdelivr.net/npm/cryptocurrency-icons/svg/color/generic.svg";

    // Create Display columns Modal
    const {
        modalProps: createColumnsModalProps,
        formProps: createColumnsFormProps,
        show: createColumnsModalShow,
        formLoading: createColumnsFormLoading,
        onFinish,
        close,
    } = useModalForm({
        warnWhenUnsavedChanges: false,
        action: "create",
        syncWithLocation: false,
        resource: "addresses",

    });

    const [isLoadingReProcessTransactionsForAddress, setIsLoadingReProcessTransactionsForAddress] = React.useState<boolean>(false);

    const [isLoadingActiveChains, setIsLoadingActiveChains] = useState(false);


    const refreshActiveChains = async () => {
        setIsLoadingActiveChains(true);
        try {
            setIsLoadingActiveChains(true);
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": use_region,
                "Destination-Region": use_region,
            };

            const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain;
            let url;
            if (qa_environment === "blue") {
                url = `https://${use_region_alias}.${continent.toUpperCase()}.private.sandbox.authe.io/transactions-search/blue/GET/${continent.toUpperCase()}/v0/search/${record?.address}`;
            } else {
                url = `https://${use_region_alias}.${continent.toUpperCase()}.private.sandbox.authe.io/transactions-search/GET/${continent.toUpperCase()}/v0/search/${record?.address}`;
            }

            const response = await fetch(url, {
                headers: requestHeaders
            });
            const data = await response.json();
            console.log(data)
            // Update URL with new chain_ids
            if (data?.ok === true) {
                const newSearchParams = new URLSearchParams(searchParams);
                // Update chain_ids parameter with the new chains
                // The result is an array of objects, each with a chain_ids property coming from search feature
                if (data?.result?.[0]?.chain_ids?.length > 0) {
                    newSearchParams.set('chain_ids', data.result[0].chain_ids.join(','));
                } else {
                    newSearchParams.delete('chain_ids');
                }
                // Navigate with updated parameters
                // without url encoding
                if (data?.result?.[0]?.chain_ids?.length > 0) {
                    navigate({
                        search: `chain_ids=${data.result[0].chain_ids.join(',')}`
                    });
                } else {
                    navigate({
                        search: '' // Clear the search params when no chain_ids
                    });
                }
            }
            open?.({
                type: "success",
                message: "Active chains refreshed",
                description: "The list of active chains has been updated",
                key: "active-chains",
            });

            // Extract chain_ids from the search results
            const chains = data?.result?.[0]?.chain_ids || []; 
            setActiveChains(chains);
            setSelectedChains([]);
        } catch (error) {
            open?.({
                type: "error",
                message: "Failed to refresh active chains",
                description: error instanceof Error ? error.message : "Unknown error",
                key: "active-chains",
            });
            console.error("Error refreshing active chains:", error);
        } finally {
            setIsLoadingActiveChains(false);
        }
    };

    // Add useEffect to initialize activeChains from record
    useEffect(() => {
        if (record?.active_chain_ids) {
            setActiveChains(record.active_chain_ids);
        }
    }, [record]);

    async function ReProcessTransactionsForAddress(address_id: string) {
        setIsLoadingReProcessTransactionsForAddress(true)
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": use_region,
            "Destination-Region": use_region,
        };

        var chainsString = ''
        selectedChainsForImport.forEach((chain: any) => {
            if (chain.value == true){
                chainsString = chainsString + "&chains=" + chain.label
            }
        });

        const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses/${address_id}/process-transactions?from_block=${fromBlock}&to_block=${toBlock}${chainsString}`
        }  
        else{
            url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses/${address_id}/process-transactions?from_block=${fromBlock}&to_block=${toBlock}${chainsString}`
        }
        console.log(url)
        return fetch(url, {headers: requestHeaders})
            .then((response) => 
            response.json() 
            )
            .then((body) => {
            if(body.statusCode == 500 || body.statusCode == 400 || body.statusCode == 401 || body.statusCode == 403){
                console.log(body)                       
                setIsLoadingReProcessTransactionsForAddress(false)
                open?.({
                    type: "error",
                    message: "error: " + body.statusCode,
                    description: "error: " + body?.error?.reason ? body?.error?.reason : body?.error?.message, // reason : "Chain is not enabled by org: goerli"
                    key: "address",
                })
            }
            else{
                console.log(body) 
                setIsLoadingReProcessTransactionsForAddress(false)
                open?.({
                    type: "success",
                    message: "Successfully requested the reprocess of the transactions for address",
                    description: "Successfully requested the reprocess of the transactions for address",
                    key: "address",
                })
            }
            }
            )            
            .catch(() =>
                setIsLoadingReProcessTransactionsForAddress(false)
            ).catch(() =>
                open?.({
                    type: "error",
                    message: "error",
                    description: "error",
                    key: "address",
                })
            )
        }

        function toggleChain(e:any) {
            const chain_name = e.target.id
            console.log("Toggling chain " + chain_name )
            for (var chain of selectedChainsForImport) {
                if (chain.label == chain_name) {
                    chain.value = e.target.checked
                }
            }    
            setSelectedChainsForImport(selectedChainsForImport)
        }

        const [transactions, setTransactions] = useState<any[]>([]);
        const [isLoadingInfiniteScroll, setIsLoadingInfiniteScroll] = useState(false);
        const [isRefreshing, setIsRefreshing] = useState(false);
        const [hasMore, setHasMore] = useState(true);
        const [currentPage, setCurrentPage] = useState(1);
        const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState<Record<string, any>>({});
        // Update state to track cursors per chain
        interface PaginationCursor {
            moralis: Record<string, any>;
            database: {
                [chainId: string]: {
                    last_included: {
                        transaction_hash: string | null;
                        block_timestamp: string;
                    }
                }
            };
            status: {
                [chainId: string]: {
                    current_source: string;
                    has_more_data: boolean;
                    is_complete: boolean;
                    error?: string;
                }
            }
        }
        
        const [paginationCursors, setPaginationCursors] = useState<PaginationCursor>({
            moralis: {},
            database: {},
            status: {}
        });
        // Modify fetchTransactions function
        const fetchTransactions = async (refresh = false) => {
            try {
                setIsLoadingInfiniteScroll(true);
                
                // If refreshing, reset everything
                if (refresh) {
                    setTransactions([]);
                    setPaginationCursors({
                        moralis: {},
                        database: {},
                        status: {}
                    });
                    setCurrentPage(1);
                }

                const requestHeaders = {
                    Authorization: `Bearer ${token.__raw}`,
                    Accept: "application/json, text/plain, */*",
                    "Source-Platform": "dashboard",
                    "Source-Region": use_region,
                    "Destination-Region": use_region,
                };

                const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain;
                let url;
                if (qa_environment === "blue") {
                    url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
                } else {
                    url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
                }

                // Add query parameters including pagination cursors
                const queryParams = new URLSearchParams({
                    limit: '100',
                });

                // Add chain_ids from search params if they exist
                const chainIds = searchParams.get('chain_ids');
                if (chainIds) {
                    queryParams.set('chain_ids', chainIds);
                }
          
                // Add pagination cursors when not refreshing
                if (paginationCursors && !refresh) {
                    queryParams.set('pagination_cursors', JSON.stringify(paginationCursors));
                }

                const response = await fetch(`${url}?${queryParams}`, {
                    headers: requestHeaders
                });

                const responseData = await response.json();
                
                if (responseData.result && responseData.ok) {
                    setTransactions(prev => refresh ? responseData.result.transactions : [...prev, ...responseData.result.transactions]);
                    
                    // Update pagination cursors from response
                    if (responseData.result.pagination_cursors) {
                        setPaginationCursors(responseData.result.pagination_cursors);
                    }

                    // Check if any chain has a non-null cursor value
                    const paginationCursors = responseData?.result?.pagination_cursors;
                    const hasMoreData = paginationCursors?.status 
                        ? Object.values(paginationCursors.status).some(
                            (status: any) => !status.is_complete || status.has_more_data
                        )
                        : false;

                    setHasMore(hasMoreData);
                    
                    setCurrentPage(prev => prev + 1);

                    open?.({
                        type: "success",
                        message: "Loaded new transactions",
                        description: "Loaded new transactions",
                        key: "address",
                    }) 
                } else {
                    // Handle error response
                    const errorMessage = responseData.error?.reason || 
                                        responseData.error?.message || 
                                        responseData.error?.detailed_reason || 
                                        'Unknown error occurred';
                    
                    open?.({
                        type: "error",
                        message: `Error (${responseData.statusCode})`,
                        description: errorMessage,
                        key: "address",
                    });
                }
               
            } catch (error) {
                open?.({
                    type: "error",
                    message: "Error fetching transactions",
                    description: error instanceof Error ? error.message : "Unknown error",
                    key: "address",
                })
                console.error('Error fetching transactions:', error);
            } finally {
                setIsLoadingInfiniteScroll(false);
            }
        };


        // Add these state variables near the top with other states
        const [nodesPerRow, setNodesPerRow] = useState(5);
        const [verticalSpacing, setVerticalSpacing] = useState(150);

        // Trigger backend refresh of address data
        const refreshAddressData = async () => {
            try {
                setIsLoadingInfiniteScroll(true);
                const requestHeaders = {
                    Authorization: `Bearer ${token.__raw}`,
                    Accept: "application/json, text/plain, */*",
                    "Source-Platform": "dashboard",
                    "Source-Region": use_region,
                    "Destination-Region": use_region,
                };

                const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain;

                let refreshUrl;
                if (qa_environment === "blue") {
                    refreshUrl = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
                } else {
                    refreshUrl = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
                }

                // Add query parameters including pagination cursors
                const queryParams = new URLSearchParams({
                    limit: '100',
                    get_all_chain_data: String(true),
                });

                // Add chain_ids from search params if they exist
                const chainIds = searchParams.get('chain_ids');
                if (chainIds) {
                    queryParams.set('chain_ids', chainIds);
                }
    
                const response = await fetch(`${refreshUrl}?${queryParams}`, {
                    headers: requestHeaders
                });

                const responseData = await response.json();

                if (responseData.result && responseData.ok) {
                    setTransactions(responseData.result.transactions);
                    
                    // Update pagination cursors from response
                    if (responseData.result?.pagination_cursors) {
                        setPaginationCursors(responseData.result.pagination_cursors);
                    }

                    // Check if any chain has a non-null cursor value
                    const hasMoreData = responseData.result.pagination_cursors?.status 
                    ? Object.values(responseData.result.pagination_cursors.status).some(
                        (status: any) => !status.is_complete || status.has_more_data
                    )
                    : false;
                    setHasMore(hasMoreData);
                    
                    setCurrentPage(prev => prev + 1);
                } else {
                    // Handle error response
                    const errorMessage = responseData.error?.reason || 
                                        responseData.error?.message || 
                                        responseData.error?.detailed_reason || 
                                        'Unknown error occurred';
                    
                    open?.({
                        type: "error",
                        message: `Error (${responseData.statusCode})`,
                        description: errorMessage,
                        key: "address",
                    });
                }
                                
                open?.({
                    type: "success",
                    message: "Address all transactions updated",
                    description: "Address all transactions updated",
                    key: "address",
                })
                // Refresh transactions after triggering backend refresh
                await fetchTransactions(true);
            } catch (error) {
                open?.({
                    type: "error",
                    message: "error",
                    description: "error",
                    key: "address",
                })
                console.error('Error refreshing address data:', error);
            } finally {
                setIsLoadingInfiniteScroll(false);
            }
        };
        

    const chainIdToName: { [key: string]: string } = {
        '0x1': 'Ethereum',
        '0x5': 'Goerli',
        '0xaa36a7': 'Sepolia',
        '0x89': 'Polygon',
        '0x13881': 'Mumbai',
        '0x38': 'BNB Smart Chain',
        '0x61': 'BSC Testnet',
        '0xa86a': 'Avalanche',
        '0xfa': 'Fantom',
        '0x19': 'Cronos',
        '0x2a15c308d': 'Palm',
        '0xa4b1': 'Arbitrum'
    };

    // Add new modal form hooks for tags, labels, and shared with
    const {
        modalProps: editTagsModalProps,
        formProps: editTagsFormProps,
        show: showEditTagsModal,
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: identifier,
        warnWhenUnsavedChanges: true,
    });

    const {
        modalProps: editLabelsModalProps,
        formProps: editLabelsFormProps,
        show: showEditLabelsModal,
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: identifier,
        warnWhenUnsavedChanges: true,
    });

    const [value, setValue] = useState<UserValue[]>([]);

    const fetchUserList = async (username: string) => {
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": use_region,
                "Destination-Region": use_region,
            };

            const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain;
            let url;
            if (qa_environment === "blue") {
                url = `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`
            } else {
                url = `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`
            }
            
            return fetch(url, {headers: requestHeaders})
            .then((response) => 
              response.json() 
            )
            .then((body) =>
              body.result?.map(
                (user: { name: string; email: string }) => ({
                  label: `${user.name} - ${user.email}`,
                  value: user.email,
                }),
              ),
            );

        } catch (error) {
            console.error('Error fetching user list:', error);
            return [];
        }
    };

    const {
        modalProps: editSharedWithModalProps,
        formProps: editSharedWithFormProps,
        show: showEditSharedWithModal,
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: identifier,
        warnWhenUnsavedChanges: true,
    });

    const [chainGroups] = useState(new Map<string, ChainGroup>());

    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsShowAddress<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }
    
    // Add new modal form hook for notes
    const {
        modalProps: editNotesModalProps,
        formProps: editNotesFormProps,
        show: showEditNotesModal,
    } = useModalForm({
        action: "edit",
        resource: "addresses",
        id: identifier,
        warnWhenUnsavedChanges: true,
    });

    // Add the state at the component level
    const [selectedSection, setSelectedSection] = useState<{
        type: string;
        subType: string | null;
        data: any;
        searchAddress: string;
        rowKey: string;
    } | null>(null);

    // Column definition with clickable tags
    const foundInColumn = {
        title: () => (
            <Space direction="vertical" size={0}>
                <Typography.Text strong>Found Address In</Typography.Text>
                <Space direction="vertical" size={0}>
                    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                        Shows where address appears in each transaction:
                    </Typography.Text>
                    <Typography.Text code copyable style={{ fontSize: '12px' }}>
                        {record?.address || record?.id}
                    </Typography.Text>
                </Space>
            </Space>
        ),
        dataIndex: 'address',
        key: 'found_in',
        width: 200,
        filters: [
            { 
                text: <Tag color="green">From Address</Tag>, 
                value: 'From Address' 
            },
            { 
                text: <Tag color="blue">To Address</Tag>, 
                value: 'To Address' 
            },
            { 
                text: <Tag color="purple">Internal Transaction</Tag>, 
                value: 'Internal Transaction'
            },
            { 
                text: <Tag color="orange">Native Transfer</Tag>, 
                value: 'Native Transfer'
            },
            { 
                text: <Tag color="cyan">ERC20 Transfer</Tag>, 
                value: 'ERC20 Transfer'
            },
            { 
                text: <Tag color="magenta">NFT Transfer</Tag>, 
                value: 'NFT Transfer'
            },
            {
                text: <Tag color="red">Contract Interaction</Tag>,
                value: 'Contract Interaction',
                children: [
                    {
                        text: <Tag color="gold">Approval</Tag>,
                        value: 'Contract Approval'
                    },
                    {
                        text: <Tag color="gold">Method Call</Tag>,
                        value: 'Contract Method Call'
                    }
                ]
            }
        ],        
        onFilter: (value: boolean | Key, record: any) => {
            const monitoredAddress = data?.data?.address?.toString().toLowerCase();
            const filterValue = String(value);
            
            switch(filterValue) {
                case 'From Address':
                    return record.from_address?.toLowerCase() === monitoredAddress;
                case 'To Address':
                    return record.to_address?.toLowerCase() === monitoredAddress;
                case 'Internal Transaction':
                    return Boolean(record.internal_transactions?.some((tx: any) => 
                        tx?.from?.toLowerCase() === monitoredAddress || 
                        tx?.to?.toLowerCase() === monitoredAddress
                    ));
                case 'Native Transfer':
                    return Boolean(record.native_transfers?.some((transfer: any) =>
                        transfer?.from_address?.toLowerCase() === monitoredAddress || 
                        transfer?.to_address?.toLowerCase() === monitoredAddress
                    ));
                case 'ERC20 Transfer':
                    return Boolean(record.erc20_transfers?.some((transfer: any) =>
                        transfer?.from_address?.toLowerCase() === monitoredAddress || 
                        transfer?.to_address?.toLowerCase() === monitoredAddress
                    ));
                case 'NFT Transfer':
                    return Boolean(record.nft_transfers?.some((transfer: any) =>
                        transfer?.from_address?.toLowerCase() === monitoredAddress || 
                        transfer?.to_address?.toLowerCase() === monitoredAddress
                    ));
                case 'Contract Approval':
                    return Boolean(record.contract_interactions?.some((interaction: any) =>
                        interaction?.type === 'approval' && (
                            interaction?.from_address?.toLowerCase() === monitoredAddress ||
                            interaction?.to_address?.toLowerCase() === monitoredAddress ||
                            interaction?.spender?.toLowerCase() === monitoredAddress
                        )
                    ));
                case 'Contract Method Call':
                    return Boolean(record.contract_interactions?.some((interaction: any) =>
                        interaction?.type === 'method_call' && (
                            interaction?.from_address?.toLowerCase() === monitoredAddress ||
                            interaction?.to_address?.toLowerCase() === monitoredAddress
                        )
                    ));
                case 'Contract Interaction':
                    // This handles the parent category selection
                    return Boolean(record.contract_interactions?.some((interaction: any) =>
                        (interaction?.from_address?.toLowerCase() === monitoredAddress ||
                        interaction?.to_address?.toLowerCase() === monitoredAddress ||
                        interaction?.spender?.toLowerCase() === monitoredAddress)
                    ));                    
                default:
                    return false;
            }
        },      
        render: (_: any, txRecord: any) => {
            const searchAddress = record?.address.toString().toLowerCase() ;
            if (!searchAddress) return null;

            const locations = [];
            
            if (txRecord.from_address === searchAddress) {
                locations.push(
                    <Tag 
                        key="from"
                        color="green" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'from',
                            subType: null,
                            data: txRecord,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        From Address
                    </Tag>
                );
            }
            
            if (txRecord.to_address === searchAddress) {
                locations.push(
                    <Tag 
                        key="to"
                        color="blue" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'to',
                            subType: null,
                            data: txRecord,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        To Address
                    </Tag>
                );
            }

            const matchingInternalTxs = Array.isArray(txRecord.internal_transactions) 
                ? txRecord.internal_transactions.filter((tx: any) => 
                    tx?.from?.toLowerCase() === searchAddress?.toLowerCase() || tx?.to?.toLowerCase() === searchAddress?.toLowerCase()
                )
                : [];
            if (matchingInternalTxs?.length > 0) {
                locations.push(
                    <Tag 
                        key="internal"
                        color="purple" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'internal',
                            subType: null,
                            data: matchingInternalTxs,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        Internal Transaction ({matchingInternalTxs.length})
                    </Tag>
                );
            }

            const matchingNativeTransfers = Array.isArray(txRecord.native_transfers) 
                ? txRecord.native_transfers.filter((transfer: any) =>
                    transfer?.from_address?.toLowerCase() === searchAddress?.toLowerCase() || 
                    transfer?.to_address?.toLowerCase() === searchAddress?.toLowerCase()
                )
                : [];
            if (matchingNativeTransfers?.length > 0) {
                locations.push(
                    <Tag 
                        key="native"
                        color="orange" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'native',
                            subType: null,
                            data: matchingNativeTransfers,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        Native Transfer ({matchingNativeTransfers.length})
                    </Tag>
                );
            }

            const matchingERC20Transfers = Array.isArray(txRecord.erc20_transfers) 
                ? txRecord.erc20_transfers.filter((transfer: any) =>
                    transfer?.from_address?.toLowerCase() === searchAddress?.toLowerCase() || 
                    transfer?.to_address?.toLowerCase() === searchAddress?.toLowerCase()
                )
                : [];
            if (matchingERC20Transfers?.length > 0) {
                locations.push(
                    <Tag 
                        key="erc20"
                        color="cyan" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'erc20',
                            subType: null,
                            data: matchingERC20Transfers,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        ERC20 Transfer ({matchingERC20Transfers.length})
                    </Tag>
                );
            }

            const matchingNFTTransfers = Array.isArray(txRecord.nft_transfers) 
                ? txRecord.nft_transfers.filter((transfer: any) =>
                    transfer?.from_address?.toLowerCase() == searchAddress?.toLowerCase() || 
                    transfer?.to_address?.toLowerCase() == searchAddress?.toLowerCase()
                )
                : [];
            if (matchingNFTTransfers?.length > 0) {
                locations.push(
                    <Tag 
                        key="nft"
                        color="magenta" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'nft',
                            subType: null,
                            data: matchingNFTTransfers,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        NFT Transfer ({matchingNFTTransfers.length})
                    </Tag>
                );
            }

            // Add new contract interactions section
            const matchingContractInteractions = (() => {
                if (!txRecord.contract_interactions) return { data: [], type: null };
                
                // Get the first key that has data
                const interactionType = Object.entries(txRecord.contract_interactions)
                    .find(([_, interactions]) => Array.isArray(interactions) && interactions.length > 0)?.[0] || null;
                
                const result = {
                    data: interactionType ? txRecord.contract_interactions[interactionType] : [],
                    type: interactionType
                };
                
                return result;
            })();

            const contractData = matchingContractInteractions?.data ?? [];
            if (contractData.length > 0) {
                locations.push(
                    <Tag 
                        key="contract"
                        color="red" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedSection({
                            type: 'contract',
                            subType: matchingContractInteractions.type, // Dynamically set the subType
                            data: contractData,
                            searchAddress,
                            rowKey: `${txRecord.chain_id}_${txRecord.hash}`
                        })}
                    >
                        Contract Interaction ({contractData.length})
                    </Tag>
                );
            }

            return <Space wrap>{locations}</Space>;
        }
    };

    // Modal content renderer
    const renderModalContent = () => {
        if (!selectedSection) return null;

        const { type, data, searchAddress } = selectedSection;

        const HighlightedAddress = ({ address }: { address: string }) => (
            <Space direction="vertical" size={2}>
                <Space>
                    <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>
                        {address}
                    </Typography.Text>
                    {address === searchAddress && (
                        <Tag color="gold">Searched Address</Tag>
                    )}
                </Space>
            </Space>
        );

        switch (type) {
            case 'from':
                return (
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="Transaction Hash">
                            <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>{data.hash}</Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="From Address">
                            <Space>
                                {data.from_address_entity_logo && (
                                    <Tooltip title={data.from_address_entity || data.from_address_label}>
                                        <Avatar size="small" src={data.from_address_entity_logo} />
                                    </Tooltip>
                                )}
                                <HighlightedAddress address={data.from_address} />
                            </Space>
                        </Descriptions.Item>
                        {(data.from_address_label || data.from_address_entity) && (
                            <Descriptions.Item label="Labels">
                                <Space size={4}>
                                    {data.from_address_label && (
                                        <Tag color="blue">{data.from_address_label}</Tag>
                                    )}
                                    {data.from_address_entity && (
                                        <Tag color="purple">{data.from_address_entity}</Tag>
                                    )}
                                </Space>
                            </Descriptions.Item>
                        )}                        
                        <Descriptions.Item label="To Address">
                            <Space>
                                {data.to_address_entity_logo && (
                                    <Tooltip title={data.to_address_entity || data.to_address_label}>
                                        <Avatar size="small" src={data.to_address_entity_logo} />
                                    </Tooltip>
                                )}
                                <HighlightedAddress address={data.to_address} />
                            </Space>
                        </Descriptions.Item>
                        {(data.to_address_label || data.to_address_entity) && (
                            <Descriptions.Item label="Labels">
                                <Space size={4}>
                                    {data.to_address_label && (
                                        <Tag color="blue">{data.to_address_label}</Tag>
                                    )}
                                    {data.to_address_entity && (
                                        <Tag color="purple">{data.to_address_entity}</Tag>
                                    )}
                                </Space>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                );
            case 'to':
                return (
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="Transaction Hash">
                            <Typography.Text copyable style={{ whiteSpace: 'nowrap' }}>{data.hash}</Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="From Address">
                            <Space>
                                {data.from_address_entity_logo && (
                                    <Tooltip title={data.from_address_entity || data.from_address_label}>
                                        <Avatar size="small" src={data.from_address_entity_logo} />
                                    </Tooltip>
                                )}
                                <HighlightedAddress address={data.from_address} />
                                {(data.from_address_label || data.from_address_entity) && (
                                    <Descriptions.Item label="Labels">
                                        <Space size={4}>
                                            {data.from_address_label && (
                                            <Tag color="blue">{data.from_address_label}</Tag>
                                        )}
                                        {data.from_address_entity && (
                                            <Tag color="purple">{data.from_address_entity}</Tag>
                                        )}
                                </Space>
                            </Descriptions.Item>
                        )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="To Address">
                            <Space>
                                {data.to_address_entity_logo && (
                                    <Tooltip title={data.to_address_entity || data.to_address_label}>
                                        <Avatar size="small" src={data.to_address_entity_logo} />
                                    </Tooltip>
                                )}
                                <HighlightedAddress address={data.to_address} />
                            </Space>
                        </Descriptions.Item>
                        {(data.to_address_label || data.to_address_entity) && (
                            <Descriptions.Item label="Labels">
                                <Space size={4}>
                                    {data.to_address_label && (
                                        <Tag color="blue">{data.to_address_label}</Tag>
                                    )}
                                    {data.to_address_entity && (
                                        <Tag color="purple">{data.to_address_entity}</Tag>
                                    )}
                                </Space>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                );
            case 'internal':
                return (
                    <>
                        <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                            Showing {data.length} internal transaction{data.length !== 1 ? 's' : ''}
                        </Typography.Text>
                        <List
                            dataSource={data}
                            renderItem={(tx: any, index: number) => (
                                <List.Item>
                                    <Descriptions 
                                        column={1} 
                                        bordered
                                        title={
                                            <Space>
                                                <Tag color="purple">Internal Transaction #{index + 1} of {data.length}</Tag>
                                            </Space>
                                        }
                                    >
                                        <Descriptions.Item label="From">
                                            <HighlightedAddress address={tx.from} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="To">
                                            <HighlightedAddress address={tx.to} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Value">
                                            {tx.value}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Type">
                                            <Tag color="blue">{tx.type}</Tag>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Gas">
                                            <Space>
                                                <Tag color="orange">Gas Limit: {tx.gas}</Tag>
                                                <Tag color="green">Gas Used: {tx.gas_used}</Tag>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Block">
                                            <Space direction="vertical" size="small">
                                                <Text>Number: {tx.block_number}</Text>
                                                <Text copyable={{ text: tx.block_hash }}>
                                                    Hash: {tx.block_hash?.substring(0, 10)}...{tx.block_hash?.substring(58)}
                                                </Text>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Transaction Hash">
                                            <Typography.Text copyable={{ text: tx.transaction_hash }}>
                                                {tx.transaction_hash?.substring(0, 10)}...{tx.transaction_hash?.substring(58)}
                                            </Typography.Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />
                    </>
                );
            case 'native':
                return (
                    <>
                        <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                            Showing {data.length} native transfer{data.length !== 1 ? 's' : ''}
                        </Typography.Text>
                        <List
                            dataSource={data}
                            renderItem={(transfer: any, index: number) => (
                                <List.Item>
                                    <Descriptions 
                                        column={1} 
                                        bordered
                                        title={
                                            <Space>
                                                <Tag color="orange">Native Transfer #{index + 1} of {data.length}</Tag>
                                            </Space>
                                        }
                                    >
                                        <Descriptions.Item label="From">
                                            <Space>
                                                {transfer.from_address_entity_logo && (
                                                    <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                        <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={transfer.from_address} />
                                                {(transfer.from_address_label || transfer.from_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.from_address_label && (
                                                            <Tag color="blue">{transfer.from_address_label}</Tag>
                                                        )}
                                                        {transfer.from_address_entity && (
                                                            <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="To">
                                            <Space>
                                                {transfer.to_address_entity_logo && (
                                                    <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                        <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={transfer.to_address} />
                                                {(transfer.to_address_label || transfer.to_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.to_address_label && (
                                                            <Tag color="blue">{transfer.to_address_label}</Tag>
                                                        )}
                                                        {transfer.to_address_entity && (
                                                            <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Value">
                                            <Space>
                                                {transfer.token_logo && (
                                                    <Tooltip title={transfer.token_symbol}>
                                                        <Avatar 
                                                            size="small" 
                                                            src={transfer.token_logo}
                                                            style={{ backgroundColor: 'transparent' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                    {transfer.value_formatted} {transfer.token_symbol}
                                                </Typography.Text>
                                                <Tag 
                                                    color={transfer.direction === 'send' ? 'red' : 'green'}
                                                    style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                                >
                                                    {transfer.direction}
                                                </Tag>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Direction">
                                            <Tag color={transfer.direction === 'send' ? 'red' : 'green'}>
                                                {transfer.direction}
                                            </Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />
                    </>
                );
            case 'erc20':
                return (
                    <>
                        <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                            Showing {data.length} ERC20 transfer{data.length !== 1 ? 's' : ''}
                        </Typography.Text>
                        <List
                            dataSource={data}
                            renderItem={(transfer: any, index: number) => (
                                <List.Item>
                                    <Descriptions 
                                        column={1} 
                                        bordered
                                        title={
                                            <Space>
                                                <Tag color="cyan">ERC20 Transfer #{index + 1} of {data.length}</Tag>
                                            </Space>
                                        }
                                    >
                                        <Descriptions.Item label="From">
                                            <Space>
                                                {transfer.from_address_entity_logo && (
                                                    <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                        <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                    </Tooltip>
                                                )}                                                
                                                <HighlightedAddress address={transfer.from_address} />
                                                {(transfer.from_address_label || transfer.from_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.from_address_label && (
                                                            <Tag color="blue">{transfer.from_address_label}</Tag>
                                                        )}
                                                        {transfer.from_address_entity && (
                                                            <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="To">
                                            <Space>
                                                {transfer.to_address_entity_logo && (
                                                    <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                        <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={transfer.to_address} />
                                                {(transfer.to_address_label || transfer.to_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.to_address_label && (
                                                            <Tag color="blue">{transfer.to_address_label}</Tag>
                                                        )}
                                                        {transfer.to_address_entity && (
                                                            <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Token Contract">
                                            <Space>
                                                <HighlightedAddress address={transfer.token_address || transfer.address} />
                                                {transfer.verified_contract && (
                                                    <Tag color="green">Verified</Tag>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Token">
                                            <Space>
                                                {transfer.token_logo && (
                                                    <Tooltip title={transfer.token_symbol}>
                                                        <Avatar size="small" src={transfer.token_logo} />
                                                    </Tooltip>
                                                )}
                                                <Text strong>{transfer.token_name}</Text>
                                                <Text type="secondary">({transfer.token_symbol})</Text>
                                                {transfer.security_score && (
                                                    <Tooltip title="Security Score">
                                                        <Tag color={Number(transfer.security_score) >= 80 ? 'green' : 'orange'}>
                                                            Score: {transfer.security_score}
                                                        </Tag>
                                                    </Tooltip>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Amount">
                                            <Space>
                                                <Text>{transfer.value_formatted}</Text>
                                                <Text type="secondary">{transfer.token_symbol}</Text>
                                                <Tag 
                                                    color={transfer.direction === 'send' ? 'red' : 'green'}
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    {transfer.direction}
                                                </Tag>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Direction">
                                            <Tag color={transfer.direction === 'send' ? 'red' : 'green'}>
                                                {transfer.direction}
                                            </Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />
                    </>
                );
            case 'nft':
                return (
                    <>
                        <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                            Showing {data.length} NFT transfer{data.length !== 1 ? 's' : ''}
                        </Typography.Text>
                        <List
                            dataSource={data}
                            renderItem={(transfer: any, index: number) => (
                                <List.Item>
                                    <Descriptions 
                                        column={1} 
                                        bordered
                                        title={
                                            <Space>
                                                <Tag color="magenta">NFT Transfer #{index + 1} of {data.length}</Tag>
                                            </Space>
                                        }
                                    >
                                        <Descriptions.Item label="From">
                                            <Space>
                                                {transfer.from_address_entity_logo && (
                                                    <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                        <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={transfer.from_address} />
                                                {(transfer.from_address_label || transfer.from_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.from_address_label && (
                                                            <Tag color="blue">{transfer.from_address_label}</Tag>
                                                        )}
                                                        {transfer.from_address_entity && (
                                                            <Tag color="purple">{transfer.from_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="To">
                                            <Space>
                                                {transfer.to_address_entity_logo && (
                                                    <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                        <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={transfer.to_address} />
                                                {(transfer.to_address_label || transfer.to_address_entity) && (
                                                    <Space size={4}>
                                                        {transfer.to_address_label && (
                                                            <Tag color="blue">{transfer.to_address_label}</Tag>
                                                        )}
                                                        {transfer.to_address_entity && (
                                                            <Tag color="purple">{transfer.to_address_entity}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Token Details">
                                            <Space direction="vertical">
                                                <Space>
                                                    <Tag color="purple">{transfer.contract_type}</Tag>
                                                    <Text>ID: {transfer.token_id}</Text>
                                                    {transfer.amount && transfer.amount !== "1" && (
                                                        <Text>Amount: {transfer.amount}</Text>
                                                    )}
                                                </Space>
                                                <Space>
                                                    <Text type="secondary">Contract:</Text>
                                                    <Tooltip title={transfer.token_address}>
                                                        <Typography.Text copyable={{ text: transfer.token_address }}>
                                                            {transfer.token_address?.substring(0, 6)}...{transfer.token_address?.substring(transfer.token_address.length - 6)}
                                                        </Typography.Text>
                                                    </Tooltip>
                                                </Space>
                                                {transfer.verified_collection && (
                                                    <Tag color="green">Verified Collection</Tag>
                                                )}
                                                {transfer.possible_spam && (
                                                    <Tag color="red">Possible Spam</Tag>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                            </Descriptions>
                                        </List.Item>
                                    )}
                                />
                            </>
                        );
            case 'contract':
                return (
                    <>
                        <Typography.Text type="secondary" style={{ marginBottom: '16px', display: 'block' }}>
                            Showing {data.length} contract interaction{data.length !== 1 ? 's' : ''}
                        </Typography.Text>
                        <List
                            dataSource={data}
                            renderItem={(interaction: any, index: number) => (
                                <List.Item>
                                    <Descriptions 
                                        column={1} 
                                        bordered
                                        title={
                                            <Space>
                                                <Tag color="red">
                                                    Contract {selectedSection.subType === 'approvals' ? 'Approval' : 'Method Call'} 
                                                    #{index + 1} of {data.length}
                                                </Tag>
                                            </Space>
                                        }
                                    >
                                        <Descriptions.Item label="From">
                                            <Space>
                                                <HighlightedAddress address={interaction.spender?.address} />
                                                {interaction.spender?.address_label && (
                                                    <Tag color="blue">{interaction.spender?.address_label}</Tag>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Contract">
                                            <Space>
                                                {interaction.token_logo && (
                                                    <Tooltip title={interaction.token?.address_label || interaction.token?.name}>
                                                        <Avatar size="small" src={interaction.token_logo} />
                                                    </Tooltip>
                                                )}
                                                <HighlightedAddress address={interaction.token?.address} />
                                                {(interaction.token?.address_label || interaction.token?.name) && (
                                                    <Space size={4}>
                                                        {interaction.token?.address_label && (
                                                            <Tag color="blue">{interaction.token?.address_label}</Tag>
                                                        )}
                                                        {interaction.token?.name && (
                                                            <Tag color="purple">{interaction.token?.name}</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Interaction Details">
                                            <Space direction="vertical">
                                                <Space>
                                                    <Tag color={selectedSection.subType === 'approvals' ? 'orange' : 'cyan'}>
                                                        {(selectedSection.subType || '').charAt(0).toUpperCase() + (selectedSection.subType || '').slice(1).replace(/s$/, '')}
                                                    </Tag>
                                                    {interaction.method_name && (
                                                        <Text>Method: {interaction.method_name}</Text>
                                                    )}
                                                </Space>
                                                {selectedSection.subType === 'approvals' && interaction.spender && (
                                                    <Space direction="vertical" size={2}>
                                                        <Space>
                                                            <Text type="secondary">Spender:</Text>
                                                            {interaction.spender.entity_logo && (
                                                                <Tooltip title={interaction.spender.entity || interaction.spender.address_label}>
                                                                    <Avatar 
                                                                        size="small" 
                                                                        src={interaction.spender.entity_logo}
                                                                        style={{ backgroundColor: 'transparent' }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                            <HighlightedAddress address={interaction.spender.address} />
                                                        </Space>
                                                        {(interaction.spender.address_label || interaction.spender.entity) && (
                                                            <Space size={4}>
                                                                {interaction.spender.address_label && (
                                                                    <Tag color="blue">{interaction.spender.address_label}</Tag>
                                                                )}
                                                                {interaction.spender.entity && (
                                                                    <Tag color="purple">{interaction.spender.entity}</Tag>
                                                                )}
                                                            </Space>
                                                        )}
                                                    </Space>
                                                )}
                                                {interaction.value && (
                                                    <Space>
                                                        <Text type="secondary">Value:</Text>
                                                        <Text>{interaction.value_formatted || interaction.value}</Text>
                                                        {interaction.token_symbol && (
                                                            <Text type="secondary">{interaction.token_symbol}</Text>
                                                        )}
                                                    </Space>
                                                )}
                                                {interaction.verified_contract && (
                                                    <Tag color="green">Verified Contract</Tag>
                                                )}
                                            </Space>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </List.Item>
                            )}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    // Add new state for the JSON modal
    const [jsonModalData, setJsonModalData] = useState<any>(null);

    // Add the JSON modal
    const JsonModal = () => (
        <Modal
            title="Full Transaction Details"
            open={!!jsonModalData}
            onCancel={() => setJsonModalData(null)}
            width={800}
            footer={[
                <Button key="close" onClick={() => setJsonModalData(null)}>
                    Close
                </Button>
            ]}
        >
            <pre style={{ 
                maxHeight: '70vh', 
                overflow: 'auto', 
                padding: '12px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px'
            }}>
                {JSON.stringify(jsonModalData, null, 2)}
            </pre>
        </Modal>
    );

    const chainColumn: TableColumnType<any> = {
        title: 'Chain',
        dataIndex: 'chain_id',
        key: 'chain_id',
        width: 120,
        filters: Object.entries(chainIdToName)
            .filter(([chainId]) => {
                return record?.transactions?.some((item: any) => 
                    item.chain_id?.toString() === chainId
                );
            })
            .map(([chainId, name]) => ({
                text: (
                    <Space>
                        <Tooltip title={`Chain ID: ${chainId}`}>
                            <Avatar 
                                size="small" 
                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                style={{ backgroundColor: 'transparent' }}
                        />
                        </Tooltip>
                        <span>{name}</span>
                    </Space>
                ),
                value: chainId,
            })),
        filterDropdown: (props: FilterDropdownProps) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
            
            const filterOptions = Object.entries(chainIdToName)
                .filter(([chainId]) => {
                    return transactions?.some((item: any) => 
                        item.chain_id?.toString() === chainId
                    );
                })
                .map(([chainId, name]) => ({
                    text: name,
                    value: chainId,
                    icon: `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`,
                    chainId: chainId, // Add chainId to the options
                }));

            const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    setSelectedKeys(filterOptions.map(option => option.value));
                } else {
                    setSelectedKeys([]);
                }
            };

            return (
                <div style={{ padding: 8 }}>
                    <Checkbox
                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                        checked={selectedKeys.length === filterOptions.length}
                        onChange={(e) => handleSelectAll(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                        style={{ marginBottom: 8 }}
                    >
                        Select All
                    </Checkbox>
                    <Divider style={{ margin: '8px 0' }} />
                    {filterOptions.map((option, index) => (
                        <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                            <Checkbox
                                checked={selectedKeys.includes(option.value)}
                                onChange={(e) => {
                                    const newSelectedKeys = e.target.checked
                                        ? [...selectedKeys, option.value]
                                        : selectedKeys.filter(key => key !== option.value);
                                    setSelectedKeys(newSelectedKeys);
                                }}
                            >
                                <Space>
                                    <Tooltip title={`Chain ID: ${option.chainId}`}>
                                        <Avatar 
                                            size="small" 
                                            src={option.icon}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Tooltip>
                                    <span>{option.text}</span>
                                </Space>
                            </Checkbox>
                        </div>
                    ))}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            size="small"
                        >
                            Apply
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters?.();
                                confirm();
                            }}
                            size="small"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            );
        },
        onFilter: (value, record) => record.chain_id === value,
        sorter: (a, b) => {
            const chainA = chainIdToName[a.chain_id] || a.chain_id;
            const chainB = chainIdToName[b.chain_id] || b.chain_id;
            return chainA.localeCompare(chainB);
        },
        render: (chainId: number) => (
            <Tooltip title={chainIdToName[chainId] || `Chain ${chainId}`}>
                <Space>
                    <Avatar 
                        size="large" 
                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`} 
                        style={{ backgroundColor: 'transparent' }}
                    />
                    <span>{chainIdToName[chainId] || chainId}</span>
                </Space>
            </Tooltip>
        ),
    };

    const methodColumn: TableColumnType<any> = {
        title: 'Method',
        dataIndex: 'method_label',
        key: 'method_label',
        width: 200,
        filters: Array.from(new Set(transactions
            ?.map((item: any) => item.method_label)
            .filter(Boolean)))
            .map(method => ({ text: method, value: method })),
        filterDropdown: (props: FilterDropdownProps) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
            const filterOptions = Array.from(new Set(transactions
                ?.map((item: any) => item.method_label)
                .filter(Boolean)))
                .map(method => ({ text: method, value: method }));

            const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    setSelectedKeys(filterOptions.map(option => option.value));
                } else {
                    setSelectedKeys([]);
                }
            };

            return (
                <div style={{ padding: 8 }}>
                    <Checkbox
                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                        checked={selectedKeys.length === filterOptions.length}
                        onChange={(e) => handleSelectAll(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                        style={{ marginBottom: 8 }}
                    >
                        Select All
                    </Checkbox>
                    <Divider style={{ margin: '8px 0' }} />
                    {filterOptions.map((option, index) => (
                        <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                            <Checkbox
                                checked={selectedKeys.includes(option.value)}
                                onChange={(e) => {
                                    const newSelectedKeys = e.target.checked
                                        ? [...selectedKeys, option.value]
                                        : selectedKeys.filter(key => key !== option.value);
                                    setSelectedKeys(newSelectedKeys);
                                }}
                            >
                                <Tag color="gold">{option.text}</Tag>
                            </Checkbox>
                        </div>
                    ))}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            size="small"
                        >
                            Apply
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters?.();
                                confirm();
                            }}
                            size="small"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            );
        },
        onFilter: (value, record) => record.method_label === value,
        sorter: (a, b) => (a.method_label || '').localeCompare(b.method_label || ''),
        render: (method: string) => method ? (
            <div style={{ minWidth: 200 }}>
                <Tag color="gold">
                    {method}
                </Tag>
            </div>
        ) : <div style={{ minWidth: 200 }}></div>
    };

    const toColumn: TableColumnType<any> = {
        title: 'To',
        dataIndex: 'to_address',
        key: 'to_address',
        width: 300,
        filters: Array.from(new Set(transactions
            ?.map((item: any) => item.to_address_label || item.to_address_entity)
            .filter(Boolean)))
            .map(label => {
                // Find the first item with this label to get its logo
                const item = transactions?.find((i: any) => 
                    (i.to_address_label || i.to_address_entity) === label
                );
                return {
                    text: label,
                    value: label,
                    logo: item?.to_address_entity_logo
                };
            }),
        filterDropdown: (props: FilterDropdownProps) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
            
            // Get unique labels with their logos
            const uniqueOptions = new Map();
            transactions?.forEach((item: any) => {
                const label = item.to_address_label || item.to_address_entity;
                if (label) {
                    uniqueOptions.set(label, {
                        text: label,
                        value: label,
                        logo: item.to_address_entity_logo
                    });
                }
            });

            const filterOptions = Array.from(uniqueOptions.values());

            const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                    setSelectedKeys(filterOptions.map(option => option.value));
                } else {
                    setSelectedKeys([]);
                }
            };

            return (
                <div style={{ padding: 8 }}>
                    <Checkbox
                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                        checked={selectedKeys.length === filterOptions.length}
                        onChange={(e) => handleSelectAll(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                        style={{ marginBottom: 8 }}
                    >
                        Select All
                    </Checkbox>
                    <Divider style={{ margin: '8px 0' }} />
                    {filterOptions.map((option, index) => (
                        <div key={`${option.value}-${index}`}  style={{ marginBottom: 8 }}>
                            <Checkbox
                                checked={selectedKeys.includes(option.value)}
                                onChange={(e) => {
                                    const newSelectedKeys = e.target.checked
                                        ? [...selectedKeys, option.value]
                                        : selectedKeys.filter(key => key !== option.value);
                                    setSelectedKeys(newSelectedKeys);
                                }}
                            >
                                <Space>
                                    <Tooltip title={`${option.text}`}>
                                        <Avatar 
                                            size="small" 
                                            src={option.logo}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Tooltip>
                                    <span>{option.text}</span>
                                </Space>
                            </Checkbox>
                        </div>
                    ))}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            size="small"
                        >
                            Apply
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters?.();
                                confirm();
                            }}
                            size="small"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            );
        },
        onFilter: (value, record) => record.to_address_label === value || record.to_address_entity === value,
        sorter: (a, b) => {
            const labelA = a.to_address_label || a.to_address_entity || a.to_address || '';
            const labelB = b.to_address_label || b.to_address_entity || b.to_address || '';
            return labelA.localeCompare(labelB);
        },
        render: (address: string, record: any) => {
            const truncatedAddress = `${address?.slice(0, 6)}...${address?.slice(-6)}`;
            return (
                <Descriptions column={1} size="small" style={{ margin: 0, minWidth: 280 }}>
                    <Descriptions.Item>
                        <Space>
                            {record.to_address_entity_logo && (
                                <Tooltip title={record.to_address_entity || record.to_address_label}>
                                    <Avatar size="small" src={record.to_address_entity_logo} />
                                </Tooltip>
                            )}
                            <Tooltip title={address}>
                                <Typography.Text copyable={{ text: address }} style={{ whiteSpace: 'nowrap' }}>
                                    {truncatedAddress}
                                </Typography.Text>
                            </Tooltip>
                        </Space>
                    </Descriptions.Item>
                    {(record.to_address_label || record.to_address_entity) && (
                        <Descriptions.Item>
                            <Space size={4} wrap>
                                {record.to_address_label && (
                                    <Tag 
                                        color="blue"
                                        style={{ 
                                            maxWidth: '100%',
                                            whiteSpace: 'normal',
                                            height: 'auto',
                                            padding: '2px 8px',
                                            lineHeight: '1.5'
                                        }}
                                    >
                                        {record.to_address_label}
                                    </Tag>
                                )}
                                {record.to_address_entity && (
                                    <Tag 
                                        color="purple"
                                        style={{ 
                                            maxWidth: '100%',
                                            whiteSpace: 'normal',
                                            height: 'auto',
                                            padding: '2px 8px',
                                            lineHeight: '1.5'
                                        }}
                                    >
                                        {record.to_address_entity}
                                    </Tag>
                                )}
                            </Space>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            );
        }
    };

    // Add before the return statement
    const columnVisibilityMenu = (
        <div style={{ padding: '12px', minWidth: '200px', background: 'white' }}>
            <div style={{ marginBottom: '8px' }}>
                <Checkbox
                    indeterminate={visibleColumns.length > 0 && visibleColumns.length < 10}
                    checked={visibleColumns.length === 10}
                    onChange={(e) => {
                        setVisibleColumns(e.target.checked 
                            ? ['chain_id', 'hash', 'method_label', 'summary', 'from_address', 
                               'to_address', 'value', 'block_timestamp', 'found_in', 'actions']
                            : []);
                    }}
                >
                    Show All
                </Checkbox>
            </div>
            <Divider style={{ margin: '8px 0' }} />
            <Space direction="vertical">
                <Checkbox
                    checked={visibleColumns.includes('chain_id')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'chain_id']
                        : visibleColumns.filter(key => key !== 'chain_id'))}
                >
                    Chain
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('hash')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'hash']
                        : visibleColumns.filter(key => key !== 'hash'))}
                >
                    Hash
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('method_label')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'method_label']
                        : visibleColumns.filter(key => key !== 'method_label'))}
                >
                    Method
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('summary')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'summary']
                        : visibleColumns.filter(key => key !== 'summary'))}
                >
                    Summary
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('from_address')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'from_address']
                        : visibleColumns.filter(key => key !== 'from_address'))}
                >
                    From
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('to_address')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'to_address']
                        : visibleColumns.filter(key => key !== 'to_address'))}
                >
                    To
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('value')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'value']
                        : visibleColumns.filter(key => key !== 'value'))}
                >
                    Value
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('block_timestamp')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'block_timestamp']
                        : visibleColumns.filter(key => key !== 'block_timestamp'))}
                >
                    Block Time
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('found_in')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'found_in']
                        : visibleColumns.filter(key => key !== 'found_in'))}
                >
                    Found In
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.includes('actions')}
                    onChange={(e) => setVisibleColumns(e.target.checked 
                        ? [...visibleColumns, 'actions']
                        : visibleColumns.filter(key => key !== 'actions'))}
                >
                    Actions
                </Checkbox>
            </Space>
        </div>
    );

    // Add these state and ref declarations at the top of your component
    const [images, setImages] = useState<Array<HTMLImageElement>>();
    const fgRef = useRef<any>();

    const [filteredData, setFilteredData] = useState<any[]>([]);

    // Add this function to transform table data to graph data
    const graphDataNew = useMemo(() => {
        // Safe string operation helpers
        const safeString = (value: any): string => {
            if (!value) return '';
            if (typeof value === 'string') return value;
            if (typeof value === 'number') return value.toString();
            return '';
        };

        const safeSlice = (str: any, start: number, end?: number): string => {
            const safeStr = safeString(str);
            if (!safeStr) return 'N/A';
            try {
                return end ? safeStr.slice(start, end) : safeStr.slice(start);
            } catch (error) {
                console.warn('Error in safeSlice:', error);
                return 'N/A';
            }
        };

        const formatAddress = (address: any): string => {
            const start = safeSlice(address, 0, 6);
            const end = safeSlice(address, -4);
            return start === 'N/A' || end === 'N/A' ? 'N/A' : `${start}...${end}`;
        };

        const formatValue = (value: any): string => {
            try {
                if (!value) return '0';
                return value.toString();
            } catch (error) {
                console.warn('Error in formatValue:', error);
                return '0';
            }
        };

        // Define helper function first
        const getLinkCurvature = (source: string, target: string, linkIndex: number, totalLinks: number) => {
            if (totalLinks <= 1) return 0; // Straight line if only one link
            
            // Calculate curvature based on number of links
            const baseStep = 0.2;
            const middleIndex = Math.floor(totalLinks / 2);
            const offset = linkIndex - middleIndex;
            return offset * baseStep;
        };

        // Helper function to safely truncate addresses
        const truncateAddress = (address: string | undefined | null) => {
            if (!address || typeof address !== 'string') return 'N/A';
            try {
                return `${address.substring(0, 6)}...${address.substring(address.length - 6)}`;
            } catch (error) {
                console.warn('Error truncating address:', error);
                return 'N/A';
            }
        };

        // Helper function to safely get label or address
        const getLabelOrAddress = (label: string | undefined | null, address: string | undefined | null) => {
            if (label) return label;
            return truncateAddress(address);
        };

        // Helper function to safely format transaction hash
        const formatTxHash = (hash: string | undefined | null) => {
            if (!hash || typeof hash !== 'string') return 'N/A';
            try {
                return `${hash.substring(0, 10)}...`;
            } catch (error) {
                console.warn('Error formatting transaction hash:', error);
                return 'N/A';
            }
        };

        // Helper function to format chain ID
        const formatChainId = (chainId: string | undefined | null) => {
            if (!chainId) return '0x1'; // default to Ethereum
            // Remove '0x' prefix if it exists and ensure lowercase
            return chainId.toLowerCase().startsWith('0x') ? chainId.toLowerCase() : `0x${chainId.toLowerCase()}`;
        };

        const ensureNodeExists = (address: string, chainId: string, entityLogo?: string, transactionItem?: any) => {
            const nodeId = `${chainId}_${address}`;
            if (!nodeMap.has(nodeId)) {
                if (!chainNodeCounts[chainId as keyof typeof chainNodeCounts]) {
                    chainNodeCounts[chainId as keyof typeof chainNodeCounts] = 0;
                }
                
                const nodeCount = chainNodeCounts[chainId as keyof typeof chainNodeCounts];
                const row = Math.floor(nodeCount / nodesPerRow);
                const col = nodeCount % nodesPerRow;
                
                const isEthereumChain = chainId === '0x1';
                const baseX = isEthereumChain ? 200 + (col * 150) : 800 + (col * 150);
                const baseY = 200 + (row * verticalSpacing);
                const randomOffset = () => (Math.random() - 0.5) * 10;  
                // Get label and entity from main transaction
                let label = undefined;
                let entity = undefined;
                
                if (transactionItem) {
                    // Check main transaction
                    if (transactionItem.from_address === address) {
                        label = transactionItem.from_address_label;
                        entity = transactionItem.from_address_entity;
                    } else if (transactionItem.to_address === address) {
                        label = transactionItem.to_address_label;
                        entity = transactionItem.to_address_entity;
                    }
                    
                    // Check native transfers
                    if (!label && transactionItem.native_transfers) {
                        const transfer = transactionItem.native_transfers.find(
                            (t: any) => t.from_address === address || t.to_address === address
                        );
                        if (transfer) {
                            if (transfer.from_address === address) {
                                label = transfer.from_address_label;
                                entity = transfer.from_address_entity;
                            } else {
                                label = transfer.to_address_label;
                                entity = transfer.to_address_entity;
                            }
                        }
                    }
                    
                    // Check ERC20 transfers
                    if (!label && transactionItem.erc20_transfers) {
                        const transfer = transactionItem.erc20_transfers.find(
                            (t: any) => t.from_address === address || t.to_address === address
                        );
                        if (transfer) {
                            if (transfer.from_address === address) {
                                label = transfer.from_address_label;
                                entity = transfer.from_address_entity;
                            } else {
                                label = transfer.to_address_label;
                                entity = transfer.to_address_entity;
                            }
                        }
                    }

                    // Check NFT transfers
                    if (!label && transactionItem.nft_transfers) {
                        const transfer = transactionItem.nft_transfers.find(
                            (t: any) => t.from_address === address || t.to_address === address
                        );
                        if (transfer) {
                            if (transfer.from_address === address) {
                                label = transfer.from_address_label;
                                entity = transfer.from_address_entity;
                            } else {
                                label = transfer.to_address_label;
                                entity = transfer.to_address_entity;
                            }
                        }
                    }

                    // Check contract interactions
                    if (!label && transactionItem.contract_interactions) {
                        const interaction = transactionItem.contract_interactions.find(
                            (t: any) => t.from_address === address || t.to_address === address
                        );
                        if (interaction) {
                            if (interaction.from_address === address) {
                                label = interaction.from_address_label;
                                entity = interaction.from_address_entity;
                            } else {
                                label = interaction.to_address_label;
                                entity = interaction.to_address_entity;
                            }
                        }
                    }

                    // Check internal transactions
                    if (!label && transactionItem.internal_transactions) {
                        const internalTx = transactionItem.internal_transactions.find(
                            (t: any) => t.from === address || t.to === address
                        );
                        if (internalTx) {
                            if (internalTx.from === address) {
                                label = internalTx.from_address_label;
                                entity = internalTx.from_address_entity;
                            } else {
                                label = internalTx.to_address_label;
                                entity = internalTx.to_address_entity;
                            }
                        }
                    }
                }

                nodeMap.set(nodeId, {
                    id: nodeId,
                    chain: chainIdToName[chainId] || 'Unknown Chain',
                    friendly_name: address,
                    x: baseX + randomOffset(),
                    y: baseY + randomOffset(),
                    fx: baseX + randomOffset(),
                    fy: baseY + randomOffset(),
                    source: entityLogo || DEFAULT_NODE_IMAGE,
                    chain_id: chainId,
                    color: "#a6cee3",
                    label,
                    entity
                });
                
                chainNodeCounts[chainId as keyof typeof chainNodeCounts]++;
            }
            return nodeId;
        };
        
        const dataToUse = filteredData.length > 0 ? filteredData : transactions || [];
        
        // Initialize empty result structure
        const result = {
            nodes: [],
            links: [],
            chainGroups: []
        };

        if (!dataToUse || dataToUse.length === 0) {
            console.log("No data to process");
            return result;
        }

        const nodeMap = new Map();
        const links: {
            id: string; 
            type: string; 
            source: string; 
            target: string; 
            value: any; 
            linkLabel: any; 
            linkColor: string; 
            arraySize: number; 
            curved: number; 
            token_symbol?: string;
            token_name?: string;
            direction?: string;
            security_score?: string | number;
            verified_contract?: boolean;
            contract_type?: string;
            token_id?: string;
            amount?: string;
            verified_collection?: boolean;
            possible_spam?: boolean;
            // Contract interaction specific
            method_name?: string;
            type_label?: string;
            spender?: string;
        }[] = [];
        const linkCounts = new Map<string, number>();
        const currentLinkIndices = new Map<string, number>();

        // Track node counts per chain for positioning
        const chainNodeCounts = {
            "0x1": 0,  // Ethereum
            "0x89": 0  // Polygon
        };
        
        // First pass - collect unique nodes and chain info
        dataToUse.forEach((item, index) => {
            if (!item || !item.chain_id) return;
            
            const chainId = item.chain_id;
            const chainName = chainIdToName[chainId] || 'Unknown Chain';
            
            // Process addresses and create nodes
            [
                { address: item.from_address, type: 'from' },
                { address: item.to_address, type: 'to' }
            ].forEach(({ address, type }) => {
                if (!address) return;
                
                const nodeId = `${chainId}_${address}`;
                
                if (!nodeMap.has(nodeId)) {
                    // Separate counters for each chain
                    if (!(chainId in chainNodeCounts)) {
                        chainNodeCounts[chainId as keyof typeof chainNodeCounts] = 0;
                    }
                    
                    const nodeCount = chainNodeCounts[chainId as keyof typeof chainNodeCounts];
                    const row = Math.floor(nodeCount / nodesPerRow);
                    const col = nodeCount % nodesPerRow;
                    
                    // Base X positions - use strict equality comparison
                    const isEthereumChain = chainId === '0x1';
                    const baseX = isEthereumChain
                        ? 200 + (col * 150)  // Ethereum: 200, 350, 500, 650
                        : 800 + (col * 150); // Polygon: 800, 950, 1100, 1250, 1400
                    
                    const baseY = 200 + (row * verticalSpacing);
                    const randomOffset = () => (Math.random() - 0.5) * 10;

                    const node = {
                        id: nodeId,
                        chain: chainName,
                        friendly_name: address,
                        x: baseX + randomOffset(),
                        y: baseY + randomOffset(),
                        fx: baseX + randomOffset(),
                        fy: baseY + randomOffset(),
                        // Get logo from address labels
                        source: item?.from_address === address 
                            ? item?.from_address_entity_logo 
                            : item?.to_address === address 
                                ? item?.to_address_entity_logo 
                                : "https://cdn.jsdelivr.net/npm/cryptocurrency-icons/svg/color/generic.svg",
                        chain_id: chainId,
                        color: "#a6cee3",
                        // Add label and entity information
                        label: item?.from_address === address 
                            ? item?.from_address_label 
                            : item?.to_address === address 
                                ? item?.to_address_label 
                                : undefined,
                        entity: item?.from_address === address 
                            ? item?.from_address_entity 
                            : item?.to_address === address 
                                ? item?.to_address_entity 
                                : undefined
                    };
                    
                    nodeMap.set(nodeId, node);
                    
                    // Increment counter for this specific chain
                    chainNodeCounts[chainId as keyof typeof chainNodeCounts]++;
                }
            });

            // Create link if both addresses exist
            if (item.from_address && item.to_address) {
                links.push({
                    id: `${chainId}_${item.hash}_transfer_${item.from_address}_${item.to_address}`,
                    type: 'transfer',   
                    source: `${chainId}_${item.from_address}`,
                    target: `${chainId}_${item.to_address}`,
                    value: item.value || "0",
                    linkLabel: item.method_name || "transfer",
                    linkColor: "#999",
                    arraySize: 3,
                    curved: 0.2
                });
            }

            // Track chain groups
            if (!chainGroups.has(chainId)) {
                chainGroups.set(chainId, {
                    x: chainId === '0x1' ? 200 : 800,
                    y: 200,
                    width: 400,
                    height: 800,
                    name: chainName,
                    chain_id: chainId
                });
            }
        });

        // Process internal transactions
        dataToUse.forEach(item => {
            const chainId = formatChainId(item.chain_id);
            if (item.internal_transactions?.length > 0) {
                item.internal_transactions?.forEach((tx: any) => {
                    if (!tx?.from || !tx?.to) return;

                    
                    ensureNodeExists(tx.from, chainId, item?.from_address_entity_logo, item);
                    ensureNodeExists(tx.to, chainId, item?.to_address_entity_logo, item);                    
                    const linkKey = [tx.from, tx.to].sort().join('_');
                    const totalLinks = linkCounts.get(linkKey) || 1;
                    const currentIndex = currentLinkIndices.get(linkKey) || 0;

                    try {
                        links.push({
                            id: `${chainId}_${item.hash}_internal_${tx.from}_${tx.to}`,
                            type: 'internal',
                            source: `${chainId}_${tx.from}`,
                            target: `${chainId}_${tx.to}`,
                            value: tx.value || '0',
                            linkLabel: `Internal Transaction (${tx.type || 'N/A'})\n` +
                                `Value: ${tx.value ? tx.value : '0'} ETH\n` +
                                `Gas: ${tx.gas || '0'} (${tx.gas_used || '0'} used)\n` +
                                `Block: ${tx.block_number || 'N/A'}\n` +
                                `Tx Hash: ${formatTxHash(tx.transaction_hash)}`,
                            linkColor: "#9c27b0",
                            arraySize: 3,
                            curved: getLinkCurvature(tx.from, tx.to, currentIndex, totalLinks)
                        });
                    } catch (error) {
                        console.error('Error creating internal transaction link:', error);
                    }

                    currentLinkIndices.set(linkKey, currentIndex + 1);
                });
            }

        // Process native transfers
        if (item.native_transfers?.length > 0) {
            item.native_transfers.forEach((transfer: any) => {
                if (!transfer?.from_address || !transfer?.to_address) return;

                ensureNodeExists(transfer.from_address, chainId, transfer.from_address_entity_logo, item);
                ensureNodeExists(transfer.to_address, chainId, transfer.to_address_entity_logo, item);
                
                const linkKey = [transfer.from_address, transfer.to_address].sort().join('_');
                const totalLinks = linkCounts.get(linkKey) || 1;
                const currentIndex = currentLinkIndices.get(linkKey) || 0;

                try {
                    links.push({
                        id: `${chainId}_${item.hash}_native_${transfer.from_address}_${transfer.to_address}`,
                        type: 'native',
                        source: `${chainId}_${transfer.from_address}`,
                        target: `${chainId}_${transfer.to_address}`,
                        value: transfer.value_formatted || '0',
                        token_symbol: transfer.token_symbol,
                        direction: transfer.direction,
                        linkLabel: `Native Transfer\n` +
                            `Value: ${transfer.value_formatted || '0'} ${transfer.token_symbol}\n` +
                            `Direction: ${transfer.direction || 'N/A'}`,
                        linkColor: "#ff9800",
                        arraySize: 3,
                        curved: getLinkCurvature(transfer.from_address, transfer.to_address, currentIndex, totalLinks)
                    });
                } catch (error) {
                    console.error('Error creating native transfer link:', error);
                }

                currentLinkIndices.set(linkKey, currentIndex + 1);
            });
        }

        // Process ERC20 transfers
        if (item.erc20_transfers?.length > 0) {
            item.erc20_transfers.forEach((transfer: any) => {
                if (!transfer?.from_address || !transfer?.to_address) return;

                ensureNodeExists(transfer.from_address, chainId, transfer.from_address_entity_logo, item);
                ensureNodeExists(transfer.to_address, chainId, transfer.to_address_entity_logo, item);
                
                const linkKey = [transfer.from_address, transfer.to_address].sort().join('_');
                const totalLinks = linkCounts.get(linkKey) || 1;
                const currentIndex = currentLinkIndices.get(linkKey) || 0;

                try {
                    links.push({
                        id: `${chainId}_${item.hash}_erc20_${transfer.from_address}_${transfer.to_address}`,
                        type: 'erc20',
                        source: `${chainId}_${transfer.from_address}`,
                        target: `${chainId}_${transfer.to_address}`,
                        value: transfer.value_formatted || '0',
                        token_symbol: transfer.token_symbol,
                        token_name: transfer.token_name,
                        direction: transfer.direction,
                        security_score: transfer.security_score,
                        verified_contract: transfer.verified_contract,
                        linkLabel: `ERC20 Transfer\n` +
                            `Token: ${transfer.token_name} (${transfer.token_symbol})\n` +
                            `Value: ${transfer.value_formatted || '0'} ${transfer.token_symbol}\n` +
                            `Direction: ${transfer.direction || 'N/A'}\n` +
                            `Security Score: ${transfer.security_score || 'N/A'}`,
                        linkColor: "#2196f3",
                        arraySize: 3,
                        curved: getLinkCurvature(transfer.from_address, transfer.to_address, currentIndex, totalLinks)
                    });
                } catch (error) {
                    console.error('Error creating ERC20 transfer link:', error);
                }

                currentLinkIndices.set(linkKey, currentIndex + 1);
            });
        }

        // Process NFT transfers
        if (item.nft_transfers?.length > 0) {
            item.nft_transfers.forEach((transfer: any) => {
                if (!transfer?.from_address || !transfer?.to_address) return;

                ensureNodeExists(transfer.from_address, chainId, transfer.from_address_entity_logo, item);
                ensureNodeExists(transfer.to_address, chainId, transfer.to_address_entity_logo, item);
                
                const linkKey = [transfer.from_address, transfer.to_address].sort().join('_');
                const totalLinks = linkCounts.get(linkKey) || 1;
                const currentIndex = currentLinkIndices.get(linkKey) || 0;

                try {
                    links.push({
                        id: `${chainId}_${item.hash}_nft_${transfer.from_address}_${transfer.to_address}`,
                        type: 'nft',
                        source: `${chainId}_${transfer.from_address}`,
                        target: `${chainId}_${transfer.to_address}`,
                        contract_type: transfer.contract_type,
                        token_id: transfer.token_id,
                        amount: transfer.amount,
                        direction: transfer.direction,
                        verified_collection: transfer.verified_collection,
                        possible_spam: transfer.possible_spam,
                        linkLabel: `NFT Transfer\n` +
                            `Type: ${transfer.contract_type || 'N/A'}\n` +
                            `Token ID: ${transfer.token_id || 'N/A'}\n` +
                            `Amount: ${transfer.amount || '1'}\n` +
                            `${transfer.verified_collection ? '✓ Verified Collection\n' : ''}` +
                            `${transfer.possible_spam ? '⚠ Possible Spam' : ''}`,
                        linkColor: "#e91e63",
                        arraySize: 3,
                        curved: getLinkCurvature(transfer.from_address, transfer.to_address, currentIndex, totalLinks),
                        value: transfer.amount,
                    });
                } catch (error) {
                    console.error('Error creating NFT transfer link:', error);
                }

                currentLinkIndices.set(linkKey, currentIndex + 1);
            });
        }        
        
        // Process contract interactions
        if (item.contract_interactions?.length > 0) {
            item.contract_interactions.forEach((interaction: any) => {
                if (!interaction?.from_address || !interaction?.to_address) return;

                ensureNodeExists(interaction.from_address, chainId, interaction.from_address_entity_logo, item);
                ensureNodeExists(interaction.to_address, chainId, interaction.to_address_entity_logo, item);
                
                const linkKey = [interaction.from_address, interaction.to_address].sort().join('_');
                const totalLinks = linkCounts.get(linkKey) || 1;
                const currentIndex = currentLinkIndices.get(linkKey) || 0;

                try {
                    links.push({
                        id: `${chainId}_${item.hash}_contract_${interaction.from_address}_${interaction.to_address}`,
                        type: 'contract',
                        source: `${chainId}_${interaction.from_address}`,
                        target: `${chainId}_${interaction.to_address}`,
                        method_name: interaction.method_name,
                        value: interaction.value_formatted || '0',
                        token_symbol: interaction.token_symbol,
                        verified_contract: interaction.verified_contract,
                        type_label: interaction.type === 'approval' ? 'Approval' : 'Method Call',
                        spender: interaction.spender,
                        linkLabel: `Contract ${interaction.type === 'approval' ? 'Approval' : 'Method Call'}\n` +
                            `${interaction.method_name ? `Method: ${interaction.method_name}\n` : ''}` +
                            `${interaction.value ? `Value: ${interaction.value_formatted || interaction.value} ${interaction.token_symbol || ''}\n` : ''}` +
                            `${interaction.spender ? `Spender: ${interaction.spender}\n` : ''}` +
                            `${interaction.verified_contract ? '✓ Verified Contract' : ''}`,
                        linkColor: interaction.type === 'approval' ? "#ff9800" : "#00bcd4",
                        arraySize: 3,
                        curved: getLinkCurvature(interaction.from_address, interaction.to_address, currentIndex, totalLinks)
                    });
                } catch (error) {
                    console.error('Error creating contract interaction link:', error);
                }

                currentLinkIndices.set(linkKey, currentIndex + 1);
            });
        }
    });
     

        return {
            nodes: Array.from(nodeMap.values()),
            links,
            chainGroups: Array.from(chainGroups.values())
        };
    }, [transactions, filteredData, chainIdToName]);

    // Add useEffect for image loading
    useEffect(() => {
        if (!images && graphDataNew.nodes.length > 0) {
            // Load images for nodes
            const nodeImages = graphDataNew.nodes
                .map(node => node.source)
                .filter(Boolean);
            
            const nodeImagesUnique = Array.from(new Set(nodeImages));
            
            const newImages = nodeImagesUnique.map(image => {
                const img = new Image();
                img.src = image;
                return img;
            });
            setImages(newImages);
        }
    }, [graphDataNew, images]);

    // Add this state
    const [graphKey, setGraphKey] = useState(0);

    const handleGraphLayout = (focusChainId?: number, resetZoom?: boolean) => {
        // Only proceed if explicitly called with a chain ID or through the center button
        if (!fgRef.current || !graphDataNew.nodes.length) return;
        if (!resetZoom && focusChainId === undefined) return;
        
        const nodes = graphDataNew.nodes;
        const visibleChains = Array.from(chainGroups.values());
        
        // Get actual container dimensions
        const containerEl = document.querySelector('.force-graph-container');
        const containerWidth = containerEl?.clientWidth || 1920;
        const containerHeight = containerEl?.clientHeight || 844;
        
        // Minimal padding
        const paddingX = 10;
        const paddingY = 20;
        
        // If focusing on a specific chain
        if (focusChainId !== undefined) {
            const chain = visibleChains.find(c => c.chain_id === focusChainId);
            if (chain) {
                const chainNodes = nodes.filter(n => n.chainId === focusChainId);
                
                const minX = Math.min(chain.x, ...chainNodes.map(n => n.x || 0));
                const maxX = Math.max(chain.x + chain.width, ...chainNodes.map(n => n.x || 0));
                const minY = Math.min(chain.y, ...chainNodes.map(n => n.y || 0));
                const maxY = Math.max(chain.y + chain.height, ...chainNodes.map(n => n.y || 0));
                
                // Add padding
                const paddedMinX = minX - paddingX;
                const paddedMaxX = maxX + paddingX;
                const paddedMinY = minY - paddingY;
                const paddedMaxY = maxY + paddingY;
                
                const width = paddedMaxX - paddedMinX;
                const height = paddedMaxY - paddedMinY;
                
                // Calculate zoom for single chain
                const widthRatio = containerWidth / width;
                const heightRatio = containerHeight / height;
                const targetZoom = Math.min(widthRatio, heightRatio) * 1.2;
                const zoom = Math.max(0.5, Math.min(targetZoom, 1.2));
                
                console.log('Chain focus zoom:', {
                    chainId: focusChainId,
                    chainName: chain.name,
                    contentWidth: width,
                    contentHeight: height,
                    nodeCount: chainNodes.length,
                    targetZoom,
                    finalZoom: zoom
                });
                
                const centerX = (paddedMinX + paddedMaxX) / 2;
                const centerY = (paddedMinY + paddedMaxY) / 2;
                
                // Center and zoom on the specific chain
                fgRef.current.centerAt(centerX, centerY, 1000);
                setTimeout(() => {
                    if (fgRef.current) {
                        fgRef.current.zoom(zoom, 1000);
                    }
                }, 50);
                
                return;
            }
        }
        
        // Default layout for all visible chains
        const minX = Math.min(
            Math.min(...nodes.map(n => n.x || 0)),
            Math.min(...visibleChains.map(c => c.x))
        );
        
        const maxX = Math.max(
            Math.max(...nodes.map(n => n.x || 0)),
            Math.max(...visibleChains.map(c => c.x + c.width))
        );
        
        const minY = Math.min(
            Math.min(...nodes.map(n => n.y || 0)),
            Math.min(...visibleChains.map(c => c.y))
        );
        
        const maxY = Math.max(
            Math.max(...nodes.map(n => n.y || 0)),
            Math.max(...visibleChains.map(c => c.y + c.height))
        );
        
        // Add padding
        const paddedMinX = minX - paddingX;
        const paddedMaxX = maxX + paddingX;
        const paddedMinY = minY - paddingY;
        const paddedMaxY = maxY + paddingY;
        
        const width = paddedMaxX - paddedMinX;
        const height = paddedMaxY - paddedMinY;
        
        // Calculate zoom for all chains
        const widthRatio = containerWidth / width;
        const heightRatio = containerHeight / height;
        const targetZoom = Math.min(widthRatio, heightRatio) * 1.2;
        const zoom = Math.max(0.5, Math.min(targetZoom, 1.2));
        
        console.log('All chains zoom:', {
            contentWidth: width,
            contentHeight: height,
            nodeCount: nodes.length,
            targetZoom,
            finalZoom: zoom
        });
        
        const centerX = (paddedMinX + paddedMaxX) / 2;
        const centerY = (paddedMinY + paddedMaxY) / 2;
        
        // Center and zoom on all chains
        fgRef.current.centerAt(centerX, centerY, 1000);
        setTimeout(() => {
            if (fgRef.current) {
                fgRef.current.zoom(zoom, 1000);
            }
        }, 50);
    };

    const chainColors = {
        // Mainnets
        "0x1": {  // Ethereum
            fill: 'rgba(98, 126, 234, 0.1)',
            stroke: 'rgb(98, 126, 234)'
        },
        "0x89": {  // Polygon
            fill: 'rgba(130, 71, 229, 0.1)',
            stroke: 'rgb(130, 71, 229)'
        },
        "0x38": {  // BSC
            fill: 'rgba(240, 185, 11, 0.1)',
            stroke: 'rgb(240, 185, 11)'
        },
        "0xa86a": {  // Avalanche
            fill: 'rgba(232, 65, 66, 0.1)',
            stroke: 'rgb(232, 65, 66)'
        },
        "0xa": {  // Optimism
            fill: 'rgba(255, 4, 32, 0.1)',
            stroke: 'rgb(255, 4, 32)'
        },
        "0xa4b1": {  // Arbitrum
            fill: 'rgba(40, 160, 240, 0.1)',
            stroke: 'rgb(40, 160, 240)'
        },
        "0x64": {  // Gnosis
            fill: 'rgba(73, 169, 166, 0.1)',
            stroke: 'rgb(73, 169, 166)'
        },
        "0xfa": {  // Fantom
            fill: 'rgba(19, 181, 236, 0.1)',
            stroke: 'rgb(19, 181, 236)'
        },
        "0xa4ec": {  // Celo
            fill: 'rgba(53, 208, 127, 0.1)',
            stroke: 'rgb(53, 208, 127)'
        },
        "0x504": {  // Moonbeam
            fill: 'rgba(83, 77, 242, 0.1)',
            stroke: 'rgb(83, 77, 242)'
        },
        "0x505": {  // Moonriver
            fill: 'rgba(195, 58, 161, 0.1)',
            stroke: 'rgb(195, 58, 161)'
        },
        "0x19": {  // Cronos
            fill: 'rgba(18, 25, 38, 0.1)',
            stroke: 'rgb(18, 25, 38)'
        },
        "0x63564c40": {  // Harmony
            fill: 'rgba(0, 217, 187, 0.1)',
            stroke: 'rgb(0, 217, 187)'
        },
        "0x2019": {  // Klaytn
            fill: 'rgba(255, 102, 0, 0.1)',
            stroke: 'rgb(255, 102, 0)'
        },
        "0x8ae": {  // Kava
            fill: 'rgba(255, 87, 54, 0.1)',
            stroke: 'rgb(255, 87, 54)'
        },
        "0x144": {  // zkSync Era
            fill: 'rgba(140, 100, 255, 0.1)',
            stroke: 'rgb(140, 100, 255)'
        },
        "0xe708": {  // Linea
            fill: 'rgba(0, 0, 0, 0.1)',
            stroke: 'rgb(0, 0, 0)'
        },
        "0x2105": {  // Base
            fill: 'rgba(0, 82, 255, 0.1)',
            stroke: 'rgb(0, 82, 255)'
        },
        "0x82750": {  // Scroll
            fill: 'rgba(68, 146, 255, 0.1)',
            stroke: 'rgb(68, 146, 255)'
        },

        // Testnets
        "0x5": {  // Goerli (ETH)
            fill: 'rgba(98, 126, 234, 0.05)',
            stroke: 'rgba(98, 126, 234, 0.7)'
        },
        "0xaa36a7": {  // Sepolia (ETH)
            fill: 'rgba(98, 126, 234, 0.05)',
            stroke: 'rgba(98, 126, 234, 0.7)'
        },
        "0x13881": {  // Mumbai (Polygon)
            fill: 'rgba(130, 71, 229, 0.05)',
            stroke: 'rgba(130, 71, 229, 0.7)'
        },
        "0x61": {  // BSC Testnet
            fill: 'rgba(240, 185, 11, 0.05)',
            stroke: 'rgba(240, 185, 11, 0.7)'
        },
        "0xa869": {  // Avalanche Fuji
            fill: 'rgba(232, 65, 66, 0.05)',
            stroke: 'rgba(232, 65, 66, 0.7)'
        },
        "0x1a4": {  // Optimism Goerli
            fill: 'rgba(255, 4, 32, 0.05)',
            stroke: 'rgba(255, 4, 32, 0.7)'
        },
        "0x66eed": {  // Arbitrum Goerli
            fill: 'rgba(40, 160, 240, 0.05)',
            stroke: 'rgba(40, 160, 240, 0.7)'
        },
        "0xfa2": {  // Fantom Testnet
            fill: 'rgba(19, 181, 236, 0.05)',
            stroke: 'rgba(19, 181, 236, 0.7)'
        },
        "0xaef3": {  // Celo Alfajores
            fill: 'rgba(53, 208, 127, 0.05)',
            stroke: 'rgba(53, 208, 127, 0.7)'
        },
        "0x507": {  // Moonbase Alpha
            fill: 'rgba(83, 77, 242, 0.05)',
            stroke: 'rgba(83, 77, 242, 0.7)'
        },
        "0x152": {  // Cronos Testnet
            fill: 'rgba(18, 25, 38, 0.05)',
            stroke: 'rgba(18, 25, 38, 0.7)'
        },
        "0x118": {  // zkSync Era Testnet
            fill: 'rgba(140, 100, 255, 0.05)',
            stroke: 'rgba(140, 100, 255, 0.7)'
        },
        "0xe704": {  // Linea Testnet
            fill: 'rgba(0, 0, 0, 0.05)',
            stroke: 'rgba(0, 0, 0, 0.7)'
        },
        "0x14a33": {  // Base Goerli
            fill: 'rgba(0, 82, 255, 0.05)',
            stroke: 'rgba(0, 82, 255, 0.7)'
        },
        "0x8274f": {  // Scroll Sepolia
            fill: 'rgba(68, 146, 255, 0.05)',
            stroke: 'rgba(68, 146, 255, 0.7)'
        }
    };
    
    // Initial load and data updates
    useEffect(() => {
        // Only run on initial load or when data actually changes
        if (!selectedNode) {  // Don't run if a node is selected
            handleGraphLayout(undefined, true);
        }
    }, [graphDataNew.nodes.length, chainGroups.size]); // Only watch for actual data changes

    // Handle window resizing
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            handleGraphLayout(undefined, true);
        });
        
        const containerEl = document.querySelector('.force-graph-container');
        if (containerEl) {
            resizeObserver.observe(containerEl);
        }
        
        return () => {
            if (containerEl) {
                resizeObserver.unobserve(containerEl);
            }
            resizeObserver.disconnect();
        };
    }, []); // Empty dependency array as we only want to set up the observer once


    const GraphSettings = () => (
        <Space direction="vertical" style={{ 
            padding: '16px 24px', 
            minWidth: '280px',
            background: 'white',
            boxShadow: '0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)',
            borderRadius: '8px'
        }}>
            <Typography.Title level={5} style={{ margin: '0 0 16px 0' }}>Graph Layout Settings</Typography.Title>
            
            <Form layout="vertical" style={{ width: '100%' }}>
                <Form.Item 
                    label={<Typography.Text strong>Nodes Per Row</Typography.Text>}
                    help="Adjust how many nodes appear in each row of the graph"
                >
                    <Slider
                        min={3}
                        max={100}
                        value={nodesPerRow}
                        onChange={(value) => {
                            setNodesPerRow(value);
                            setGraphKey(prev => prev + 1);
                            handleGraphLayout(undefined, true);
                        }}
                        marks={{
                            3: '3',
                            10: '10',
                            100: '100'
                        }}
                        tooltip={{ formatter: (value) => `${value} nodes` }}
                    />
                </Form.Item>
                
                <Form.Item 
                    label={<Typography.Text strong>Vertical Spacing</Typography.Text>}
                    help="Adjust the vertical distance between rows of nodes"
                >
                    <Slider
                        min={50}
                        max={300}
                        step={10}
                        value={verticalSpacing}
                        onChange={(value) => {
                            setVerticalSpacing(value);
                            setGraphKey(prev => prev + 1);
                            handleGraphLayout(undefined, true);
                        }}
                        marks={{
                            50: '50px',
                            150: '150px',
                            300: '300px'
                        }}
                        tooltip={{ formatter: (value) => `${value}px` }}
                    />
                </Form.Item>
            </Form>
        </Space>
    );

    // Add state for selected node at the top with other state declarations
    const [selectedNode, setSelectedNode] = useState<any>(null);

    // Add the NodeStats component before the return statement
    const NodeStats = ({ node }: { node: any }) => (
        <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white',
            padding: '16px 24px',
            boxShadow: '0 -2px 8px rgba(0,0,0,0.15)',
            zIndex: 1000,
            transition: 'transform 0.3s ease',
            transform: node ? 'translateY(0)' : 'translateY(100%)',
        }}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Space>
                        {node?.source && (
                            <Avatar src={node.source} size="large" />
                        )}
                        <Space direction="vertical" size={0}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                {node.label || node.entity || 'Unknown Entity'}
                            </Typography.Title>
                            <Typography.Text copyable={{ text: node.friendly_name }}>
                                {`${node.friendly_name?.slice(0, 6)}...${node.friendly_name?.slice(-4)}`}
                            </Typography.Text>
                        </Space>
                    </Space>
                    <Button 
                        icon={<CloseOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                        type="text"
                        onClick={() => setSelectedNode(null)}
                    />
                </Space>

                <Divider style={{ margin: '12px 0' }} />

                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Statistic 
                            title="Chain" 
                            value={chainIdToName[node.chain_id] || node.chain_id}
                            prefix={<Avatar 
                                size="small" 
                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${node.chain_id}.svg`}
                            />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Outgoing Transactions" 
                            value={node.outgoing_count || 0}
                            prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Incoming Transactions" 
                            value={node.incoming_count || 0}
                            prefix={<ArrowDownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Total Value" 
                            value={node.total_value || 0}
                            prefix="Ξ"
                        />
                    </Col>
                </Row>
            </Space>
        </div>
    );

    useEffect(() => {
        if (record?.transactions) {
            // Set initial transactions
            setTransactions(record?.transactions);
        }

        if (record?.pagination_cursors) {
            // Check pagination cursors from initial load
            const initialCursors = record.pagination_cursors;
            setPaginationCursors(initialCursors || {} as PaginationCursor);
            
            // Set hasMore based on initial cursors
            const hasMoreData = initialCursors?.status 
                ? Object.values(initialCursors.status).some(
                    (status: any) => !status.is_complete || status.has_more_data
                )
                : false;
                
            console.log('Initial cursors:', initialCursors);
            console.log('Initial hasMore:', hasMoreData);
            
            setHasMore(hasMoreData);
        }
    }, [record?.transactions, record?.pagination_cursors]);

    const handleChainSelection = (values: string[]) => {
        setSelectedChains(values);
    };

    const fetchSelectedChainData = async (refresh = false) => {
        setIsRefreshing(true);
        setTransactions([]);
        setPaginationCursors({
            moralis: {},
            database: {},
            status: {}
        });
        setCurrentPage(1);        
        try {
            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "dashboard",
                "Source-Region": use_region,
                "Destination-Region": use_region,
            };

            const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain;
            let url;
            if (qa_environment === "blue") {
                url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
            } else {
                url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses/${identifier}`;
            }

            // Add query parameters including pagination cursors
            const queryParams = new URLSearchParams({
                limit: '100',
            });

            // Add chain_ids from search params if they exist
            const chainIds = selectedChains.join(',');
            if (chainIds) {
                queryParams.set('chain_ids', chainIds);
            }

            const response = await fetch(`${url}?${queryParams}`, {
                headers: requestHeaders
            });

            const data = await response.json();
            console.log(data)
            // Update URL with new chain_ids
            if (data?.ok) {
                setTransactions(prev => refresh ? data.result.transactions : [...prev, ...data.result.transactions]);
                    
                // Update pagination cursors from response
                if (data.result.pagination_cursors) {
                    setPaginationCursors(data.result.pagination_cursors);
                    console.log(data.result.pagination_cursors)
                }

                // Check if any chain has a non-null cursor value
                const paginationCursors = data?.result?.pagination_cursors;
                const hasMoreData = paginationCursors?.status 
                    ? Object.values(paginationCursors.status).some(
                        (status: any) => !status.is_complete || status.has_more_data
                    )
                    : false;

                setHasMore(hasMoreData);
                
                setCurrentPage(prev => prev + 1);

                // Replace the navigate call with setSearchParams
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('chain_ids', selectedChains.join(','));
                setSearchParams(newSearchParams);
                
                open?.({
                    type: "success",
                    message: "Retrieved transaction data for selected chains",
                    description: "Retrieved transaction data for selected chains",
                    key: "active-chains",
                });                           
            } else {
                // Handle error response
                const errorMessage = data.error?.reason || 
                    data.error?.message || 
                    data.error?.detailed_reason || 
                    'Unknown error occurred';
                
                open?.({
                    type: "error",
                    message: `Error (${data.statusCode})`,
                    description: errorMessage,
                    key: "address",
                });
            }

        } catch (error) {
            open?.({
                type: "error",
                message: "Failed to retrieve transaction data for selected chains",
                description: error instanceof Error ? error.message : "Unknown error",
                key: "active-chains",
            });
            console.error("Error retrieving transaction data for selected chains:", error);
        } finally {
            setIsRefreshing(false);
        }
    };

    return (
        <Show isLoading={isLoading}>
            <Card title="Basic Information">
                <Space direction="vertical">
                    {record?.id && (
                        <>
                            <Title level={5}>Id</Title>
                            <Text>{record.id}</Text>
                        </>
                    )}

                    <Title level={5}>Address</Title>
                    <Typography.Text 
                        copyable={{ text: record?.address?.toString() || identifier || '' }} 
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {record?.address || identifier}
                    </Typography.Text>
                    <Title level={5}>Status</Title>
                    <Tag 
                        color={
                            record?.status === 'active' ? 'success' :
                            record?.status === 'pending' ? 'processing' :
                            record?.status === 'inactive' ? 'default' :
                            'warning'
                        }
                        style={{ 
                            padding: '4px 12px',
                            fontSize: '14px',
                            textTransform: 'capitalize'
                        }}
                    >
                        {record?.status}
                    </Tag>
                    <Title level={5}>Created at</Title>
                    <DateField value={dayjs.unix(record?.created_at || 1)} format="LLL"></DateField>
                    <Title level={5}>Created by</Title>
                    <Text>{record?.created_by}</Text>   
                    <Title level={5}>Active on Chain(s)</Title>
                    <Space direction="vertical" style={{ width: '100%' }}>
                    {(searchParams.get('chain_ids')?.split(',').sort().join(',') != activeChains.sort().join(',')) && (
                        <Alert
                            message={
                                <Space>
                                    {searchParams.get('chain_ids')?.split(',').map(chainId => (
                                        <Space key={chainId}>
                                            <Avatar 
                                                size="small"
                                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            />
                                            <Text strong>
                                                {chainIdToName[chainId] || 'Unknown Chain'}
                                            </Text>
                                        </Space>
                                    ))}
                                </Space>
                            }
                            description="Currently only showing data for the chains listed above. Click the refresh button to scan all supported chains and detect where this address has been active. This will update the list of chains shown below."
                            type="warning"
                            showIcon
                            style={{ marginBottom: 8 }}
                        />
                    )}
                        <Space>
                            <Space wrap>
                                {activeChains?.length > 0 ? (
                                    activeChains.map((chain_id: string) => (
                                        <Tooltip key={chain_id} title={chainIdToName[chain_id] || chain_id}>
                                            <Avatar 
                                                size="large"
                                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chain_id}.svg`}
                                                style={{ 
                                                    marginRight: 4,
                                                    // Highlight the chain from query params
                                                    border: searchParams.get('chain_id') === chain_id 
                                                        ? '2px solid #1890ff' 
                                                        : 'none',
                                                    padding: searchParams.get('chain_id') === chain_id ? '2px' : '0'
                                                }}
                                            />
                                        </Tooltip>
                                    ))
                                ) : (
                                    <Text type="secondary">No active chains detected</Text>
                                )}
                            </Space>
                            <Tooltip title={
                                isLoadingActiveChains 
                                    ? "Checking all available chains for activity..."
                                    : searchParams.get('chain_id')
                                        ? "Refresh to check all chains for activity"
                                        : "Refresh active chains"
                            }>
                                <Button 
                                    icon={<ReloadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                    onClick={refreshActiveChains}
                                    loading={isLoadingActiveChains}
                                    size="small"
                                    type={searchParams.get('chain_id') ? "primary" : "default"}
                                />
                            </Tooltip>
                        </Space>
                        {isLoadingActiveChains && (
                            <Alert
                                message="Checking chains for activity"
                                description="This operation checks all available chains to detect where this address has been active. This may take a few moments..."
                                type="info"
                                showIcon
                            />
                        )}
                    </Space>
                </Space>
            </Card>
            <Card 
                title={
                    <Space>
                        Tags
                        <Button 
                            icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                            type="link" 
                            onClick={() => showEditTagsModal(identifier)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                <Space wrap>
                    {record?.tags?.map((tag: any) => (
                        <Space key={tag.name}>
                            {tag.source && (
                                <Avatar 
                                    size="small" 
                                    src={`${tag.source}`}
                                />
                            )}
                            <TagField 
                                id={tag.name} 
                                value={tag.name} 
                                color={"#" + tag.color} 
                                style={{ marginTop: 5, marginBottom: 5 }}
                            />
                        </Space>
                    ))}
                </Space>
            </Card>
            <Card 
                title={
                    <Space>
                        Labels
                        <Button 
                            icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                            type="link" 
                            onClick={() => showEditLabelsModal(identifier)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Title level={5}>All Labels</Title>
                    <Space wrap>
                        {record?.labels?.map((label: any) => (
                            <Space key={label.label}>
                                {label.source && (
                                    <Avatar 
                                        size="small" 
                                        src={`${label.source}`}
                                    />
                                )}
                                <TagField 
                                    id={label.label} 
                                    value={label.label} 
                                    color={"#" + label.value} 
                                    style={{ marginTop: 5, marginBottom: 5 }}
                                />
                            </Space>
                        ))}
                    </Space>

                    <Title level={5}>Default Labels</Title>
                    <Space wrap>
                        {record?.default_labels?.map((label: any) => (
                            <Space key={label.label}>
                                {label.source && (
                                    <Avatar 
                                        size="small" 
                                        src={`${label.source}`}
                                    />
                                )}
                                <TagField 
                                    id={label.label} 
                                    value={label.label} 
                                    color={"#" + label.value} 
                                    style={{ marginTop: 5, marginBottom: 5 }}
                                />
                            </Space>
                        ))}
                    </Space>

                    <Title level={5}>Main Labels</Title>     
                    <Space wrap>
                        {record?.main_labels_grouped_by_chain?.map((label: any) => (
                            <Space key={label.label}>
                                {label.source && (
                                    <Avatar 
                                        size="small" 
                                        src={`${label.source}`}
                                    />
                                )}
                                <Tooltip title={"Main label on chain(s): " + label.chains.join(" + ")}>
                                    <TagField 
                                        id={label.label} 
                                        value={label.label} 
                                        color={"#" + label.value} 
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                    />
                                </Tooltip>
                            </Space>
                        ))}
                    </Space>
                </Space>
            </Card>
            <Card 
                title={
                    <Space>
                        Shared With
                        <Button 
                            icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                            type="link" 
                            onClick={() => showEditSharedWithModal(identifier)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                <Space wrap>
                    {record?.shared_with?.map((shared_with: any) => (
                        <TagField 
                            id={shared_with} 
                            key={shared_with} 
                            value={shared_with} 
                            style={{ marginTop: 5, marginBottom: 5 }}
                        />
                    ))}
                    {(!record?.shared_with || record.shared_with.length === 0) && (
                        <Text type="secondary">Not shared with anyone</Text>
                    )}
                </Space>
            </Card>
            <Card 
                title={
                    <Space>
                        Notes
                        <Button 
                            icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                            type="link" 
                            onClick={() => showEditNotesModal(identifier)}
                        />
                    </Space>
                } 
                style={{ marginTop: 16 }}
            >
                {record?.notes ? (
                    <Text>{record.notes}</Text>
                ) : (
                    <Text type="secondary">No notes available</Text>
                )}
            </Card>
            <Title level={5}>Number of transactions for address per chain:</Title>
            <List
                itemLayout="vertical"
                dataSource={record?.transaction_assignments}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Tooltip title="View Details">
                                <Button icon={<EyeOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} />
                            </Tooltip>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${item?.chain_id}.svg`} />}
                            title={<Space>
                                <Text strong>{item.chain}</Text>
                                <Tag color="blue">{item.total} transactions</Tag>
                            </Space>}
                            description={
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Statistic title="Outgoing" value={item.from_address_count} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Incoming" value={item.to_address_count} />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic title="Total" value={item.total} />
                                    </Col>
                                </Row>
                            }
                        />
                    </List.Item>
                )}
            />          
            <Divider></Divider>
                <>
                <Title level={5}>Transactions</Title>
                <Space style={{ marginBottom: 16 }} direction="vertical" size="middle">
                    <Space>
                        <Button 
                            onClick={() => fetchTransactions(true)}
                            icon={<ReloadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        >
                            Refresh Data
                        </Button>
                        <Tooltip title={
                            <div>
                                <p>This will fetch all historical transaction data for this address across the following chains:</p>
                                <ul style={{ paddingLeft: '20px', margin: '8px 0' }}>
                                    {activeChains?.map((chainId: string) => (
                                        <li key={chainId}>
                                            {chainIdToName[chainId] || chainId}
                                        </li>
                                    ))}
                                </ul>
                                <p>Note: This operation may take some time to complete depending on the number of transactions.</p>
                            </div>
                        }>
                            <Button
                                type="primary" 
                                onClick={refreshAddressData}
                                loading={isRefreshing}
                                icon={<CloudSyncOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            >
                                {isRefreshing ? 'Retrieving All Data...' : 'Get All Historical Data'}
                            </Button>
                        </Tooltip>
                        <Select
                            mode="multiple"
                            style={{ minWidth: 200 }}
                            placeholder="Select specific chains"
                            onChange={handleChainSelection}
                            defaultValue={
                                // Only use search params if they differ from active chains
                                searchParams.get('chain_ids')?.split(',').sort().join(',') !== activeChains?.sort().join(',')
                                    ? searchParams.get('chain_ids')?.split(',').filter(Boolean)
                                    : []
                            }
                            allowClear
                        >
                        {activeChains?.map((chainId: string) => (
                            <Select.Option key={chainId} value={chainId}>
                                <Space>
                                    <Tooltip title={chainIdToName[chainId] || chainId}>
                                        <Avatar 
                                            size="small"
                                            src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            style={{ backgroundColor: 'transparent' }}
                                        />
                                    </Tooltip>
                                    <Typography.Text>
                                        {chainIdToName[chainId] || chainId}
                                    </Typography.Text>
                                </Space>
                            </Select.Option>
                        ))}
                        </Select>
                        <Tooltip title="Fetch transactions for selected chains only">
                            <Button
                                onClick={(e) => fetchSelectedChainData()}
                                disabled={!selectedChains.length || isRefreshing}
                                icon={<FilterOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            >
                                Get Selected Chain Data
                            </Button>
                        </Tooltip>
                </Space>
                {selectedChains.length > 0 && (
                <Alert
                    message={
                        <Space>
                            <Typography.Text>Selected Chains:</Typography.Text>
                            {selectedChains.map(chainId => (
                                <Tooltip key={chainId} title={chainIdToName[chainId] || chainId}>
                                    <Avatar 
                                        size="small"
                                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                        style={{ backgroundColor: 'transparent' }}
                                    />
                                </Tooltip>
                            ))}
                        </Space>
                    }
                    description={
                        <Space direction="vertical">
                            <Typography.Text>
                                You are viewing results based on the selected chains shown above.
                            </Typography.Text>
                            <Typography.Text type="secondary">
                                To view data for all active chains, please clear the selection and refresh.
                            </Typography.Text>
                        </Space>
                    }
                    type="warning"
                    showIcon
                />
                )}                    
                {isRefreshing && (
                <Alert
                    message="Retrieving historical data on selected chains"
                    description={
                        <Space direction="vertical">
                            <Text>This operation fetches all historical transactions across the following chains:</Text>
                            <Space wrap>
                                {selectedChains.length > 0 ? 
                                    selectedChains.map(chainId => (
                                        <Space key={chainId}>
                                            <Avatar 
                                                size="small"
                                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            />
                                            <Text>{chainIdToName[chainId] || chainId}</Text>
                                        </Space>
                                    ))
                                    :
                                    activeChains?.map((chainId: string) => (
                                        <Space key={chainId}>
                                            <Avatar 
                                                size="small"
                                                src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                            />
                                            <Text>{chainIdToName[chainId] || chainId}</Text>
                                        </Space>
                                    ))
                                }
                            </Space>
                            <Text>This may take some time to complete. Please keep this tab open.</Text>
                        </Space>
                    }
                    type="info"
                    showIcon
                    style={{ marginLeft: 8 }}
                />
                    )}
                </Space>
                <div style={{ position: 'relative' }}>
                    <Table 
                        title={() => (
                            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                                <Dropdown overlay={columnVisibilityMenu} trigger={['click']}>
                                    <Button icon={<SettingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}>
                                        Columns
                                    </Button>
                                </Dropdown>
                            </Space>
                        )}
                        dataSource={transactions.map(tx => ({
                            ...tx,
                            key: `${tx.chain_id}_${tx.hash}` // Add unique key to each row
                        }))}
                        loading={isLoadingInfiniteScroll}
                        pagination={false}
                        
                        scroll={{ x: true, y: 400}}
                        sticky={{ 
                            offsetHeader: 0 
                        }}
                        style={{ 
                            maxHeight: 'calc(10 * 53px + 55px)', // Add extra space for header
                            overflow: 'auto'
                        }}                    
                        columns={[
                            { ...chainColumn, key: 'chain_id' },
                            {
                                title: 'Hash',
                                dataIndex: 'hash',
                                key: 'hash',
                                width: 200,
                                filters: [
                                    { text: 'Show Spam', value: 'spam' as React.Key },
                                    { text: 'Hide Spam', value: 'no-spam' as React.Key },
                                ],
                                onFilter: (value: boolean | React.Key, record: any) => {
                                    if (value === 'spam') return record.possible_spam === true;
                                    if (value === 'no-spam') return !record.possible_spam;
                                    return true;
                                },
                                sorter: (a: any, b: any) => (a.hash || '').localeCompare(b.hash || ''),
                                render: (hash: string, record: any) => {
                                    const truncatedHash = `${hash?.slice(0, 10)}...${hash?.slice(-10)}`;
                                    return (
                                        <Space>
                                            <Link to={`/transactions/show/${record.chain_id}_${hash}`}>
                                                <Tooltip title={hash}>
                                                    <Typography.Text copyable={{ text: hash }} style={{ whiteSpace: 'nowrap' }}>
                                                        {truncatedHash}
                                                    </Typography.Text>
                                                </Tooltip>
                                            </Link>
                                            {record.possible_spam && (
                                                <Tooltip title="This transaction has been flagged as possible spam">
                                                    <Tag color="red">SPAM</Tag>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    );
                                }
                            },
                            { ...methodColumn, key: 'method_label' },
                            {
                                title: 'Summary',
                                dataIndex: 'summary',
                                key: 'summary',
                                width: 300,
                                sorter: (a: { summary?: string }, b: { summary?: string }) => (a.summary || '').localeCompare(b.summary || ''),
                                render: (summary: string) => summary ? (
                                    <Typography.Text style={{ whiteSpace: 'nowrap', minWidth: 300 }}>
                                    {summary}
                                </Typography.Text>
                                ) : null
                            },
                            {
                                title: 'From',
                                dataIndex: 'from_address',
                                key: 'from_address',
                                width: 300,
                                filters: Array.from(new Set(transactions
                                    ?.map((item: any) => item.from_address_label || item.from_address_entity)
                                    .filter(Boolean)))
                                    .map(label => ({
                                        text: label,
                                        value: label as React.Key,
                                        logo: transactions?.find((i: any) => 
                                            (i.from_address_label || i.from_address_entity) === label
                                        )?.from_address_entity_logo
                                    })),
                                filterDropdown: (props: FilterDropdownProps) => {
                                    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
                                    
                                    // Get unique labels with their logos
                                    const uniqueOptions = new Map();
                                    transactions?.forEach((item: any) => {
                                        const label = item.from_address_label;
                                        const entity = item.from_address_entity;
                                        if (label || entity) {
                                            uniqueOptions.set(label || entity, {
                                                text: label || entity,
                                                value: label || entity,
                                                logo: item.from_address_entity_logo,
                                                entity: entity !== label ? entity : undefined // Only include entity if different from label
                                            });
                                        }
                                    });

                                    const filterOptions = Array.from(uniqueOptions.values());

                                    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.checked) {
                                            setSelectedKeys(filterOptions.map(option => option.value));
                                        } else {
                                            setSelectedKeys([]);
                                        }
                                    };

                                    return (
                                        <div style={{ padding: 8 }}>
                                            <Checkbox
                                                indeterminate={selectedKeys.length > 0 && selectedKeys.length < filterOptions.length}
                                                checked={selectedKeys.length === filterOptions.length}
                                                onChange={(e) => handleSelectAll(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                                                style={{ marginBottom: 8 }}
                                            >
                                                Select All
                                            </Checkbox>
                                            <Divider style={{ margin: '8px 0' }} />
                                            {filterOptions.map((option, index) => (
                                                <div key={`${option.value}-${index}`} style={{ marginBottom: 8 }}>
                                                    <Checkbox
                                                        checked={selectedKeys.includes(option.value)}
                                                        onChange={(e) => {
                                                            const newSelectedKeys = e.target.checked
                                                                ? [...selectedKeys, option.value]
                                                                : selectedKeys.filter(key => key !== option.value);
                                                            setSelectedKeys(newSelectedKeys);
                                                        }}
                                                    >
                                                        <Space>
                                                            <Tooltip title={`${option.text}${option.entity ? ` (${option.entity})` : ''}`}>
                                                                <Avatar 
                                                                    size="small" 
                                                                    src={option.logo}
                                                                    style={{ backgroundColor: 'transparent' }}
                                                                />
                                                            </Tooltip>
                                                            <span>{option.text}</span>
                                                        </Space>
                                                    </Checkbox>
                                                </div>
                                            ))}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    onClick={() => confirm()}
                                                    size="small"
                                                >
                                                    Apply
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        clearFilters?.();
                                                        confirm();
                                                    }}
                                                    size="small"
                                                >
                                                    Reset
                                                </Button>
                                            </Space>
                                        </div>
                                    );
                                },
                                onFilter: (value: boolean | React.Key, record: any) => {
                                    return record.from_address_label === value || record.from_address_entity === value;
                                },
                                sorter: (a: any, b: any) => {
                                    const labelA = a.from_address_label || a.from_address_entity || a.from_address || '';
                                    const labelB = b.from_address_label || b.from_address_entity || b.from_address || '';
                                    return labelA.localeCompare(labelB);
                                },
                                render: (address: string, record: any) => {
                                    const truncatedAddress = `${address?.slice(0, 6)}...${address?.slice(-6)}`;
                                    return (
                                        <Descriptions column={1} size="small" style={{ margin: 0, minWidth: 280 }}>
                                            <Descriptions.Item>
                                                <Space>
                                                    {record.from_address_entity_logo && (
                                                        <Tooltip title={record.from_address_entity || record.from_address_label}>
                                                            <Avatar size="small" src={record.from_address_entity_logo} />
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip title={address}>
                                                        <Typography.Text copyable={{ text: address }} style={{ whiteSpace: 'nowrap' }}>
                                                            {truncatedAddress}
                                                        </Typography.Text>
                                                    </Tooltip>
                                                </Space>
                                            </Descriptions.Item>
                                            {(record.from_address_label || record.from_address_entity) && (
                                                <Descriptions.Item>
                                                    <Space size={4} wrap>
                                                        {record.from_address_label && (
                                                            <Tag 
                                                                color="blue"
                                                                style={{ 
                                                                    maxWidth: '100%',
                                                                    whiteSpace: 'normal',
                                                                    height: 'auto',
                                                                    padding: '2px 8px',
                                                                    lineHeight: '1.5'
                                                                }}
                                                            >
                                                                {record.from_address_label}
                                                            </Tag>
                                                        )}
                                                        {record.from_address_entity && (
                                                            <Tag 
                                                                color="purple"
                                                                style={{ 
                                                                    maxWidth: '100%',
                                                                    whiteSpace: 'normal',
                                                                    height: 'auto',
                                                                    padding: '2px 8px',
                                                                    lineHeight: '1.5'
                                                                }}
                                                            >
                                                                {record.from_address_entity}
                                                            </Tag>
                                                        )}
                                                    </Space>
                                                </Descriptions.Item>
                                            )}
                                        </Descriptions>
                                    );
                                }
                            },
                            { ...toColumn, key: 'to_address' },
                            {
                                title: 'Value',
                                dataIndex: 'value',
                                key: 'value',
                                width: 200,
                                filters: [
                                    { text: 'Has Native Transfer', value: 'native' as React.Key },
                                    { text: 'Has ERC20 Transfer', value: 'erc20' as React.Key },
                                    { text: 'Has NFT Transfer', value: 'nft' as React.Key },
                                    { text: 'Has Contract Interaction', value: 'contract' as React.Key },
                                ],
                                onFilter: (value: boolean | React.Key, record: any) => {
                                    const filterValue = value.toString();
                                    
                                    // Ensure we're safely checking arrays
                                    const hasNativeTransfers = Array.isArray(record?.native_transfers) && record.native_transfers.length > 0;
                                    const hasERC20Transfers = Array.isArray(record?.erc20_transfers) && record.erc20_transfers.length > 0;
                                    const hasNFTTransfers = Array.isArray(record?.nft_transfers) && record.nft_transfers.length > 0;
                                    const hasContractInteractions = Array.isArray(record?.contract_interactions) && record.contract_interactions.length > 0;
                            
                                    switch(filterValue) {
                                        case 'native': return hasNativeTransfers;
                                        case 'erc20': return hasERC20Transfers;
                                        case 'nft': return hasNFTTransfers;
                                        case 'contract': return hasContractInteractions;
                                        default: return true;
                                    }
                                },
                                sorter: (a: any, b: any) => {
                                    const valueA = a.native_transfers?.[0]?.value_formatted || '0';
                                    const valueB = b.native_transfers?.[0]?.value_formatted || '0';
                                    return Number(valueA) - Number(valueB);
                                },
                                render: (_: string, record: any) => {
                                    const nativeTransfers = record.native_transfers || [];
                                    const erc20Transfers = record.erc20_transfers || [];
                                    const nftTransfers = record.nft_transfers || [];
                                    const contractInteractions = record?.contract_interactions || [];
                                    
                                    return (
                                        <Space direction="vertical">
                                            {/* Native Transfers */}
                                            {Array.isArray(nativeTransfers) && nativeTransfers.map((transfer: any, index: number) => (
                                                <Space key={`native-${index}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {transfer.token_logo && (
                                                        <Tooltip title={transfer.token_symbol}>
                                                            <Avatar 
                                                                size="small" 
                                                                src={transfer.token_logo}
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                        {transfer.value_formatted} {transfer.token_symbol}
                                                    </Typography.Text>
                                                    <Tag 
                                                        color={transfer.direction === 'send' ? 'red' : 'green'}
                                                        style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                                    >
                                                        {transfer.direction}
                                                    </Tag>
                                                </Space>
                                            ))}
                                            
                                            {/* ERC20 Transfers */}
                                            {Array.isArray(erc20Transfers) && erc20Transfers.map((transfer: any, index: number) => (
                                                <Space key={`erc20-${index}`} style={{ whiteSpace: 'nowrap' }}>
                                                    {transfer.token_logo && (
                                                        <Tooltip title={`${transfer.token_name} (${transfer.token_symbol})`}>
                                                            <Avatar 
                                                                size="small" 
                                                                src={transfer.token_logo}
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                        {transfer.value_formatted} {transfer.token_symbol}
                                                    </Typography.Text>
                                                    <Tag 
                                                        color={transfer.direction === 'send' ? 'red' : 'green'}
                                                        style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                                                    >
                                                        {transfer.direction}
                                                    </Tag>
                                                    {transfer.security_score && (
                                                        <Tooltip title="Token Security Score">
                                                            <Tag color={Number(transfer.security_score) >= 80 ? 'green' : 'orange'}>
                                                                Score: {transfer.security_score}
                                                            </Tag>
                                                        </Tooltip>
                                                    )}
                                                </Space>
                                            ))}

                                            {/* NFT Transfers */}
                                            {Array.isArray(nftTransfers) && nftTransfers.map((transfer: any, index: number) => (
                                                <Space key={`nft-${index}`} style={{ whiteSpace: 'nowrap' }}>
                                                    <Tag color="purple">NFT</Tag>
                                                    <Space direction="vertical" size={0}>
                                                        <Space>
                                                            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                                                {transfer.contract_type}
                                                            </Typography.Text>
                                                            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                                ID: {transfer.token_id}
                                                            </Typography.Text>
                                                            {transfer.amount && transfer.amount !== "1" && (
                                                                <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                                    × {transfer.amount}
                                                                </Typography.Text>
                                                            )}
                                                        </Space>
                                                        <Space>
                                                            {transfer.from_address_entity_logo && (
                                                                <Tooltip title={transfer.from_address_entity || transfer.from_address_label}>
                                                                    <Avatar size="small" src={transfer.from_address_entity_logo} />
                                                                </Tooltip>
                                                            )}
                                                            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                                                →
                                                            </Typography.Text>
                                                            {transfer.to_address_entity_logo && (
                                                                <Tooltip title={transfer.to_address_entity || transfer.to_address_label}>
                                                                    <Avatar size="small" src={transfer.to_address_entity_logo} />
                                                                </Tooltip>
                                                            )}
                                                        </Space>
                                                    </Space>
                                                    {transfer.verified_collection && (
                                                        <Tooltip title="Verified Collection">
                                                            <Tag color="green">Verified</Tag>
                                                        </Tooltip>
                                                    )}
                                                    {transfer.possible_spam && (
                                                        <Tooltip title="Possible Spam">
                                                            <Tag color="red">Spam</Tag>
                                                        </Tooltip>
                                                    )}
                                                </Space>
                                            ))}

                                            {/* Contract Interactions */}
                                            {record.contract_interactions && Object.entries(record.contract_interactions).map(([interactionType, interactions]) => (
                                                // Map through each type of interaction array
                                                Array.isArray(interactions) && interactions.map((interaction: any, index: number) => (
                                                    <Space key={`${interactionType}-${index}`} style={{ whiteSpace: 'nowrap' }}>
                                                        <Tag color="blue">
                                                            {interactionType.charAt(0).toUpperCase() + interactionType.slice(1)}
                                                        </Tag>
                                                        <Space direction="vertical" size={0}>
                                                            <Space>
                                                                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                                                    {/* Handle different interaction types */}
                                                                    {interactionType === 'approvals' ? (
                                                                        <>Approve {interaction.token?.token_symbol}</>
                                                                    ) : (
                                                                        interaction.method_label || interaction.method || 'Unknown Method'
                                                                    )}
                                                                </Typography.Text>
                                                                {/* Show value if it exists */}
                                                                {(interaction.value_formatted || interaction.value) && (
                                                                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                                                                        Value: {interaction.value_formatted || interaction.value} {interaction.token?.token_symbol}
                                                                    </Typography.Text>
                                                                )}
                                                            </Space>
                                                            <Space>
                                                                {/* Show entity logo if it exists */}
                                                                {(interaction.spender?.entity_logo || interaction.contract_entity_logo) && (
                                                                    <Tooltip title={
                                                                        interaction.spender?.entity || 
                                                                        interaction.spender?.address_label || 
                                                                        interaction.contract_entity || 
                                                                        interaction.contract_label
                                                                    }>
                                                                        <Avatar 
                                                                            size="small" 
                                                                            src={interaction.spender?.entity_logo || interaction.contract_entity_logo} 
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                                {/* Show address */}
                                                                <Typography.Text copyable={{ 
                                                                    text: interaction.spender?.address || interaction.contract_address 
                                                                }}>
                                                                    {`${(interaction.spender?.address || interaction.contract_address || '')?.slice(0, 6)}...${(interaction.spender?.address || interaction.contract_address || '')?.slice(-4)}`}
                                                                </Typography.Text>
                                                            </Space>
                                                        </Space>
                                                        {/* Show verification status */}
                                                        {interaction.verified_contract && (
                                                            <Tooltip title="Verified Contract">
                                                                <Tag color="green">Verified</Tag>
                                                            </Tooltip>
                                                        )}
                                                        {interaction.possible_spam && (
                                                            <Tooltip title="Possible Spam Contract">
                                                                <Tag color="red">Spam</Tag>
                                                            </Tooltip>
                                                        )}
                                                    </Space>
                                                ))
                                            ))}
                                        </Space>
                                    );
                                }
                            },
                            {
                                title: 'Block Time',
                                dataIndex: 'block_timestamp',
                                key: 'block_timestamp',
                                width: 200,
                                filterDropdown: ({ 
                                    setSelectedKeys, 
                                    selectedKeys, 
                                    confirm, 
                                    clearFilters 
                                }: {
                                    setSelectedKeys: (keys: React.Key[]) => void;
                                    selectedKeys: React.Key[];
                                    confirm: () => void;
                                    clearFilters?: () => void;
                                }) => (
                                    <div style={{ padding: 8 }}>
                                        <DatePicker.RangePicker
                                            value={selectedKeys.length ? [
                                                dayjs((selectedKeys as unknown as [number, number][])[0]?.[0]),
                                                dayjs((selectedKeys as unknown as [number, number][])[0]?.[1])
                                            ] : null}
                                            onChange={(dates) => {
                                                if (dates) {
                                                    const startDate = dates[0]?.startOf('day');
                                                    const endDate = dates[1]?.endOf('day');
                                                    setSelectedKeys([[startDate?.valueOf(), endDate?.valueOf()]] as unknown as React.Key[]);
                                                } else {
                                                    setSelectedKeys([]);
                                                }
                                            }}
                                            style={{ marginBottom: 8, display: 'block' }}
                                        />
                                        <Space>
                                            <Button
                                                type="primary"
                                                onClick={() => confirm()}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Filter
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    clearFilters?.();
                                                    confirm();
                                                }}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Reset
                                            </Button>
                                        </Space>
                                    </div>
                                ),
                                filterIcon: (filtered: boolean) => (
                                    <FilterOutlined 
                                        style={{ color: filtered ? '#1890ff' : undefined }}
                                        onPointerEnterCapture={undefined} 
                                        onPointerLeaveCapture={undefined}
                                    />
                                ),
                                onFilter: (value: any, record: any) => {
                                    if (!value || !record.block_timestamp) return false;
                                    const timestamp = new Date(record.block_timestamp).getTime();
                                    const [startTime, endTime] = value as unknown as [number, number];
                                    return timestamp >= startTime && timestamp <= endTime;
                                },
                                sorter: (a: any, b: any) => {
                                    const timeA = new Date(a.block_timestamp || 0).getTime();
                                    const timeB = new Date(b.block_timestamp || 0).getTime();
                                    return timeA - timeB;
                                },
                                render: (timestamp: string) => {
                                    if (!timestamp) return null;
                                    
                                    const date = new Date(timestamp);
                                    
                                    return (
                                        <Tooltip title={date.toLocaleString()}>
                                            {date.toLocaleDateString()} {date.toLocaleTimeString()}
                                        </Tooltip>
                                    );
                                }
                            },
                            foundInColumn,
                            {
                                title: 'Actions',
                                key: 'actions',
                                width: 200,
                                render: (_: any, record: any) => (
                                    <Button 
                                        type="link" 
                                        onClick={() => setJsonModalData(record)}
                                    >
                                        Show More
                                    </Button>
                                )
                            }
                        ].filter(column => visibleColumns.includes(column.key as string))}
                        onChange={(pagination, filters, sorter, extra) => {
                            setFilteredData(extra.currentDataSource);
                            // Force graph remount
                            setGraphKey(prev => prev + 1);
                        }}
                        summary={() => {
                            // Calculate summary statistics
                            const totalTxs = transactions?.length || 0;
                            
                            // Define interface for transfer counts
                            interface TransferCounts {
                                native: number;
                                erc20: number;
                                nft: number;
                                internal: number;
                                contract: number;
                            }

                            // Calculate transfers by type with proper typing
                            const transfers = transactions.reduce((acc: TransferCounts, tx: any) => {
                                return {
                                    native: acc.native + (tx.native_transfers?.length || 0),
                                    erc20: acc.erc20 + (tx.erc20_transfers?.length || 0),
                                    nft: acc.nft + (tx.nft_transfers?.length || 0),
                                    internal: acc.internal + (tx.internal_transactions?.length || 0),
                                    contract: acc.contract + (tx.contract_interactions?.length || 0)
                                };
                            }, { native: 0, erc20: 0, nft: 0, internal: 0, contract: 0 });

                            // Define interface for chain counts
                            interface ChainCounts {
                                [key: string]: number;
                            }

                            // Calculate transactions by chain with proper typing
                            const chainCounts = transactions.reduce((acc: ChainCounts, tx: any) => {
                                const chainId = tx.chain_id;
                                return {
                                    ...acc,
                                    [chainId]: (acc[chainId] || 0) + 1
                                };
                            }, {} as ChainCounts);

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={2}>
                                        <div style={{
                                            fontWeight: 'bold',
                                            backgroundColor: '#fafafa',
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 2,
                                            padding: '8px 16px'
                                        }}>
                                            <Space direction="vertical" size="small">
                                                <Typography.Text strong>
                                                    Total Transactions: {totalTxs}
                                                </Typography.Text>
                                                <Space wrap>
                                                    {transfers?.native > 0 && (
                                                        <Tag color="orange">
                                                            Native Transfers: {transfers.native}
                                                        </Tag>
                                                    )}
                                                    {transfers?.erc20 > 0 && (
                                                        <Tag color="blue">
                                                            ERC20 Transfers: {transfers.erc20}
                                                        </Tag>
                                                    )}
                                                    {transfers?.nft > 0 && (
                                                        <Tag color="purple">
                                                            NFT Transfers: {transfers.nft}
                                                        </Tag>
                                                    )}
                                                    {transfers?.internal > 0 && (
                                                        <Tag color="cyan">
                                                            Internal Txs: {transfers.internal}
                                                        </Tag>
                                                    )}
                                                    {transfers?.contract > 0 && (
                                                        <Tag color="red">
                                                            Contract Interactions: {transfers.contract}
                                                        </Tag>
                                                    )}
                                                </Space>
                                            </Space>
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} colSpan={6}>
                                        <Space wrap>
                                            {Object.entries(chainCounts || {}).map(([chainId, count]) => (
                                                <Space key={chainId}>
                                                    <Avatar 
                                                        size="small"
                                                        src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${chainId}.svg`}
                                                    />
                                                    <Typography.Text>
                                                        {String(chainIdToName[chainId] ?? chainId)}: {count}
                                                    </Typography.Text>
                                                </Space>
                                            ))}
                                        </Space>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                    />
                    <div style={{ textAlign: 'center', marginTop: 16 }}>
                        <Typography.Text strong>
                            Total Transactions: {transactions.length || 0}
                        </Typography.Text>
                    </div>
                    {hasMore && (
                        <div style={{ textAlign: 'center', marginTop: 16 }}>
                            <Button 
                                onClick={() => fetchTransactions()} 
                                loading={isLoading || isLoadingInfiniteScroll}
                            >
                                Load More
                            </Button>
                        </div>
                    )}
                    {/* Scroll buttons */}
                    {transactions && transactions.length > 0 && (
                        <div style={{
                            position: 'absolute',
                            bottom: 20,
                            right: 35, // Adjusted to account for scrollbar
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            zIndex: 1000,
                            pointerEvents: 'auto'
                        }}>
                            <Tooltip title="Scroll to top">
                                <Button
                                    type="primary"
                                    size="small"
                                    icon={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                    onClick={() => {
                                        const tableBody = document.querySelector('.ant-table-body');
                                        if (tableBody) {
                                            tableBody.scrollTo({ 
                                                top: 0, 
                                                behavior: 'smooth' 
                                            });
                                        }                                    
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Scroll to bottom" placement="bottom">
                                <Button
                                    type="primary"
                                    size="small"
                                    icon={<ArrowDownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                    onClick={() => {
                                        console.log('clicked');
                                        const tableBody = document.querySelector('.ant-table-body');
                                        if (tableBody) {
                                            tableBody.scrollTo({ 
                                                top: tableBody.scrollHeight, 
                                                behavior: 'smooth' 
                                            });
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
                </>
            <Divider/>
            <Modal  {...createColumnsModalProps} title="Import transactions for address details">
                <Form key="checkboxForm" {...createColumnsFormProps} layout="vertical" onFinish={(e) => ReProcessTransactionsForAddress(identifier!)}>
                    <Form.Item 
                            label="From Block"
                            name="from_block"
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input onChange={(e) => setFromBlock(Number(e.target.value))}/>
                    </Form.Item>  
                    <Form.Item 
                            label="To Block"
                            name="to_block"
                            initialValue={999999999999}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input onChange={(e) => setToBlock(Number(e.target.value))}/>
                    </Form.Item>  
                    <Text>Select Chains to import from</Text>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', marginTop: '1px' }}>
                        {available_chains.map((chain, index) => (
                            <Form.Item 
                                key={`chain-${chain.label}-${index}`}
                                id={`chain-${chain.label}`}
                                name={chain.label}
                                style={{ margin: 0 }} // Remove default Form.Item margin
                            >
                                <Checkbox 
                                    key={`checkbox-${chain.label}-${index}`}
                                    id={`checkbox-${chain.label}`}
                                    onChange={(e) => toggleChain(e)} 
                                    defaultChecked={chain.value}
                                >
                                    {chain.label}
                                </Checkbox>
                            </Form.Item>
                        ))}
                    </div>
                </Form>
            </Modal>
            <Modal 
                {...editTagsModalProps} 
                title="Edit Tags"
                centered
                style={{ maxWidth: 520 }}
            >
                <Form
                    {...editTagsFormProps}
                    initialValues={{
                        tags: record?.tags || [], 
                    }}
                >
                    <Form.Item
                        label="Tags"
                        name="tags"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            labelInValue={true}
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Add tags here"
                            tagRender={tagRender}
                            options={record?.tags} // Initialize with empty array if addressLabels is undefined
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal 
                {...editLabelsModalProps} 
                title="Edit Labels"
                centered
                style={{ maxWidth: 520 }}
            >
                <Form
                    {...editLabelsFormProps}
                    initialValues={{
                        labels: record?.labels || [], 
                    }}
                >
                    <Form.Item
                        label="Labels"
                        name="labels"
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select labels"
                            tagRender={labelRender}
                            options={record?.labels}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal 
                {...editSharedWithModalProps} 
                title="Edit Shared With"
                centered
                style={{ maxWidth: 520 }}
            >
                <Form {...editSharedWithFormProps} layout="vertical"
                    initialValues={{
                        shared_with: record?.shared_with || [], 
                    }}
                >
                    <Form.Item 
                        label="Share the address with the following people in the organization"
                        name="shared_with"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DebounceSelect
                            mode="multiple"
                            value={value}
                            placeholder="Select users"
                            fetchOptions={fetchUserList}
                            onChange={(newValue) => {
                                setValue(newValue as UserValue[]);
                            }}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal 
                {...editNotesModalProps} 
                title="Edit Notes"
                centered
                style={{ maxWidth: 520 }}
            >
                <Form {...editNotesFormProps} layout="vertical">
                    <Form.Item
                        label="Notes"
                        name="notes"
                        initialValue={record?.notes}
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea 
                            rows={4}
                            placeholder="Enter notes about this address"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={!!selectedSection}
                onCancel={() => setSelectedSection(null)}
                title={selectedSection ? `${selectedSection.type.charAt(0).toUpperCase() + selectedSection.type.slice(1)} Details` : ''}
                width={800}
                footer={[
                    <Button key="close" onClick={() => setSelectedSection(null)}>
                        Close
                    </Button>
                ]}
            >
                {renderModalContent()}
            </Modal>
            <JsonModal />
            <Card 
                title={
                    <Space direction="vertical" size="small">
                        <Typography.Title level={4} style={{ margin: 0 }}>Transaction Analytics Graph</Typography.Title>
                        <Typography.Text type="secondary">
                            This graph visualizes the transaction data from the table above. Use the table filters to refine the displayed connections.
                        </Typography.Text>
                        <Space>
                            <InfoCircleOutlined style={{ color: '#1890ff' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <Typography.Text type="secondary">
                                Tip: Drag nodes to rearrange, use mouse wheel to zoom, and click the chain buttons above to focus on specific networks
                            </Typography.Text>
                        </Space>
                    </Space>
                }
                style={{ 
                    marginTop: 16,
                    width: '100%'
                }}
            >
                { 
                    isLoading ? 
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
                            <Spin spinning={isLoading} /> 
                        </div>
                    : 
                    <div style={{ 
                        width: '100%', 
                        height: '600px',
                        border: '1px solid #f0f0f0',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        backgroundColor: '#fff',
                        position: 'relative'
                    }}>
                        <Space
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 1000
                            }}
                        >
                            {/* Settings button */}
                            <Tooltip title="Graph Settings">
                                <Dropdown 
                                    overlay={<GraphSettings />} 
                                    trigger={['click']}
                                    placement="bottomRight"
                                >
                                    <Button 
                                        icon={<SettingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} 
                                        type="text"
                                    />
                                </Dropdown>
                            </Tooltip>

                            {/* Chain buttons */}
                            {Array.from(new Set(graphDataNew.nodes
                                .filter((n: any) => n.chain_id)
                                .map((n: any) => n.chain_id)))
                                .map((chainId) => (
                                    <Button
                                        key={chainId}
                                        icon={<AimOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                        onClick={() => {
                                            handleGraphLayout(chainId);
                                        }}
                                    >
                                        {chainIdToName[chainId] || `Chain ${chainId}`}
                                    </Button>
                                ))}
                            
                            {/* Center all nodes button */}
                            <Button
                                icon={<AimOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                type="primary"
                                onClick={() => {
                                    handleGraphLayout(undefined, true);
                                }}
                            >
                                Center on All Nodes
                            </Button>
                        </Space>
                        <ForceGraph2D
                            key={graphKey}
                            ref={fgRef}
                            graphData={graphDataNew}
                            nodeLabel={(node: any) => {
                                // Define styles using Unicode box drawing characters
                                const boxChars = {
                                    topLeft: '╭',
                                    topRight: '╮',
                                    bottomLeft: '╰',
                                    bottomRight: '╯',
                                    vertical: '│',
                                    horizontal: '─',
                                    separator: '┄'
                                };

                                // Helper function to safely truncate and format text
                                const formatText = (text: string | undefined | null, maxLength: number = 30) => {
                                    if (!text) return '';
                                    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
                                };

                                // Helper function to format address
                                const formatAddress = (address: string | undefined | null) => {
                                    if (!address) return 'N/A';
                                    return `${address.slice(0, 6)}...${address.slice(-4)}`;
                                };

                                // Format each piece of information with proper handling of missing values
                                const sections = [];
                                
                                // Chain info with icon and fallback
                                const chainName = chainIdToName[node.chain_id] || `Chain ${node.chain_id}` || 'Unknown Chain';
                                sections.push({
                                    icon: '⛓️',
                                    label: 'Chain',
                                    value: formatText(chainName)
                                });
                                
                                // Address with icon and fallback
                                sections.push({
                                    icon: '🔑',
                                    label: 'Address',
                                    value: formatAddress(node.friendly_name)
                                });
                                
                                // Label with icon (if exists)
                                if (node.label) {
                                    sections.push({
                                        icon: '🏷️',
                                        label: 'Label',
                                        value: formatText(node.label)
                                    });
                                }
                                
                                // Entity with icon (if exists)
                                if (node.entity) {
                                    sections.push({
                                        icon: '👤',
                                        label: 'Entity',
                                        value: formatText(node.entity)
                                    });
                                }
                                
                                // Join with newlines
                                return sections.map(section => `${section.icon} ${section.label}: ${section.value}`).join('\n');
                            }}
                            linkLabel={(link: any) => {
                                // Helper function to safely format text
                                const formatText = (text: string | undefined | null, maxLength: number = 30) => {
                                    if (!text || text === '') return 'N/A';
                                    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
                                };

                                // Ensure link exists
                                if (!link || !link.type) {
                                    return [
                                        '❓ Type: Unknown',
                                        '🏷️ Label: N/A'
                                    ].join('\n');
                                }

                                // Format based on link type
                                switch (link.type.toLowerCase()) {
                                    case 'nft': {
                                        const sections = [
                                            `🎨 Type: ${formatText(link.contract_type || 'NFT')}`,
                                            `🔢 Token ID: ${formatText(link.token_id)}`,
                                            `📊 Amount: ${link.amount?.toString() || '1'}`,
                                            `↔️ Direction: ${formatText(link.direction || 'transfer')}`
                                        ];
                                        
                                        if (link.verified_collection) sections.push('✅ Status: Verified');
                                        if (link.possible_spam) sections.push('⚠️ Warning: Spam');
                                        
                                        return sections.join('\n');
                                    }

                                    case 'erc20':
                                        return [
                                            `💰 Type: ERC20 Transfer`,
                                            `${link.token_logo ? '🖼️' : '🪙'} Token: ${formatText(link.token_name || link.token_symbol || 'Token')}${link.token_logo ? ` (${link.token_symbol})` : ''}`,
                                            `📊 Amount: ${link.value || '0'} ${link.token_symbol || ''}`,
                                            `↔️ Direction: ${formatText(link.direction || 'transfer')}`,
                                            `🔒 Security: ${link.security_score || 'N/A'}`,
                                            ...(link.verified_contract ? ['✅ Status: Verified'] : [])
                                        ].join('\n');

                                    case 'native':
                                        return [
                                            `💎 Type: Native Transfer`,
                                            `📊 Amount: ${link.value || '0'} ${link.token_symbol || ''}`,
                                            `↔️ Direction: ${formatText(link.direction || 'transfer')}`,
                                            `📝 Transaction: ${formatText(link.internal_transaction ? 'Internal' : 'External')}`
                                        ].join('\n');

                                    case 'internal':
                                        return [
                                            `🔄 Type: Internal (${formatText(link.call_type)})`,
                                            `💎 Amount: ${link.value || '0'} ETH`,
                                            `⛽ Gas: ${link.gas || '0'} (${link.gas_used || '0'} used)`,
                                            `🔢 Block: ${link.block_number ? `Block: ${link.block_number}` : 'N/A'}`,
                                            `📝 Tx Hash: ${formatText(link.transaction_hash)}`
                                        ].join('\n');

                                    default:
                                        return [
                                            `❓ Type: ${formatText(link.type)}`,
                                            `🏷️ Label: ${formatText(link.linkLabel)}`
                                        ].join('\n');
                                }
                            }}
                            nodeAutoColorBy="chain_id"
                            linkColor={(link: any) => {
                                switch(link.type) {
                                    case 'erc20':
                                        return '#2196f3'; // Blue
                                    case 'native':
                                        return '#ff9800'; // Orange
                                    case 'internal':
                                        return '#9c27b0'; // Purple
                                    case 'nft':
                                        return '#e91e63'; // Pink
                                    case 'nft_operator':
                                        return '#4caf50'; // Green
                                    case 'contract':
                                        return '#f44336'; // Red
                                    default:
                                        return '#999'; // Gray
                                }
                            }}
                            linkWidth={(link: any) => {
                                switch(link.type) {
                                    case 'erc20':
                                    case 'native':
                                    case 'internal':
                                    case 'nft':
                                        return 2;
                                    case 'nft_operator':
                                        return 1;
                                    case 'contract':
                                        return 2;
                                    default:
                                        return 1;
                                }
                            }}
                            backgroundColor="white"
                            nodeCanvasObjectMode={() => "replace"}
                            // Adjust force parameters
                            d3AlphaDecay={0.1}              // Prevent simulation from cooling down
                            d3VelocityDecay={0.1}      // Keep nodes moving smoothly
                            // d3Force="charge"           // Enable charge force
                            nodeRelSize={8}            // Slightly larger nodes
                            enableNodeDrag={true}
                            warmupTicks={0}
                            cooldownTicks={0}
                            enableZoomInteraction={true}  // Ensure zoom is enabled
                            enablePanInteraction={true}       
    
                            nodeCanvasObject={(node: any, ctx: any, globalScale: any) => {
                                // First, draw the chain sections if this is the first node
                                if (node === graphDataNew.nodes[0]) {
                                    // Get unique chain IDs
                                    const uniqueChains = new Set(graphDataNew.nodes
                                        .filter((n: any) => n.chain_id)
                                        .map((n: any) => n.chain_id));
                                    const chainIds = Array.from(uniqueChains);
                                    
                                    // For 2 chains, use a horizontal layout
                                    const cols = 2;  // Force 2 columns for 2 chains
                                    const rows = 1;  // Force 1 row for 2 chains
                                    
                                    // Calculate section size with margins
                                    const margin = 20; // Margin between sections
                                    const sectionWidth = (ctx.canvas.width - (margin * (cols + 1))) / cols;
                                    const sectionHeight = (ctx.canvas.height - (margin * (rows + 1))) / rows;

                                    // Calculate the bounds for each chain's nodes first
                                    const chainBounds = new Map();
                                    graphDataNew.nodes.forEach((node: any) => {
                                        if (node.chain_id) {
                                            if (!chainBounds.has(node.chain_id)) {
                                                chainBounds.set(node.chain_id, {
                                                    minX: Infinity,
                                                    maxX: -Infinity,
                                                    minY: Infinity,
                                                    maxY: -Infinity
                                                });
                                            }
                                            const bounds = chainBounds.get(node.chain_id);
                                            bounds.minX = Math.min(bounds.minX, node.x);
                                            bounds.maxX = Math.max(bounds.maxX, node.x);
                                            bounds.minY = Math.min(bounds.minY, node.y);
                                            bounds.maxY = Math.max(bounds.maxY, node.y);
                                        }
                                    });

                                    // Draw sections for each chain
                                     // First, group nodes by chain
                                     const nodesByChain = new Map();
                                     graphDataNew.nodes.forEach((node: any) => {
                                         if (!nodesByChain.has(node.chain_id)) {
                                             nodesByChain.set(node.chain_id, []);
                                         }
                                         nodesByChain.get(node.chain_id).push(node);
                                     });
 
                                     // Calculate global boundaries first
                                     const chainBoundaries = new Map();
                                     nodesByChain.forEach((nodes, chainId) => {
                                         const minX = Math.min(...nodes.map((n: any) => n.x));
                                         const maxX = Math.max(...nodes.map((n: any) => n.x));
                                         const minY = Math.min(...nodes.map((n: any) => n.y));
                                         const maxY = Math.max(...nodes.map((n: any) => n.y));
                                         chainBoundaries.set(chainId, { minX, maxX, minY, maxY });
                                     });
 
                                     // Sort chains by leftmost node position
                                     const sortedChains = Array.from(chainBoundaries.entries())
                                         .sort(([, a], [, b]) => a.minX - b.minX);
 
                                     // Draw sections with dynamic spacing
                                     const padding = 25;
                                     const titleHeight = 40; // Space reserved for chain name
                                     let previousRight = -Infinity;
 
                                     sortedChains.forEach(([chainId, bounds], index) => {
                                        const nodes = nodesByChain.get(chainId);
                                        
                                        // Calculate initial position
                                        let x = bounds.minX - padding;
                                        let width = (bounds.maxX - bounds.minX) + (padding * 2);
                                        
                                        // Ensure no overlap with previous chain
                                        if (previousRight !== -Infinity) {
                                            const gap = 10; // Minimum gap between chains
                                            x = Math.max(x, previousRight + gap);
                                        }
                                        
                                        // Adjust y position to account for title space
                                        const y = bounds.minY - padding - titleHeight;
                                        const height = (bounds.maxY - bounds.minY) + (padding * 2) + titleHeight;
                                        
                                        // Update previousRight for next chain
                                        previousRight = x + width;
                                        // Get chain colors from our mapping
                                        const colors = chainColors[chainId as keyof typeof chainColors] || {
                                            fill: 'rgba(128, 128, 128, 0.1)', 
                                            stroke: 'rgb(128, 128, 128)'
                                        };

                                        // Draw background with chain-specific colors
                                        ctx.beginPath();
                                        ctx.fillStyle = colors.fill;
                                        ctx.fillRect(x, y, width, height);
                                        
                                        // Draw border with chain-specific colors
                                        ctx.strokeStyle = colors.stroke;
                                        ctx.lineWidth = 2;
                                        ctx.strokeRect(x, y, width, height);

                                        // Draw chain label with chain-specific color
                                        const chainName = chainIdToName[chainId] || `Chain ${chainId}`;
                                        ctx.font = 'bold 16px Arial';
                                        ctx.fillStyle = colors.stroke;  // Use stroke color for text
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'middle';
                                        ctx.fillText(
                                            chainName,
                                            x + (width / 2),
                                            y + titleHeight / 2 // Center text in title space
                                        );

                                        // Store section bounds
                                        chainGroups.set(chainId, {
                                            x,
                                            y,
                                            width,
                                            height,
                                            name: chainName,
                                            chain_id: chainId
                                        });
                                    });
                                }
                                
                                // Regular node rendering with chain-based positioning
                                if (!node.isChainLabel && node.chain_id) {
                                    const chainBounds = chainGroups.get(node.chain_id);
                                    if (chainBounds) {
                                        // Keep node within its chain bounds
                                        const margin = 20;
                                        node.x = Math.max(chainBounds.x + margin, 
                                            Math.min(chainBounds.x + chainBounds.width - margin, node.x));
                                        node.y = Math.max(chainBounds.y + margin, 
                                            Math.min(chainBounds.y + chainBounds.height - margin, node.y));
                                    }
                                }

                                // Regular node rendering
                                const fontSize = 12 / globalScale;
                                ctx.font = `${fontSize}px Sans-Serif`;
                                ctx.textAlign = "center";
                                ctx.textBaseline = "middle";
                                ctx.fillStyle = "black";

                                // Different sizes for nodes with and without logos
                                const logoSize = 52;  // Increased size for nodes with logos
                                const tagHeight = 24; // Height of the tag
                                const tagPadding = 12; // Padding on each side of the text        
                                const hitboxPadding = 20; // Additional invisible padding for easier dragging                    
                                const defaultSize = 12;  // Original size for nodes without logos
                                const size = images && node.source ? logoSize : defaultSize;

                                if (images && node.source) {
                                    const img = images.find(img => img.src === node.source);
                                    if (img) {
                                        // Draw invisible larger hitbox for dragging
                                        ctx.beginPath();
                                        ctx.rect(
                                            node.x - size - hitboxPadding,
                                            node.y - size - hitboxPadding,
                                            (size + hitboxPadding) * 2,
                                            (size + hitboxPadding) * 2
                                        );
                                        ctx.fillStyle = 'rgba(0, 0, 0, 0)'; // Completely transparent
                                        ctx.fill();

                                        ctx.drawImage(
                                            img, 
                                            node.x - size / 2, 
                                            node.y - size / 2, 
                                            size, 
                                            size
                                        );

                                        // Set node.__bckgDimensions for hit detection
                                        node.__bckgDimensions = {
                                            width: size + hitboxPadding * 2,
                                            height: size + hitboxPadding * 2
                                        };
                                    }
                                } else {
                                    // Format address for display
                                    const address = node.friendly_name || node.id || '';
                                    const displayText = `${address.slice(0, 10)}...${address.slice(-10)}`;
                                    
                                    // Measure text width
                                    const textWidth = ctx.measureText(displayText).width;
                                    const tagWidth = textWidth + (tagPadding * 2);
                                    
                                    // Store dimensions for hit detection
                                    node.__bckgDimensions = {
                                        width: tagWidth + hitboxPadding * 2,
                                        height: tagHeight + hitboxPadding * 2
                                    };

                                    // Draw invisible larger hitbox for dragging
                                    ctx.beginPath();
                                    ctx.rect(
                                        node.x - node.__bckgDimensions.width / 2,
                                        node.y - node.__bckgDimensions.height / 2,
                                        node.__bckgDimensions.width,
                                        node.__bckgDimensions.height
                                    );
                                    ctx.fillStyle = 'rgba(0, 0, 0, 0)'; // Completely transparent
                                    ctx.fill();
                                    
                                    // Draw tag background
                                    const radius = tagHeight / 2;
                                    ctx.fillStyle = '#e6f7ff';
                                    ctx.strokeStyle = '#1890ff';
                                    ctx.lineWidth = 1;
                                    
                                    // Draw rounded rectangle
                                    ctx.beginPath();
                                    ctx.moveTo(node.x - tagWidth/2 + radius, node.y - tagHeight/2);
                                    ctx.lineTo(node.x + tagWidth/2 - radius, node.y - tagHeight/2);
                                    ctx.arc(node.x + tagWidth/2 - radius, node.y, radius, -Math.PI/2, Math.PI/2);
                                    ctx.lineTo(node.x - tagWidth/2 + radius, node.y + tagHeight/2);
                                    ctx.arc(node.x - tagWidth/2 + radius, node.y, radius, Math.PI/2, -Math.PI/2);
                                    ctx.closePath();
                                    
                                    // Fill and stroke
                                    ctx.fill();
                                    ctx.stroke();
                                    
                                    // Draw text
                                    ctx.fillStyle = '#1890ff';
                                    ctx.fillText(displayText, node.x, node.y);
                                }
                            }}             
                            onNodeDrag={() => {
                                const canvas = document.querySelector('.force-graph-container canvas') as HTMLCanvasElement;
                                if (canvas) {
                                    canvas.style.cursor = 'grabbing';
                                }
                            }}
                            onNodeDragEnd={() => {
                                const canvas = document.querySelector('.force-graph-container canvas') as HTMLCanvasElement;
                                if (canvas) {
                                    canvas.style.cursor = 'grab';
                                }
                            }}
                            // Add this new prop to ForceGraph2D
                            nodePointerAreaPaint={(node, color, ctx) => {
                                if (node.__bckgDimensions) {
                                    ctx.fillStyle = color;
                                    ctx.fillRect(
                                        node.x - node.__bckgDimensions.width / 2,
                                        node.y - node.__bckgDimensions.height / 2,
                                        node.__bckgDimensions.width,
                                        node.__bckgDimensions.height
                                    );
                                }
                            }}
                            onNodeClick={(node) => {
                                console.log('Node clicked:', node); // Add logging
                                setSelectedNode(node);
                            }}
                        />
                        {selectedNode && <NodeStats node={selectedNode} />}
                    </div>
                }
            </Card>
            <Button loading={isLoadingReProcessTransactionsForAddress} type="primary" onClick={(e) => createColumnsModalShow()} 
                    >Reprocess the transactions for this address
            </Button>
        </Show>
    );
};
