import React, { useEffect, useState } from 'react';


import { IResourceComponentsProps, useInvalidate, useOne, useUpdate } from "@refinedev/core";

import {
    List,
    useTable,
    useModalForm,
    RefreshButton,
    CreateButton,
    DeleteButton,
} from "@refinedev/antd";
import { Form, Input, Modal, Avatar, Card, Skeleton, Switch, Col, Row, Spin, Space, Tooltip  } from "antd";
const { Meta } = Card;
import { EditOutlined, DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { IAction } from "interfaces";
import Typography from 'antd/lib/typography';
import { Link } from 'react-router-dom';


export const ActionsList: React.FC<IResourceComponentsProps> = () => {
    const [isLoading, setIsLoading] = React.useState<Array<boolean>>([]);
    const [actions, setActions] = React.useState<Array<IAction>>();

    const {
        tableQueryResult,
    } = useTable<IAction>({
        meta: { 
            "last_evaluated_key": "null",
            "limit": "null",
            "search": "null"
        },
    });

    let [editAction, setEditAction] = React.useState<any>() 
    let [editActionChecked, setEditActionChecked] = React.useState<boolean>(false) 
    let [enableDetailedEdit, setEnableDetailedEdit] = React.useState<boolean>(false) 

    // Show action task settings Modal
    const {
        modalProps: showActionSettingsModalProps,
        formProps: showActionSettingsFormProps,
        show: showActionSettingsModalShow,
        setId,
        onFinish,
        close: closeUpdateActionModal,
    } = useModalForm({
        id: editAction?.id,
        action: "edit",
        resource: "actions",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeUpdateActionModal()
            refreshTableResults()
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
            setEnableDetailedEdit(false)
        },
        onMutationError: (data, variables, context, isAutoSave) => {
            let items = [...isLoading];
            for (let i = 0; i < items.length; i++) {
                items[i] = false
              }
            
            setIsLoading(items) 
        },
    });

    const onFinishUpdateAction = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello action")
        console.log(editAction)
        let new_obj = {}
        if(values.updateCheckedOnly) {
            console.log("Only updating checked value")

            new_obj = {
                id: `${values.record.id}`,
                enabled: `${values.new_checked}`,
            }
        } else{
            let new_type = values.type != 'undefined' && typeof(values.type) !== 'undefined' && values.type !== undefined ? values.type : editAction.type
            let new_stage = values.stage != 'undefined' && typeof(values.stage) !== 'undefined' && values.stage !== undefined  ? values.stage : editAction.stage
            let new_name = values.name != 'undefined' && typeof(values.name) !== 'undefined' && values.name !== undefined  ? values.name : editAction.name
            let new_description = values.description != 'undefined' && typeof(values.description) !== 'undefined' && values.description !== undefined  ? values.description : editAction.description
            let new_timeout_seconds = values.timeout_seconds != 'undefined' && typeof(values.timeout_seconds) !== 'undefined' && values.timeout_seconds !== undefined  ? values.timeout_seconds : editAction.timeout_seconds
            let new_heart_beat_seconds = values.heart_beat_seconds != 'undefined' && typeof(values.heart_beat_seconds) !== 'undefined' && values.heart_beat_seconds !== undefined  ? values.heart_beat_seconds : editAction.heart_beat_seconds
            let new_context = values.context != 'undefined' && typeof(values.context) !== 'undefined' && values.context !== undefined  ? values.context : editAction.context
            let new_arn = values.arn != 'undefined' && typeof(values.arn) !== 'undefined' && values.arn !== undefined  ? values.arn : editAction.arn
            let new_field_name = values.field_name != 'undefined' && typeof(values.field_name) !== 'undefined' && values.field_name !== undefined  ? values.field_name : editAction.field_name
            let new_enabled = Boolean(editActionChecked) // should always use this one
    
            if (typeof new_context === 'object' || new_context instanceof Object){
                new_context = JSON.stringify(new_context)
            } 
    
            new_obj = {
                id: `${editAction.id}`,
                type: `${new_type}`,
                stage: `${new_stage}`,
                name: `${new_name}`,
                description: `${new_description}`,
                timeout_seconds: `${new_timeout_seconds}`,
                heart_beat_seconds: `${new_heart_beat_seconds}`,
                context: `${new_context}`,
                arn: `${new_arn}`,
                field_name: `${new_field_name}`,
                enabled: `${new_enabled}`,
            }
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };


    

    const editActionSettings = async (record: IAction) => {
        console.log("Edit task settings")
        if(record){
            console.log(record)
            setEnableDetailedEdit(false)
            setId(record.id);
            setEditAction(record)
            setEditActionChecked(record.task.enabled)
            showActionSettingsModalShow(record.id)
        }

      };

    const handleDetailedScreenChangeActionEnabled = (checked: boolean) => {
        editAction.task.enabled = checked
        setEditActionChecked(checked)
        setEnableDetailedEdit(checked)
    };

    const onChangeMainScreenEnabledAction = (checked: boolean, index: any, record: IAction) => {
        // 1. Make a shallow copy of the items
        let items = [...isLoading];
        items[index] = true
        setIsLoading(items) 

        onFinishUpdateAction({
            "updateCheckedOnly": "updateCheckedOnly",
            "new_checked": checked,
            "record": record
        })
    };
    
    const [isLoadingTable, setIsLoadingTable] = React.useState<boolean>(false);
    const refreshTableResults = () => {
        if(tableQueryResult?.data){
            setActions([])
        }
        
        tableQueryResult.refetch()
    };
    
    
    useEffect(() => {
        if(tableQueryResult){
            if(tableQueryResult.data){
                setActions(tableQueryResult?.data.data)
            }
            setIsLoadingTable(tableQueryResult.isRefetching)
        }
       
      }, [editAction, tableQueryResult, actions, enableDetailedEdit]);

    return (
        <>
    <List 
        key="id"
        title="Actions"      
        headerButtons={
            <><RefreshButton
            loading={isLoadingTable}
                resource="actions" onClick={(e) => refreshTableResults()} />
            <CreateButton
                    resource="actions" /></>
      }>
    Actions are a set of steps
 executed in sequence whenever any of the various triggers are invoked in the Authe pipeline (such as a user logging in). Actions can be added to a Flow from either the Marketplace
 or be built with custom code to extend the default Authe behavior. Read more
.
        </List>
        <Modal {...showActionSettingsModalProps} title="Task settings" centered>
            <Form 
                {...showActionSettingsFormProps}
                fields={[
                {
                    name: ["id"],
                    value: editAction?.id,
                },
                {
                    name: ["type"],
                    value: editAction?.type,
                },
                {
                    name: ["stage"],
                    value: editAction?.stage,
                },
                {
                    name: ["name"],
                    value: editAction?.task.name,
                },
                {
                    name: ["description"],
                    value: editAction?.task.description,
                },
                {
                    name: ["timeout_seconds"],
                    value: editAction?.task.timeout_seconds,
                },
                {
                    name: ["heart_beat_seconds"],
                    value: editAction?.task.heart_beat_seconds,
                },
                {
                    name: ["context"],
                    value: editAction?.task.context,
                },
                {
                    name: ["arn"],
                    value: editAction?.task.arn,
                },
                {
                    name: ["field_name"],
                    value: editAction?.task.field_name,
                },
                {
                    name: ["enabled"],
                    value: editAction?.task.enabled,
                },
                ]}
                layout="vertical"
                onFinish={onFinishUpdateAction}
                >
                <Form.Item
                    label="Id"
                    name="id"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editAction?.id} disabled={false}/>
                </Form.Item>
               <Form.Item
                    label="Type"
                    name="type"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editAction?.type} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="stage"
                    name="stage"
                    style={{ width: 500 }}
                    hidden={true}
                >
                    <Input defaultValue={editAction?.stage} disabled={!enableDetailedEdit}/>
                </Form.Item>
                 <Form.Item
                    label="Name"
                    name="name"
                    style={{ width: 500 }}
                >
                    <Input defaultValue={editAction?.task.name} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    style={{ width: 500 }}
                >
                    <Input  defaultValue={editAction?.task.description} disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="timeout_seconds" label="Timeout in Seconds">
                    <Input defaultValue={editAction?.task.timeout_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="heart_beat_seconds" label="Heartbeat in Seconds">
                    <Input defaultValue={editAction?.task.heart_beat_seconds} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="context" 
                    label="Context"
                    rules={[
                        {
                            required: false,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                try{
                                    if (typeof value === 'object' || value instanceof Object){
                                        JSON.stringify(value)
                                    } else{
                                        JSON.parse(value)
                                    }
                                    
                                    return Promise.resolve();
                                }
                                catch{
                                    return Promise.reject(
                                        new Error("'No valid JSON"),
                                    );
                                }
                            },
                        },
                    ]}             
                    getValueProps={(v) => {
                        if (typeof v === 'string' || v instanceof String){
                            return ({ name: 'context', value: v });
                        } else{
                            return ({ name: 'context', value: JSON.stringify(editAction?.task.context) });
                        }
                    }}>
                    <Input type="textarea"  disabled={!enableDetailedEdit}/>
                </Form.Item>
                <Form.Item name="arn" label="Lambda Arn">
                    <Input defaultValue={editAction?.task.arn} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item name="field_name" label="Field Name">
                    <Input defaultValue={editAction?.task.field_name} type="textarea" disabled={!enableDetailedEdit} />
                </Form.Item>
                <Form.Item 
                    name="enabled" 
                    label={<Link to={''}>Edit Action?</Link>       }
                    labelAlign="right"
                    // getValueProps={(v) => {
                    //     return ({ name: 'enabled', value: editAction?.task.enabled });
                    //     }}
                    >                        
                    <Switch checkedChildren={<CheckOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} unCheckedChildren={<CloseOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} onChange={handleDetailedScreenChangeActionEnabled} style={{marginTop: 20}} defaultChecked={false} />
                </Form.Item>
            </Form>
        </Modal>
        
        <Row gutter={16}>
        {
            actions ? actions.map((record: IAction, index) => 
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><SettingOutlined key="setting" onClick={() => editActionSettings(record)} onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Edit"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/code-editor/index.html?id="+ record.id} target="_blank"><EditOutlined key="edit" onPointerEnterCapture={true} onPointerLeaveCapture={true} /></Link></Tooltip>,
                    <Tooltip title="Delete">
                        <DeleteButton resource="actions" recordItemId={record.id} hideText size='small'/>
                    </Tooltip>,
                    ]}
                >
                    <Skeleton loading={false} avatar active>
                    <Meta
                        avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        title={record.task.name}
                        description={record.task.description}
                    />
                    <div className="additional" style={{marginTop: 20}}>
                        <p>Type: <span>{record.type}</span></p>
                        <p>Stage: <span>{record.stage}</span></p>
                    </div>
                    <Spin spinning={isLoading[index] ? isLoading[index]: false}>
                        <Switch checkedChildren={<CheckOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} unCheckedChildren={<CloseOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} style={{marginTop: 20}} defaultChecked={record.task.enabled} onChange={(e) => {onChangeMainScreenEnabledAction(e, index, record)}}  />
                    </Spin>
                    </Skeleton>
                    
                </Card>
            </Col>
            ) :             
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><SettingOutlined key="setting_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Edit"><EditOutlined key="edit_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Delete"> <DeleteOutlined key="delete_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    ]}
                >
                    <Skeleton loading={true} avatar active>
                    </Skeleton>
                </Card>
            </Col>
        }
                    
        </Row>
        
        </>
    );
    
};
